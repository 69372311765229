import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';

import { initialState, UsersFilterValueModel, UsersListingModel, UsersState } from './users-state.model';
import { registrationFormReducer } from '../components/registration/registration-form.reducer';
import { UsersAction, UsersActionTypes } from './users.actions';
import { mroRegistrationFormReducer } from '../components/mro-facility-registration/mro-registration.reducer';
import { agencyRegistrationFormReducer } from '../components/agency-facility-registration/agency-registration.reducer';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export const FEATURE_STATE_KEY = 'users';

export function getReducers(): ActionReducerMap<UsersState> {
  return {
    registrationForm: registrationFormReducer,
    usersList: usersReducer,
    usersListingFilters: usersFiltersReducer,
    mroRegistrationForm: mroRegistrationFormReducer,
    agencyRegistrationForm: agencyRegistrationFormReducer,
    listOfFacilities: facilitiesReducer,
    mroGroupsList: mroGroupsReducer
  };
}

const usersFiltersReducer = (
  state: UsersFilterValueModel = initialState.usersListingFilters,
  action: UsersAction | any
): UsersFilterValueModel => {
  switch (action.type) {
    case UsersActionTypes.UpdateFilters:
      return { ...action.payload };
    case GlobalReset.type:
    case UsersActionTypes.ResetFilters:
      return { ...initialState.usersListingFilters };
  }
  return { ...state };
};

const usersReducer = (
  state: UsersListingModel = initialState.usersList,
  action: UsersAction | any
): UsersListingModel => {
  switch (action.type) {
    case UsersActionTypes.UsersListReceived:
      return {
        ...action.payload
      };
    case GlobalReset.type:
    case UsersActionTypes.ResetState:
      return { ...initialState.usersList };

    case UsersActionTypes.FailedToGetUsersList:
      return { ...state };
  }

  return state;
};

const facilitiesReducer = (
  state: UsersListingModel = initialState.listOfFacilities,
  action: UsersAction | any
): UsersListingModel => {
  switch (action.type) {
    case UsersActionTypes.FacilitiesListReceived:
      return {
        ...action.payload
      };
    case UsersActionTypes.LoadMoreFacilitiesReceived:
      return {
        ...action.payload,
        _embedded: {
            facilities: [
              ...state._embedded.facilities,
              ...action.payload._embedded.facilities
            ]
        }
      };
    case UsersActionTypes.FailedToGetFacilities:
      return { ...state };
    case GlobalReset.type:
      return initialState.listOfFacilities;
  }
  return state;
};

const mroGroupsReducer = (
  state: Array<any> = initialState.mroGroupsList,
  action: UsersAction | any
): Array<any> => {
  switch (action.type) {
    case UsersActionTypes.MroGroupsListLoaded:
      return [...action.mroGroupList];
    case GlobalReset.type:
      return initialState.mroGroupsList;
  }
  return state;
};

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<UsersState>
>('[Users] Registered Reducers');
