import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, OperatorFunction, Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {
  AGENCY_AGENCY_JOB_OPENING_CONFIG,
  AGENCY_CONFIG,
  AGENCY_FIXED_PRICE_JOB_OPENING_CONFIG,
  MRO_FIXED_PRICE_JOB_OPENING_CONFIG,
  MRO_JOB_OPENING_CONFIG,
  MRO_PERMANENT_JOB_OPENING_CONFIG,
  TECHNICIAN_AGENCY_JOB_OPENING_CONFIG,
  TECHNICIAN_CONFIG,
  TECHNICIAN_FIXED_PRICE_JOB_OPENING_CONFIG,
  TECHNICIAN_PERMANENT_JOB_OPENING_CONFIG
} from './table.config';
import { cloneDeep, identity, keys, pickBy } from 'lodash-es';
import {
  AcceptContract,
  AddStaffiesToOffer,
  AddTechniciansToOffer,
  AgenciesLoaded,
  ApproveOffer,
  CloseAgencyOffer,
  CloseOffer,
  ContractLoaded,
  DeleteAgencyOffer,
  DeleteOffer,
  FailedToLoadList,
  FailedToLoadOfferContracts,
  InviteAgenciesToPackageOffer,
  LinkAgencyToContract,
  LoadAgenciesToInvite,
  LoadContract,
  LoadListBO,
  LoadOfferContracts,
  LoadPublicProfile,
  PublicProfileLoaded,
  ReopenAgencyOffer,
  ReopenOffer,
  SelectedOfferContractsLoaded,
  SetActingAs,
  SetActiveFilter,
  SetFilterPage,
  SetOfferType,
  SetRefNumber,
  SetSort,
  SuccessfulDeletedAgencyOffer,
  SuccessfulDeletedOffer
} from '@libs/request-overview-common/state/requests-overview.actions';
import { Actions, ofType } from '@ngrx/effects';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { OfferModalComponent } from '@libs/common-ui/offer-modal/offer-modal.component';
import { FacilityProfileModalComponent } from '@libs/common-ui/facility-profile-modal/facility-profile-modal.component';
import { TechnicianProfileModalComponent } from '@libs/common-ui/technician-profile-modal/technician-profile-modal.component';
import { SelectEntitiesFromListModalComponent } from '@libs/common-ui/select-entities-from-list-modal/select-entities-from-list-modal.component';
import { role, RoleWrapper, UserRoles } from '@libs/shared/models/roles.enum';
import {
  AgencyOfferOutDto,
  CUSTOM_REFERENCE_NUMBER_MAX_LENGTH,
  hasIndicativeRate,
  isAgencyOffer,
  isFixedPrice,
  isPermanent,
  isTemporary,
  OfferOutDto,
  OfferType,
  PackageOfferDto,
  PermanentOfferDto
} from '@libs/shared/models/offer.model';
import { Contract } from '@libs/shared/models/contract.model';
import { SortingStages } from '@libs/shared/models/sorting-stages.enum';
import {
  selectActingAs,
  selectOfferType,
  selectPage,
  selectPageSize,
  selectResultList,
  selectSelectedEntityUuid,
  selectSelectedOfferContracts,
  selectTotalElements
} from '@libs/request-overview-common/state/requests-overview.selectors';
import { getEmbeddedResource, getLink, getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { OfferSubmitSuccess } from '@libs/create-offer-page/create-offer.actions';
import { AgenciesListModalComponent } from '@libs/agencies-list-modal/agencies-list-modal.component';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { AgencyOfferSubmitSuccess } from '@libs/create-agency-offer-page/create-agency-offer.actions';
import { PackageContractLinkRel } from '@libs/shared/linkrels/package-contract.linkrel';
import { PackageOfferLinkRel } from '@libs/shared/linkrels/package-offer.linkrel';
import { ManagePackageOfferFilesModalComponent } from '../manage-package-offer-files-modal/manage-package-offer-files-modal.component';
import { CreateOfferFormModel } from '@libs/create-offer-page/create-offer.model';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { GenericOfferType } from '@libs/shared/models/genericOffer.type';
import { RequestOverviewLinksLoaded } from '../../state/admin-requests-overview.actions';
import {
  SelectTechnicianFromListModalComponent
} from '@libs/common-ui/select-technician-from-list-modal/select-technician-from-list-modal.component';
import { Link } from '@libs/shared/bms-common/rest/resource.model';
import { AdminRequestOverviewLinkRel } from '@libs/shared/linkrels/admin-request-overview.linkrel';
import { getReqUrl } from '@libs/shared/helpers/get-url-from-resource';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';

interface Filters {
  all: boolean;
  active: boolean;
  pending: boolean;
  past: boolean;
  rejected: boolean;
  documentsNeeded: boolean;
}

@Component({
  selector: 'staffnow-request-listing',
  templateUrl: './request-listing.component.html',
  styleUrls: ['./request-listing.component.scss']
})
export class RequestListingComponent implements OnInit, OnDestroy {
  readonly CUSTOM_REFERENCE_NUMBER_MAX_LENGTH = CUSTOM_REFERENCE_NUMBER_MAX_LENGTH;
  @Input() actingAs: UserRoles = null;
  tableConfig: Array<any> = [];
  resultList: Array<GenericOfferType> = [];
  offerToAddTo: OfferOutDto | PackageOfferDto = null;
  offerContractsList: Array<Contract> = [];
  effectiveRole: RoleWrapper = null;
  expandLoading: boolean = false;
  entityRetrieverLinks: { _links: { self: Link, [key: string]: Link }};
  page: number = 0;
  pageSize: number = 0;
  totalElements: number = 0;
  filters: Filters;
  refNumberSearch: string = null;
  sortingStagesEnum = SortingStages;
  readonly sortingStages: Array<string> = [SortingStages.empty, SortingStages.asc, SortingStages.desc];
  sortState = {
    periodFrom: '',
    periodTo: '',
    ameType: '',
    vacancies: '',
    mroName: '',
    chargeRate: '',
    minExperience: ''
  };
  sortingQuery: string = '';
  expandedRowIndex: number = null;
  private subs: Array<Subscription> = [];
  selectedEntityUuid: string = '';
  component = null;
  managePackageOfferFilesModalComponentRef: BsModalRef;
  private defaultFilter: string = 'active';
  private defaultPage: number = 0;
  private offerType: string;

  constructor(
    public store: Store<any>,
    public actions: Actions,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setupStoreSubscriptions();
    this.setupActionsSubscriptions();
    this.watchQueryParams();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    this.store.dispatch(SetActingAs({ role: null }));
    this.store.dispatch(SetOfferType({ offerType: null }));
  }

  hasProp(columnName: string): boolean {
    return Object.hasOwn(this.sortState, columnName);
  }

  pageChanged($event: PageChangedEvent): void {
    this.expandedRowIndex = null;
    this.search(undefined, $event.page);
  }

  handleRowExpand(offer: OfferOutDto, index: number): void {
    if (this.expandedRowIndex !== index) {
      this.expandedRowIndex = index;
      this.expandLoading = true;

      if (this.isTemporaryJobOpening(offer) || this.isPermanentJobOpening(offer)) {
        this.store.dispatch(
          LoadOfferContracts({
            getContractsUrl: getUrl(offer, ApiRootLinkRel.GetContracts),
            userUuid: this.selectedEntityUuid
          })
        );
      } else if (this.isAgencyJobOpening(offer)) {
        this.offerContractsList = cloneDeep(getEmbeddedResource(offer, RequestOverviewLinkRel.AgencyOfferContracts));
      }
    } else {
      this.expandedRowIndex = null;
    }
  }

  clearSearch() {
    this.refNumberSearch = null;
    this.onSearchByRefNumber();
  }

  pressedKeyValidation($event: KeyboardEvent): void {
    this.refNumberSearch = this.refNumberSearch?.trim();
    const { code, key } = $event;
    const pattern = new RegExp(/^[a-z0-9]+$/i);

    if (!pattern.exec(key) || this.refNumberSearch?.length >= 8) {
      $event.preventDefault();
    }

    const ENTER_KEY = 'Enter';
    if (code === ENTER_KEY) {
      this.onSearchByRefNumber();
    }
  }

  onSearchByRefNumber() {
    this.store.dispatch(SetRefNumber({ refNumber: this.refNumberSearch }));
    this.search();
  }

  toggleFilters(filter: string): void {
    this.search(filter);
  }

  search(filter: string = this.getEnabledFilterKey(), page: number = 1): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        filter: filter,
        refNumber: this.refNumberSearch,
        page: page
      }
    });
  }

  handleSortingChange(sortKey: string): void {
    if (!Object.hasOwn(this.sortState, sortKey) && sortKey !== 'period') {
      return;
    }
    this.expandedRowIndex = null;

    if (sortKey !== 'period') {
      this.handleSortingForNonPeriod(sortKey);
    } else {
      this.handleSortingForPeriod();
    }

    for (const key in this.sortState) {
      if (
        (key !== sortKey && sortKey !== 'period') ||
        (sortKey === 'period' && key !== 'periodFrom' && key !== 'periodTo')
      ) {
        this.sortState[key] = '';
      }
    }

    const REQ_SORTING_CONFIG = { ...this.sortState };

    for (const key in REQ_SORTING_CONFIG) {
      if (!REQ_SORTING_CONFIG[key]) {
        delete REQ_SORTING_CONFIG[key];
      }
    }

    this.sortingQuery = this.generateSortingQuery(REQ_SORTING_CONFIG);
    this.store.dispatch(SetSort({ sort: this.sortingQuery }));
    this.triggerListLoad();
  }

  openJobOfferModal(data: GenericOfferType): void {
    this.bsModalService.show(OfferModalComponent, {
      ignoreBackdropClick: true,
      class: 'job-offer-modal',
      initialState: {
        offer: data
      }
    });
  }

  closeOffer(offer: GenericOfferType): void {
    this.modalService.openConfirmModal('SYSTEM.INFO.CLOSE_JO', () => {
      this.expandedRowIndex = null;
      if (this.isAgencyJobOpening(offer)) {
        this.store.dispatch(
          CloseAgencyOffer({
            refNumber: offer.refNumber,
            url: getUrl(offer, RequestOverviewLinkRel.CloseAgencyOffer),
            isBackoffice: true
          })
        );
      } else {
        this.store.dispatch(CloseOffer({ offer }));
      }
    });
  }

  reopenOffer(offer: GenericOfferType): void {
    this.modalService.openConfirmModal('SYSTEM.INFO.REOPEN_JO', () => {
      this.expandedRowIndex = null;
      if (this.isAgencyJobOpening(offer)) {
        this.store.dispatch(
          ReopenAgencyOffer({
            refNumber: offer.refNumber,
            url: getUrl(offer, RequestOverviewLinkRel.ReopenAgencyOffer),
            isBackoffice: true
          })
        );
      } else {
        this.store.dispatch(ReopenOffer({ offer }));
      }
    });
  }

  approveOffer(offer): void {
    this.modalService.openConfirmModal(
      offer.isPublic ? 'SYSTEM.INFO.APPROVE_PUBLIC_JO' : 'SYSTEM.INFO.APPROVE_PRIVATE_JO',
      () => {
        this.store.dispatch(ApproveOffer({ offer }));
      }
    );
  }

  onAcceptContract({ contract }, agency: any = {}) {
    this.store.dispatch(AcceptContract({ contract, agencyUuid: agency?.uuid ?? null }));
  }

  onAcceptOfferTechnician($event: { contract: Contract; offer: OfferOutDto }) {
    if ($event.offer.requiresAgencyAcceptance) {
      this.showAgencyModal($event, agency => this.onAcceptContract({ contract: $event.contract }, agency));
    } else {
      this.onAcceptContract({ contract: $event.contract }, null);
    }
  }

  onSetContractAgency($event: { contract: Contract; offer: OfferOutDto }) {
    this.showAgencyModal($event, agency => {
      this.store.dispatch(
        LinkAgencyToContract({
          contract: $event.contract,
          agencyUuid: agency.uuid
        })
      );
    });
  }

  private showAgencyModal({ contract, offer }, onConfirmCallback) {
    this.bsModalService.show(AgenciesListModalComponent, {
      class: 'agencies-list-modal modal-center',
      initialState: {
        offer: offer,
        contract: contract,
        onConfirmCallback
      }
    });
  }

  handleDeleteOffer(offer: OfferOutDto): void {
    this.modalService.openConfirmModal('SYSTEM.INFO.DELETE_JO_CONFIRMATION', () => {
      if (this.isAgencyJobOpening(offer)) {
        this.store.dispatch(
          DeleteAgencyOffer({
            url: getUrl(offer, RequestOverviewLinkRel.DeleteAgencyOffer),
            isBackoffice: true
          })
        );
      } else {
        this.store.dispatch(DeleteOffer({ url: getUrl(offer, RequestOverviewLinkRel.Delete) }));
      }
    });
  }

  onOpenPublicProfile({ contract, entityType }) {
    this.store.dispatch(LoadPublicProfile({ contract, entityType }));
  }

  openPublicProfileModal(action) {
    switch (action.entityType) {
      case EntityTypes.TECHNICIAN:
        this.bsModalService.show(TechnicianProfileModalComponent, {
          ignoreBackdropClick: true,
          class: 'modal-center',
          initialState: {
            technician: action.profile,
            isBackoffice: true
          }
        });
        break;
      case EntityTypes.MRO:
      case EntityTypes.AGENCY:
        this.bsModalService.show(FacilityProfileModalComponent, {
          ignoreBackdropClick: true,
          class: 'facility-profile-modal',
          initialState: {
            facilityProfile: action.profile
          }
        });
        break;
    }
  }

  onAddStaffie(offer) {
    this.offerToAddTo = offer;
    this.openAddStaffiesModal();
  }


  openAddStaffiesModal() {
    this.store.pipe(getFilteredApiRoot).pipe(take(1)).subscribe(apiRoot => {
      const getTechniciansUrl = getReqUrl(apiRoot, ApiRootLinkRel.GetStaffies).replace('offerUuid=', `offerUuid=${this.offerToAddTo.uuid}`);
      this.bsModalService.show(SelectTechnicianFromListModalComponent, {
        ignoreBackdropClick: true,
        class: 'modal-center',
        initialState: {
          placeholder: 'Select own employees',
          retrieverUrl: getTechniciansUrl,
          multiple: true,
          onConfirmCallback: selectedTechnicians => this.addStaffiesToOffer(selectedTechnicians, this.offerToAddTo as OfferOutDto)
        }
      });
    })
  }

  addStaffiesToOffer(technicians: string[], offer: OfferOutDto) {
    this.store.dispatch(
      AddStaffiesToOffer({
        selectedTechniciansUuids: technicians,
        offer: cloneDeep(offer)
      })
    );
    this.offerToAddTo = null;
  }

  canInviteAgenciesTechnicians(offer: PackageOfferDto) {
    return hasLink(offer, RequestOverviewLinkRel.InviteAgencies);
  }

  onInviteAgency(offer) {
    this.store.dispatch(LoadAgenciesToInvite({ offer }));
    this.offerToAddTo = offer;
  }

  private watchQueryParams() {
    this.subs.push(this.activatedRoute.queryParams.subscribe(params => this.updateFromQueryParams(params)));
  }

  private updateFromQueryParams(params: Params) {
    this.refNumberSearch = (params.refNumber as string) || null;
    const page = (params.page as number) || this.defaultPage;
    const filter = (params.filter as string) || this.defaultFilter;
    this.store.dispatch(SetRefNumber({ refNumber: this.refNumberSearch }));
    this.store.dispatch(SetFilterPage({ page: page - 1 }));
    this.enableFilter(filter);
  }

  private setupStoreSubscriptions() {
    this.storeSubscribe(select(selectPage), page => (this.page = page + 1));
    this.storeSubscribe(select(selectPageSize), pageSize => (this.pageSize = pageSize));
    this.storeSubscribe(select(selectTotalElements), totalElements => (this.totalElements = totalElements));
    this.storeSubscribe(select(selectResultList), resultList => {
      this.resultList = cloneDeep(resultList) as Array<GenericOfferType>;
      this.updateManagePackageOfferFilesModal();
    });
    this.storeSubscribe(select(selectSelectedOfferContracts), selectedOfferContracts => {
      if (selectedOfferContracts) {
        this.offerContractsList = cloneDeep(selectedOfferContracts.contracts) as Array<Contract>;
      }
    });
    this.storeSubscribe(select(selectSelectedEntityUuid), uuid => (this.selectedEntityUuid = uuid));
    this.subs.push(
      combineLatest([this.store.select(selectActingAs), this.store.select(selectOfferType)]).subscribe(
        ([actingAs, offerType]) => this.loadTableConfig(actingAs, offerType)
      )
    );
  }

  private updateManagePackageOfferFilesModal(): void {
    if (this.managePackageOfferFilesModalComponentRef) {
      const offer: { id: number } = this.managePackageOfferFilesModalComponentRef.content.packageOffer;
      this.managePackageOfferFilesModalComponentRef.content.packageOffer = this.resultList.find(
        it => it.id === offer.id
      );
    }
  }

  private setupActionsSubscriptions(): void {
    this.onAction(LoadContract, () => (this.expandLoading = true));
    this.onAction(RequestOverviewLinksLoaded, (links) => this.entityRetrieverLinks = links);
    this.onAction(
      ContractLoaded,
      SelectedOfferContractsLoaded,
      FailedToLoadOfferContracts,
      () => (this.expandLoading = false)
    );
    this.onAction(LoadListBO, () => (this.expandedRowIndex = null));
    this.onAction(FailedToLoadList, () => (this.resultList = []));
    this.onAction(PublicProfileLoaded, action => this.openPublicProfileModal(action));
    this.onAction(AgenciesLoaded, action =>
      this.openInviteAgenciesModal(action.responseList, this.offerToAddTo as PackageOfferDto)
    );
    this.onAction(
      SuccessfulDeletedOffer,
      OfferSubmitSuccess,
      SuccessfulDeletedAgencyOffer,
      AgencyOfferSubmitSuccess,
      () => {
        this.triggerListLoad();
      }
    );
  }

  private getEnabledFilterKey(): string {
    return keys(pickBy(this.filters, identity))[0];
  }

  private handleSortingForNonPeriod(sortKey: string): void {
    const initialSortIndex = 0;
    switch (this.sortState[sortKey]) {
      case this.sortingStages[0]:
        this.sortState[sortKey] = this.sortingStages[initialSortIndex + 1];
        break;
      case this.sortingStages[1]:
        this.sortState[sortKey] = this.sortingStages[initialSortIndex + 2];
        break;
      case this.sortingStages[2]:
        this.sortState[sortKey] = this.sortingStages[initialSortIndex];
        break;
      default:
        break;
    }
  }

  private handleSortingForPeriod(): void {
    const initialSortIndex = 0;
    switch (this.sortState['periodFrom']) {
      case this.sortingStages[0]:
        this.sortState['periodFrom'] = this.sortingStages[initialSortIndex + 1];
        this.sortState['periodTo'] = this.sortingStages[initialSortIndex + 1];
        break;
      case this.sortingStages[1]:
        this.sortState['periodFrom'] = this.sortingStages[initialSortIndex + 2];
        this.sortState['periodTo'] = this.sortingStages[initialSortIndex + 2];
        break;
      case this.sortingStages[2]:
        this.sortState['periodFrom'] = this.sortingStages[initialSortIndex];
        this.sortState['periodTo'] = this.sortingStages[initialSortIndex];
        break;
      default:
        break;
    }
  }

  private openInviteAgenciesModal(agencyList: Array<any>, offer: PackageOfferDto): void {
    this.bsModalService.show(SelectEntitiesFromListModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-center',
      initialState: {
        placeholder: 'Select Agencies',
        entityList: agencyList,
        onConfirmCallback: agencies => this.inviteAgenciesToOffer(agencies, offer)
      }
    });
  }

  private inviteAgenciesToOffer(agencies: Array<any>, offer: PackageOfferDto): void {
    this.store.dispatch(
      InviteAgenciesToPackageOffer({
        selectedAgencies: agencies,
        offer: cloneDeep(offer)
      })
    );
    this.offerToAddTo = null;
  }

  openAddTechniciansModal(): void {
    let getTechniciansUrl = getReqUrl(this.entityRetrieverLinks, AdminRequestOverviewLinkRel.GetTechnicians);
    getTechniciansUrl = getTechniciansUrl.replace('offerUuid=', `offerUuid=${this.offerToAddTo.uuid}`);
    this.bsModalService.show(SelectTechnicianFromListModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-center',
      initialState: {
        placeholder: 'Select technicians',
        retrieverUrl: getTechniciansUrl,
        multiple: true,
        onConfirmCallback: selectedTechnicians => this.addTechniciansToOffer(selectedTechnicians, this.offerToAddTo as OfferOutDto)
      }
    });
  }

  addTechniciansToOffer(techniciansUuids: string[], offer: OfferOutDto): void {
    this.store.dispatch(AddTechniciansToOffer({ selectedTechniciansUuids: techniciansUuids, offer }));
    this.offerToAddTo = null;
  }

  onAddTechnician(offer): void {
    if (!offer.isApproved) {
      return;
    }
    this.offerToAddTo = offer;
    this.openAddTechniciansModal();
  }

  openEditJobOfferForm(offer): void {
    this.router.navigate(['offer'], {
      state: {
        preselectedFilters: offer,
        isPrivate: !offer.isPublic,
        isEdit: true,
        breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Edit Job Opening'],
        mroUuid: offer._embedded?.facility.uuid
      }
    });
  }

  openEditPermanentJobOpeningForm(offer): void {
    this.router.navigate(['permanent-offer'], {
      state: {
        preselectedFilters: offer,
        isPrivate: !offer.isPublic,
        isEdit: true,
        breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Edit Job Opening'],
        mroUuid: offer._embedded?.facility.uuid
      }
    });
  }

  openEditAgencyOfferForm(agencyOffer): void {
    this.router
      .navigate(['/agency-offer', agencyOffer.packageContractId], {
        state: {
          preselectedFilters: {
            ...agencyOffer,
            ameTitleId: agencyOffer.ameTitle.id
          },
          location: agencyOffer.location,
          isEdit: true,
          startDate: agencyOffer.periodFrom,
          endDate: agencyOffer.periodTo,
          breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Edit Agency Job Opening'],
          mroUuid: agencyOffer.mroUuid,
          packageContractId: agencyOffer.packageContractId,
          editUrl: getLink(agencyOffer, PackageContractLinkRel.EditAgencyOffer)
        }
      })
      .then();
  }

  openEditPackageOfferForm(offer): void {
    this.router.navigate(['/package-offer', 'edit', offer.refNumber], {
      state: {
        offer,
        isEdit: true,
        breadcrumbs: ['Job Openings and Applications', 'Aviation Companies', 'Edit Fixed-Price Job Opening'],
        mroUuid: offer._embedded?.facility.uuid
      }
    });
  }

  private generateSortingQuery(sortConfig: any): string {
    const sortQuery = [];

    for (const key in sortConfig) {
      if (Object.hasOwn(sortConfig, key)) {
        sortQuery.push(`${sortConfig[key]}${key}`);
      }
    }

    return sortQuery.join(',');
  }

  private storeSubscribe<T, S>(pipedSelector: OperatorFunction<T, S>, subscribeFn: (a: S) => void) {
    this.subs.push(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  private onAction(...args: any[]): void {
    const subscribeFn = args.pop();
    this.subs.push(this.actions.pipe(ofType(...args)).subscribe(subscribeFn));
  }

  get shouldDisplayPending(): boolean {
    if ((this.offerType as OfferType) == OfferType.FIXED_PRICE) {
      return this.isMro || this.isAgency;
    }
    return this.isMro || this.isTechnician;
  }

  get shouldDisplayDocumentsNeeded(): boolean {
    if ((this.offerType as OfferType) == OfferType.FIXED_PRICE) {
      return false;
    }
    return !this.isMro;
  }

  get shouldDisplayRejected(): boolean {
    if ((this.offerType as OfferType) == OfferType.FIXED_PRICE) {
      return !this.isTechnician;
    }
    return true;
  }

  get isAgency(): boolean {
    return this.effectiveRole?.isAgency();
  }

  get isMro(): boolean {
    return this.effectiveRole?.isMro();
  }

  get isTechnician(): boolean {
    return this.effectiveRole?.isTechnician();
  }

  private clearSorting(): void {
    Object.keys(this.sortState).forEach(key => {
      this.sortState[key] = '';
    });
  }

  private enableFilter(filterKey: string): void {
    this.expandedRowIndex = null;
    this.clearSorting();
    this.resetFilters();
    this.filters[filterKey] = true;
    this.store.dispatch(SetActiveFilter({ activeFilter: filterKey }));
    this.triggerListLoad();
  }

  private resetFilters(): void {
    this.filters = {
      all: false,
      active: false,
      pending: false,
      past: false,
      rejected: false,
      documentsNeeded: false
    };
  }

  private loadTableConfig(userRoles: UserRoles | string, offerType: string): void {
    this.resultList = [];
    this.offerType = offerType;
    this.effectiveRole = role(userRoles);
    this.tableConfig = cloneDeep(this.getTableConfig(offerType));
  }

  private getTableConfig(offerType: string): { name: string; key: string }[] {
    if (this.effectiveRole?.isMro()) {
      return this.tableConfigForMro(offerType);
    } else if (this.effectiveRole?.isAgency()) {
      return this.tableConfigForAgency(offerType);
    } else if (this.effectiveRole?.isTechnician()) {
      return this.tableConfigForTechnician(offerType);
    }
    return [];
  }

  private tableConfigForMro(offerType: string): { name: string; key: string }[] {
    if ((offerType as OfferType) == OfferType.TEMPORARY) {
      return MRO_JOB_OPENING_CONFIG;
    }
    return (offerType as OfferType) == OfferType.PERMANENT
      ? MRO_PERMANENT_JOB_OPENING_CONFIG
      : MRO_FIXED_PRICE_JOB_OPENING_CONFIG;
  }

  private tableConfigForAgency(offerType: string): { name: string; key: string }[] {
    if ((offerType as OfferType) == OfferType.TEMPORARY) {
      return AGENCY_CONFIG;
    }
    return (offerType as OfferType) == OfferType.AGENCY
      ? AGENCY_AGENCY_JOB_OPENING_CONFIG
      : AGENCY_FIXED_PRICE_JOB_OPENING_CONFIG;
  }

  private tableConfigForTechnician(offerType: string): { name: string; key: string }[] {
    if ((offerType as OfferType) == OfferType.TEMPORARY) {
      return TECHNICIAN_CONFIG;
    }
    if ((offerType as OfferType) == OfferType.PERMANENT) {
      return TECHNICIAN_PERMANENT_JOB_OPENING_CONFIG;
    }
    return (offerType as OfferType) == OfferType.AGENCY
      ? TECHNICIAN_AGENCY_JOB_OPENING_CONFIG
      : TECHNICIAN_FIXED_PRICE_JOB_OPENING_CONFIG;
  }

  private triggerListLoad(): void {
    this.store.dispatch(LoadListBO());
  }

  canDeleteOffer(offer: OfferOutDto): boolean {
    return this.isAgencyJobOpening(offer)
      ? hasLink(offer, RequestOverviewLinkRel.DeleteAgencyOffer)
      : hasLink(offer, RequestOverviewLinkRel.Delete);
  }

  canEditOffer(offer: OfferOutDto | AgencyOfferOutDto): boolean {
    return this.isAgencyJobOpening(offer)
      ? hasLink(offer, RequestOverviewLinkRel.EditAgencyOffer)
      : hasLink(offer, RequestOverviewLinkRel.Edit);
  }

  canClose(offer: OfferOutDto): boolean {
    return this.isAgencyJobOpening(offer)
      ? hasLink(offer, RequestOverviewLinkRel.CloseAgencyOffer)
      : hasLink(offer, RequestOverviewLinkRel.CloseOffer);
  }

  canReopen(offer: OfferOutDto): boolean {
    return this.isAgencyJobOpening(offer)
      ? hasLink(offer, RequestOverviewLinkRel.ReopenAgencyOffer)
      : hasLink(offer, RequestOverviewLinkRel.ReopenOffer);
  }

  canApproveOffer(offer: OfferOutDto): boolean {
    return hasLink(offer, RequestOverviewLinkRel.ApproveOffer);
  }

  canAddStaffies(offer: OfferOutDto): boolean {
    return hasLink(offer, RequestOverviewLinkRel.AddStaffies);
  }

  canAddTechnicians(offer: OfferOutDto): boolean {
    return hasLink(offer, RequestOverviewLinkRel.AddTechnicians);
  }

  getJobOfferAt(index: number): OfferOutDto {
    return this.resultList[index] as OfferOutDto;
  }

  getPermanentOfferAt(index: number): PermanentOfferDto {
    return this.resultList[index] as PermanentOfferDto;
  }

  getFixedPriceJobOpeningAt(index: number): PackageOfferDto {
    return this.resultList[index] as PackageOfferDto;
  }

  isTemporaryJobOpening(offer: GenericOfferType): boolean {
    return isTemporary(offer);
  }

  isPermanentJobOpening(offer: GenericOfferType): boolean {
    return isPermanent(offer);
  }

  isAgencyJobOpening(offer: GenericOfferType): boolean {
    return isAgencyOffer(offer);
  }

  isFixedPriceJobOpening(offer: PackageOfferDto): boolean {
    return isFixedPrice(offer);
  }

  hasIndicativeRateForOffer(offer: CreateOfferFormModel): boolean {
    return hasIndicativeRate(offer);
  }

  canManageFixedPriceJobOpeningFiles(offer: PackageOfferDto): boolean {
    const offerFiles = getEmbeddedResource(offer, PackageOfferLinkRel.Facility);
    return (
      hasLink(offer, PackageOfferLinkRel.UploadPackageOfferFiles) ||
      hasLink(offerFiles[0], PackageOfferLinkRel.DeletePackageOfferFile)
    );
  }

  openManageFixedPriceJobOpeningFilesModal(offer: PackageOfferDto): void {
    this.managePackageOfferFilesModalComponentRef = this.bsModalService.show(ManagePackageOfferFilesModalComponent, {
      ignoreBackdropClick: false,
      class: 'manage-package-offer-files-modal',
      initialState: {
        packageOffer: offer,
        reloadPackageOfferCallback: () => {
          this.triggerListLoad();
        }
      }
    });
  }
}
