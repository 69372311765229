<div class="faq-item-wrapper mb-4">
    <div class="question-wrapper">
        <label class="mb-2">
            {{ 'Question:' | translate }}
            <input
                class="form-control question mt-1"
                [placeholder]="'Enter question' | translate"
                [value]="faqItem.label"
                (change)="handleQuestionChange($event)"
            />
        </label>
        <borderless-button
            (onClick)="handleItemDelete()"
            class="ml-5 mt-3"
            actionType="danger"
            color="dark"
            icon="delete"
        ></borderless-button>
    </div>

    <br />

    <label class="mb-1">
        {{ 'Answer:' | translate }}
    </label>
    <quill-editor
        theme="snow"
        [modules]="config"
        (onContentChanged)="handleAnswerChange($event)"
        (onEditorCreated)="editorReady($event)"
    ></quill-editor>
    <hr />
</div>
