import { FullTitleDtoReadOnly } from './full-title-dto.model';

export const DOCUMENT_AREA_PERSONAL = 1;
export const DOCUMENT_TYPE_ID_DOCUMENT_ID = 35;

export interface AmeTitleDocumentsOptions {
  ameTitles: FullTitleDtoReadOnly[];
  documentOptions: SimpleDocumentArea[];
}

export interface SimpleDocumentArea {
  title: string;
  rankNumber: number;
  types: SimpleDocumentType[];
}

export interface SimpleDocumentType {
  id: number;
  title: string;
}

export interface DocumentTypeOption {
  documentTypeId: number;
  isMandatory: boolean;
  isExpirationDateRequired: boolean;
}

export interface ExtendedDocumentArea extends SimpleDocumentArea {
  types: ExtendedDocumentType[];
}

export interface ExtendedDocumentType extends SimpleDocumentType {
  isSelected: boolean;
  isMandatory: boolean;
  isExpirationDateRequired: boolean;
}
