import {Component} from '@angular/core';
import {Store} from '@ngrx/store';

import {LoadAvailableLocations, UpdateAvailableLocations} from '../../state/manage-content.actions';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {selectIsLoading, selectLocations} from "../../state/manage-content.selectors";
import {cloneDeep} from "lodash-es";
import {Observable} from "rxjs";
import {LocationPO} from "@libs/shared/models/location.model";

@UntilDestroy()
@Component({
  selector: 'staffnow-location-list-bo',
  templateUrl: './location-list-backoffice.component.html',
  styleUrls: ['./location-list-backoffice.component.scss']
})
export class LocationListBackoffice {
  public activeLocations: LocationPO[] = [];
  public disabledLocations: LocationPO[] = [];
  public newLocationName: string = '';
  public isLoading$: Observable<boolean>;
  public isChangeMade: boolean = false;
  public duplicatedLocation: boolean = false;

  constructor(
    private store: Store<any>,
    private confirmationService: ModalService
  ) {
    this.isLoading$ = this.store.select(selectIsLoading);
    this.store
        .select(selectLocations)
        .pipe(untilDestroyed(this))
        .subscribe(locations => {
          this.isChangeMade = false;
          this.activeLocations =  cloneDeep(locations.filter(location => !location.disabled));
          this.disabledLocations = cloneDeep(locations.filter(location => location.disabled));
        });
    this.store.dispatch(LoadAvailableLocations());
  }

  public addNewLocation(): void {
    this.duplicatedLocation = false;
    const foundLocation = this.activeLocations.find(location => location.name.trim() === this.newLocationName.trim());
    if (foundLocation) {
      this.duplicatedLocation = true;
    } else {
      this.activeLocations.unshift({ id: null, name: this.newLocationName.trim() });
      this.newLocationName = '';
      this.markAsChanged();
    }
  }

  public deleteLocation(index): void {
    this.confirmationService.openCustomizableConfirmModal({
      messages: [
        'Are you sure you want to delete this location from the list?',
        'If the location is being used by any job opening, it will become disabled instead of deleted.'
      ],
      onConfirmedCallback: () => {
        if (index >= 0 && index < this.activeLocations.length) {
          this.activeLocations.splice(index, 1);
          this.markAsChanged();
        }
      }
    });
  }

  public saveChanges(): void {
    this.store.dispatch(UpdateAvailableLocations({
      locations: this.activeLocations
    }));
  }

  public markAsChanged(): void {
    this.isChangeMade = true;
  }

}
