import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { BackofficeHomeComponent } from './backoffice-home.component';
import { BackofficeHomeRoutesModule } from './backoffice-home-routing.module';

@NgModule({
  imports: [
    CommonModule,
    BackofficeHomeRoutesModule,
    SharedModule,
    CommonUiModule
  ],
  providers: [],
  declarations: [
    BackofficeHomeComponent,
  ]
})
export class BackofficeHomeModule {}
