import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'staffnow-home-login',
  template: '',
  styles: ['']
})
export class BackofficeHomeComponent implements OnInit {
  constructor(private router: Router) {
  }

  ngOnInit(): void {
      this.router.navigate(['technicians']);
  }
}
