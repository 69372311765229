import { Component } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';

import { ManageContentActionTypes, SaveGeneralSettings } from '../../state/manage-content.actions';
import { filter } from 'rxjs/operators';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { getAirplanesSortedByName } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Aircraft } from '@libs/shared/models/aircraft.model';

@UntilDestroy()
@Component({
  selector: 'staffnow-aircraft-list-bo',
  templateUrl: './aircraft-list-backoffice.component.html',
  styleUrls: ['./aircraft-list-backoffice.component.scss']
})
export class AircraftListBackofficeComponent {
  public aircraftList: Array<Aircraft> = null;
  public newAircraftName: string = '';
  public isLoading: boolean = false;
  public isChangeMade: boolean = false;

  constructor(
    private store: Store<any>,
    private confirmationService: ModalService,
    private actionsSubject: ActionsSubject
  ) {
    this.store.pipe(getAirplanesSortedByName, untilDestroyed(this)).subscribe(aircraftList => {
      this.aircraftList = aircraftList;
    });

    this.actionsSubject
      .pipe(
        filter(
          action =>
            action.type === ManageContentActionTypes.UpdateAircraftList.toString() ||
            action.type === ManageContentActionTypes.FailedToUpdateAircraftList.toString()
        ),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.isLoading = false;
        this.isChangeMade = false;
      });
  }

  updateAircraftList(aircraft: Aircraft, newAircraftName: string, index: number): void {
    if (aircraft.name !== newAircraftName) {
      this.aircraftList[index] = { id: aircraft.id, name: newAircraftName };
      this.isChangeMade = true;
    }
  }

  public addNewAircraft(): void {
    this.aircraftList.unshift({ id: null, name: this.newAircraftName });
    this.newAircraftName = '';
    this.isChangeMade = true;
  }

  public deleteAircraft(index: number): void {
    this.confirmationService.openConfirmModal('SYSTEM.INFO.DELETE_AIRCRAFT_LIST', () => {
      if (index >= 0 && index < this.aircraftList.length) {
        this.aircraftList.splice(index, 1);
        this.isChangeMade = true;
      }
    });
  }

  public saveChanges(): void {
    this.isLoading = true;
    this.store.dispatch(new SaveGeneralSettings(this.aircraftList));
  }
}
