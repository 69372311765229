<div class="document-area">
    <h1 class="h1">{{ documentAreaTitle }}</h1>
    <div class="types-list">
        <div class="document-type" *ngFor="let documentType of documentTypes">
            <h2 class="h2">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input qwerty"
                        [id]="getDocumentTypeIsSelectedId(documentType)"
                        [(ngModel)]="documentType.isSelected"
                        (ngModelChange)="handleDocumentTypeSelected(documentType)"
                    />
                    <label [for]="getDocumentTypeIsSelectedId(documentType)"  class="custom-control-label font-weight-bold">
                        {{ documentType.title }}
                    </label>
                </div>
            </h2>
            <div class="type-options">
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="getMandatoryId(documentType)"
                        [disabled]="!documentType.isSelected"
                        [(ngModel)]="documentType.isMandatory"
                    />
                    <label
                        [for]="getMandatoryId(documentType)"
                        class="custom-control-label font-weight-bold"
                    >
                        Is Mandatory
                    </label>
                </div>
                <div class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        [id]="getExpirationDateId(documentType)"
                        [disabled]="!documentType.isSelected"
                        [(ngModel)]="documentType.isExpirationDateRequired"
                    />
                    <label
                        [for]="getExpirationDateId(documentType)"
                        class="custom-control-label font-weight-bold"
                    >
                        Expiration date is required
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
