import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgSelectModule } from '@ng-select/ng-select';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { RequestOverviewRoutingModule } from './admin-request-overview-routing.module';
import { OverviewWrapperComponent } from './components/overview-wrapper/overview-wrapper.component';
import { AdminRequestOverviewComponent } from './admin-request-overview.component';
import { AdminRequestsOverviewEffects } from './state/admin-requests-overview.effects';
import {
  ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY,
  AdminRequestsOverviewState,
  getReducers as getRegistrationReducers,
  initialState as adminRequestsOverviewInitialState,
  reducersInjectionToken
} from './state/admin-requests-overview.reducer';
import { SharedModule } from '@back-office/shared/shared.module';
import { RequestListingComponent } from './components/request-listing/request-listing.component';
import { OfferInfoExpandComponent } from './components/offer-info-expand/offer-info-expand.component';
import { ContractsListExpandComponent } from './components/contracts-list-expand/contracts-list-expand.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RequestOverviewCommonModule } from '@libs/request-overview-common/request-overview-common.module';
import { AgencyModalModule } from '@libs/agencies-list-modal/agency-modal.module';
import { RatePipe } from '@libs/shared/pipes/rate.pipe';
import { PackageContractListExpandComponent } from './components/package-contract-list-expand/package-contract-list-expand.component';
import { AgencyOfferInfoExpandComponent } from './components/agency-offer-info-expand/agency-offer-info-expand.component';
import {
  AgencyOfferContractsListExpandComponent
} from './components/agency-offer-contracts-list-expand/agency-offer-contracts-list-expand.component';
import { ManagePackageOfferFilesModalComponent } from './components/manage-package-offer-files-modal/manage-package-offer-files-modal.component';
import { PackageOfferModule } from '@libs/package-offer/package-offer.module';
import { TechnicianPaginatedSelectorComponent } from '@libs/common-ui/technician-paginated-selector/technician-paginated-selector.component';

export function getInitialState(): Partial<AdminRequestsOverviewState> {
  return {
    ...adminRequestsOverviewInitialState
  };
}

@NgModule({
  imports: [
    RequestOverviewRoutingModule,
    FormsModule,
    NgSelectModule,
    TypeaheadModule.forRoot(),
    SharedModule,
    StoreModule.forFeature(
      ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY,
      reducersInjectionToken,
      {
        initialState: adminRequestsOverviewInitialState
      }
    ),
    EffectsModule.forFeature([AdminRequestsOverviewEffects]),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    RequestOverviewCommonModule,
    AgencyModalModule,
    PackageOfferModule,
    TechnicianPaginatedSelectorComponent
  ],
  providers: [
    { provide: reducersInjectionToken, useFactory: getRegistrationReducers },
    RatePipe
  ],
  declarations: [
    AdminRequestOverviewComponent,
    OverviewWrapperComponent,
    RequestListingComponent,
    OfferInfoExpandComponent,
    ContractsListExpandComponent,
    PackageContractListExpandComponent,
    AgencyOfferInfoExpandComponent,
    AgencyOfferContractsListExpandComponent,
    ManagePackageOfferFilesModalComponent
  ]
})
export class AdminRequestOverviewModule {}
