import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroupState } from 'ngrx-forms';
import { ActionsSubject, select, Store } from '@ngrx/store';

import {
  ResetFacilityRegistrationForm,
  SubmitFacilityRegistrationForm,
  UsersActionTypes
} from '../../state/users.actions';
import { FEATURE_STATE_KEY } from '../../state/users.reducer';
import {
  AgencyRegistrationStateModel,
  AGENCY_REGISTRATION_FORM_ID
} from './agency-registration-state.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'agency-facility-registration',
  templateUrl: './agency-registration.component.html'
})
export class AgencyRegistrationComponent implements OnDestroy {
  @ViewChild('modalWindow') private modalWindow: any;

  public agencyRegistrationForm: Observable<
    FormGroupState<AgencyRegistrationStateModel>
  >;
  public agencyRegistrationFormValue: FormGroupState<AgencyRegistrationStateModel> = null;
  public isLoading = false;
  private subscriptions = [];

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject
  ) {
    this.agencyRegistrationForm = store.pipe(
      select(
        state =>
          <FormGroupState<AgencyRegistrationStateModel>>(
            state[FEATURE_STATE_KEY][AGENCY_REGISTRATION_FORM_ID]
          )
      )
    );
    this.subscriptions.push(
      this.agencyRegistrationForm.subscribe(value => {
        this.agencyRegistrationFormValue = value;
      })
    );
    this.subscriptions.push(
      this.onActionType(UsersActionTypes.SuccessfullyRegistredFacility, () =>
        this.closeRegistrationForm()
      )
    );
    this.subscriptions.push(
      this.onActionType(
        UsersActionTypes.FailedToRegisterNewFacility,
        () => (this.isLoading = false)
      )
    );
  }

  private onActionType(type: string, fn: () => void) {
    return this.actionsSubject
      .pipe(filter(action => action.type === type))
      .subscribe(fn);
  }

  public submitForm(): void {
    this.store.dispatch(
      new SubmitFacilityRegistrationForm(this.agencyRegistrationFormValue.value)
    );
    this.isLoading = true;
  }

  public openRegistrationForm(): void {
    this.modalWindow.open();
  }

  public closeRegistrationForm(): void {
    this.store.dispatch(new ResetFacilityRegistrationForm());
    this.modalWindow.close();
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
    this.subscriptions = [];
  }
}
