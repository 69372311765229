import { Component } from '@angular/core';

import { ManageContentTabs } from './manage-content-tabs.constant';

@Component({
  selector: 'manage-content',
  templateUrl: './manage-content.component.html'
})
export class ManageContentComponent {
  public MANAGE_CONTENT_TABS = Object.values(ManageContentTabs);
  public activeTab: any = this.MANAGE_CONTENT_TABS[0];

  get aircraftIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.aircraft.path;
  }

  get locationsIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.locations.path;
  }

  get slideshowIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.slideshow.path;
  }

  get faqIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.faq.path;
  }

  get languagesIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.languages.path;
  }

  get titlesIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.titles.path;
  }

  get titleDocumentsConfigIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.titleDocumentsConfig.path;
  }

  get personalDocumentsIsActiveTab() {
    return this.activeTab.path === ManageContentTabs.personalDocuments.path;
  }

}
