<div class="contracts-list-container">
    <div *ngIf="isLoading" class="no-applicants">
        <staff-loader
            *ngIf="isLoading"
            [customClass]="'splash-screen-loader'"
        ></staff-loader>
    </div>
    <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="effectiveRole?.isTechnician(); else facilities">
            <div class="details-for-technician-view">
                <div class="contract">
                    <div>
                        <div class="agency-info">
                            <facility-logo
                                [resource]="contractsList[0]"
                                size="small"
                            ></facility-logo>
                            <span class="h6">
                                {{ contractsList[0].agencyName }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <p class="specification">Specification</p>
                        <p>
                            {{ offer?.specification }}
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #facilities>
        <ng-container *ngIf="contractsList.length > 0; else noApplicants">
            <div class="col-titles">
                <span class="col-name">{{ 'Name' | translate }}</span>
                <span class="col-name">{{ 'Start Date' | translate }}</span>
                <span class="col-name">{{ 'End Date' | translate }}</span>
                <span class="col-name">{{ 'Agency Price' | translate }}</span>
                <span
                    class="col-name"
                    [innerHtml]="'Aviation Company<br>Price' | translate"
                ></span>
                <span class="col-name">{{ 'Status' | translate }}</span>
                <span class="col-actions">{{ 'Actions' | translate }}</span>
            </div>
            <div class="applicants-list">
                <div
                    *ngFor="let contract of contractsList"
                    class="position-relative contract-wrapper"
                >
                    <div class="single-contract-row">
                        <!-- AGENCY COLUMN -->
                        <div class="agency-info">
                            <facility-logo
                                [resource]="contract"
                                size="small"
                            ></facility-logo>
                            <span class="h6">
                                {{ contract.agencyName }}
                            </span>
                        </div>
                        <!-- START DATE COLUMN -->
                        <span class="start-date">
                            <ng-container
                                *ngIf="contract.startDate; else pending"
                            >
                                {{ contract.startDate | absoluteDateFormat }}
                            </ng-container>
                        </span>
                        <!-- END DATE COLUMN -->
                        <span class="end-date">
                            <ng-container
                                *ngIf="contract.endDate; else pending"
                            >
                                {{ contract.endDate | absoluteDateFormat }}
                            </ng-container>
                        </span>
                        <!-- AGENCY PRICE COLUMN -->
                        <span class="price">
                            <ng-container
                                *ngIf="
                                    contract.agencyOfferedPrices;
                                    else pending
                                "
                            >
                                <staffnow-rates-history
                                    [rates]="contract.agencyOfferedPrices"
                                ></staffnow-rates-history>
                                <template [ngTemplateOutlet]="priceEstimate"></template>
                            </ng-container>
                        </span>
                        <!-- MRO PRICE COLUMN -->
                        <span class="price">
                            <ng-container
                                *ngIf="
                                    contract.mroOfferedPrice;
                                    else notPresent
                                "
                            >
                                {{ contract.mroOfferedPrice | rate }}
                                <template [ngTemplateOutlet]="priceEstimate"></template>
                            </ng-container>
                        </span>
                        <!-- CONTRACT STATUS COLUMN -->
                        <staffnow-package-contract-status [contract]="contract">
                        </staffnow-package-contract-status>
                        <!-- ACTIONS COLUMN -->
                        <div class="action-buttons">
                            <general-button
                                *ngIf="canEdit(contract)"
                                (onClick)="editContract(contract)"
                                size="small"
                                backgroundStyle="outline"
                                text="EDIT"
                            ></general-button>
                            <general-button
                                *ngIf="canAccept(contract)"
                                (onClick)="acceptContract(contract)"
                                size="small"
                                text="ACCEPT"
                            ></general-button>
                            <general-button
                                *ngIf="canDecline(contract)"
                                (onClick)="declineContract(contract)"
                                size="small"
                                backgroundStyle="outline"
                                color="danger"
                                text="DECLINE"
                            ></general-button>
                            <general-button
                                *ngIf="canAcceptPrice(contract)"
                                (onClick)="acceptPrice(contract)"
                                size="small"
                                backgroundStyle="outline"
                                text="ACCEPT PRICE"
                            ></general-button>
                            <general-button
                                *ngIf="canProposePrice(contract)"
                                (onClick)="proposePrice(contract)"
                                size="small"
                                backgroundStyle="outline"
                                text="PROPOSE PRICE"
                            ></general-button>
                            <general-button
                                *ngIf="canAddAgencyTechnicians(contract)"
                                (onClick)="addAgencyTechnicians(contract)"
                                size="small"
                                backgroundStyle="outline"
                                text="ADD TECHNICIANS"
                            ></general-button>
                            <general-button
                                (onClick)="openSeeAgencyApprovalsModal(contract)"
                                size="small"
                                backgroundStyle="outline"
                                text="SEE APPROVALS"
                            ></general-button>
                            <general-button
                                *ngIf="canCreateAgencyOffer(contract)"
                                (onClick)="openCreateAgencyOfferPage(contract)"
                                size="small"
                                backgroundStyle="outline"
                                text="CREATE AGENCY JOB OPENING"
                            ></general-button>
                        </div>
                    </div>
                    <div class="agency-notes-wrapper">
                        <h3 class="h3">Agency Notes: </h3>
                        <div *ngIf="contract.agencyNotes; else notPresent">
                            {{ contract.agencyNotes }}
                        </div>
                    </div>
                    <div class="workers-wrapper">
                        <h3 class="h3">
                            {{ getWorkers(contract).length + ' workers' }}
                        </h3>
                        <div class="workers">
                            <staffnow-chip
                                *ngFor="let worker of getWorkers(contract)"
                            >
                                <div class="package-contract-technician">
                                    <staffnow-technician-picture-and-name
                                        [resource]="worker"
                                        (onClick)="openTechnicianProfile($event)"
                                    ></staffnow-technician-picture-and-name>
                                    <div class="actions">
                                        <borderless-button
                                            *ngIf="canRemoveTechnician(contract)"
                                            (onClick)="removeTechnician(contract, worker)"
                                            actionType="danger"
                                            color="dark"
                                            icon="delete"
                                        ></borderless-button>
                                    </div>
                                </div>
                            </staffnow-chip>
                        </div>
                    </div>
                    <ng-template #priceEstimate>
                        <span *ngIf="contract.priceEstimate">
                            - Best estimation
                        </span>
                    </ng-template>
                </div>

            </div>
        </ng-container>
    </ng-template>
    <ng-template #pending> Pending </ng-template>
    <ng-template #notPresent> N/P </ng-template>
    <ng-template #noApplicants>
        <div class="no-applicants">
            <h1 class="h1">
                {{
                    'There are no applicants for this request yet.' | translate
                }}
            </h1>
        </div>
    </ng-template>
</div>
