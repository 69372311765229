import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FullTitleDto} from '../../../state/full-title-dto.model';

@Component({
  selector: 'staffnow-new-title-form',
  templateUrl: './new-title-form.component.html',
  styleUrls: ['./new-title-form.component.scss']
})
export class NewTitleFormComponent {
  @Input() public title: FullTitleDto;
  @Input() public index: number = null;
  @Output() public remove = new EventEmitter<void>();

  public handleRemove(): void {
    this.remove.emit();
  }

  public resetLicenses(): void {
    this.title.license = null;
  }

}
