<input
    type="text"
    placeholder="Type of Technician"
    class="form-control"
    [(ngModel)]="title.type"
/>
<input
    type="text"
    placeholder="License (optional)"
    class="form-control"
    [disabled]="title.isSingletonType"
    [(ngModel)]="title.license"
/>
<div>
    <div class="custom-control custom-checkbox">
        <input
            [id]="'isSingletonType' + index"
            type="checkbox"
            class="custom-control-input"
            [(ngModel)]="title.isSingletonType"
            (ngModelChange)="resetLicenses()"
        />
        <label
            [for]="'isSingletonType' + index"
            class="custom-control-label font-weight-bold"
        >
            Without licenses
        </label>
    </div>
</div>
<borderless-button
    (onClick)="handleRemove()"
    actionType="danger"
    color="dark"
    icon="delete"
></borderless-button>
