export enum UsersLinkRel {
  GetTechnicianUsers = 'technicians',
  CreateMROFacility = 'createMroFacility',
  CreateAgencyFacility = 'createAgencyFacility',
  CreateMROUser = 'createMroUser',
  CreateAgencyUser = 'createAgencyUser',
  CreateAdminUser = 'createAdministrativeUser',
  GetMROFacilities = 'getMroFacilities',
  GetMROFacilitiesPaged = 'getMroFacilitiesPaged',
  GetMROFacilitiesIncludingDeleted = 'getMroFacilitiesIncludingDeleted',
  GetAgencyFacilities = 'getAgencyFacilities',
  GetMROUsers = 'getMroUsers',
  GetAgencyUsers = 'getAgencyUsers',
  GetAdminUsers = 'administrativeUsers',
  GetMROFacilitiesFull = 'searchMroFacilities',
  FilterMroGroups = 'filterGroups',
  GetAgencyFacilitiesFull = 'searchAgencyFacilities',
  GetAllMroGroups = 'allGroups',
  CreateGroup = 'createGroup',
  EditGroup = 'updateGroup',
  GetActiveTechnicians = 'getActiveTechnicians',
  GetWorkingTechnicians = 'getWorkingTechnicians',
  CreateTechnicianUser = 'createTechnicianUser'
}
