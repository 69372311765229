import { MroFacility, MroGroup, PublicAgency } from '@libs/shared/models/facility.model';

export const initialState: ReportsState = {
  mroGroupsList: [],
  agenciesList: [],
};

export type ReportDropDownEntity =
  | MroFacility
  | MroGroup
  | PublicAgency
  | TechnicianForImpersonate;

export interface TechnicianForImpersonate {
  email: string;
  userUuid: string;
  lastName: string;
  firstName: string;
  agencyName: string;
}


export interface ReportsState {
  mroGroupsList: Array<MroGroup>;
  agenciesList: Array<PublicAgency>;
}
