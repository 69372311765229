const COMMON = [
  {
    name: 'Ref. No',
    key: 'refNumber'
  },
  {
    name: 'Period',
    key: 'period'
  }
];

const PRIORITY = {
  name: 'Priority',
  key: 'priority'
};

const TYPES_AND_PRIORITY = [
  PRIORITY,
  {
    name: 'Type of Technicians',
    key: 'ameType'
  },
  {
    name: 'Aircraft Type',
    key: 'aircraft'
  }
];

const ACTIONS = {
  name: 'Actions',
  key: 'actions'
};

export const MRO_JOB_OPENING_CONFIG = [
  ...COMMON,
  {
    name: 'Number of Technicians',
    key: 'vacancies'
  },
  {
    name: 'Charge Rate',
    key: 'chargeRate'
  },
  {
    name: 'Pay Rate',
    key: 'payRate'
  },
  {
    name: 'Experience',
    key: 'minExperience'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const MRO_PERMANENT_JOB_OPENING_CONFIG = [
  {
    name: 'Ref. No',
    key: 'refNumber'
  },
  {
    name: 'Start Date',
    key: 'startDate'
  },
  {
    name: 'Number of Technicians',
    key: 'vacancies'
  },
  {
    name: 'Net Salary',
    key: 'netSalary'
  },
  {
    name: 'Experience',
    key: 'minExperience'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const MRO_FIXED_PRICE_JOB_OPENING_CONFIG = [
  ...COMMON,
  PRIORITY,
  ACTIONS
];

export const TECHNICIAN_CONFIG = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Pay Rate',
    key: 'payRate'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const TECHNICIAN_PERMANENT_JOB_OPENING_CONFIG = [
  {
    name: 'Ref. No',
    key: 'refNumber'
  },
  {
    name: 'Start Date',
    key: 'startDate'
  },
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Net Salary',
    key: 'netSalary'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];

export const AGENCY_FIXED_PRICE_JOB_OPENING_CONFIG = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  PRIORITY,
  ACTIONS
];

export const TECHNICIAN_FIXED_PRICE_JOB_OPENING_CONFIG = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  ACTIONS
];

export const AGENCY_AGENCY_JOB_OPENING_CONFIG = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  PRIORITY,
  ACTIONS
];

export const TECHNICIAN_AGENCY_JOB_OPENING_CONFIG = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Agency Name',
    key: 'agencyOfferAgencyName'
  },
  ACTIONS
];

export const AGENCY_CONFIG = [
  ...COMMON,
  {
    name: 'Aviation Company Name',
    key: 'mroName'
  },
  {
    name: 'Charge Rate',
    key: 'chargeRate'
  },
  {
    name: 'Pay Rate',
    key: 'payRate'
  },
  ...TYPES_AND_PRIORITY,
  ACTIONS
];
