import { Component, Input } from '@angular/core'
import { ExtendedDocumentType } from '../../state/title-documents.model'

@Component({
  selector: 'staffnow-document-types-list',
  templateUrl: './document-types-list.component.html',
  styleUrls: ['./document-types-list.component.scss']
})
export class DocumentTypesListComponent {
  @Input() public documentTypes: ExtendedDocumentType[] = [];
  @Input() public documentAreaTitle: string = '';

  public getDocumentTypeIsSelectedId(
    documentType: ExtendedDocumentType
  ): string {
    return 'selected-' + documentType.id;
  }

  public handleDocumentTypeSelected(documentType: ExtendedDocumentType): void {
    documentType.isMandatory = false;
    documentType.isExpirationDateRequired = false;
  }

  public getMandatoryId(documentType: ExtendedDocumentType): string {
    return 'mandatory-' + documentType.id;
  }

  public getExpirationDateId(documentType: ExtendedDocumentType): string {
    return 'expiration-date-' + documentType.id;
  }

}
