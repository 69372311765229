import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';

import { ReportsAction, ReportsActionTypes } from './reports.actions';
import { initialState, ReportsState } from './reports-state.model';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export const REPORTS_FEATURE_KEY = 'reports';

export function getReducers(): ActionReducerMap<ReportsState> {
  return {
    mroGroupsList: mroGroupsReducer,
    agenciesList: agenciesListReducer,
  };
}

const mroGroupsReducer = (
  state: Array<any> = initialState.mroGroupsList,
  action: ReportsAction | any
): Array<any> => {
  switch (action.type) {
    case ReportsActionTypes.MroGroupsListLoaded:
      return [...action.payload];
    case GlobalReset.type:
    case ReportsActionTypes.ResetToInitialState:
      return [...initialState.mroGroupsList];
  }

  return state;
};

const agenciesListReducer = (
  state: Array<any> = initialState.agenciesList,
  action: ReportsAction | any
): Array<any> => {
  switch (action.type) {
    case ReportsActionTypes.AgenciesListLoaded:
      return [...action.payload];
    case GlobalReset.type:
    case ReportsActionTypes.ResetToInitialState:
      return [...initialState.agenciesList];
  }
  return state;
};

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<ReportsState>
>('[Reports] Registered Reducers');
