import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { PackageOfferDto } from '@libs/shared/models/offer.model';

@Component({
  selector: 'staffnow-manage-package-offer-files-modal',
  templateUrl: './manage-package-offer-files-modal.component.html'
})
export class ManagePackageOfferFilesModalComponent {
  @Input() public packageOffer: PackageOfferDto = null;
  @Input() public reloadPackageOfferCallback: Function = null;

  constructor(
    public bsModalRef: BsModalRef
  ) {}

  close() {
    this.bsModalRef.hide();
  }

  public reloadPackageOffer() {
    this.reloadPackageOfferCallback();    
  }
}
