import {Component, OnInit} from '@angular/core';
import {MENU_ITEMS_ENUM} from '../shared/config/menuItems.enum';
import {
  BackofficeFeatureAccessPolicyService
} from "@back-office/shared/services/backoffice-feature-access-policy/backoffice-feature-access-policy.service";

@Component({
  selector: 'staffnow-reports-bo',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public menuItems: Array<string> = [];
  public activeItem: string = null;

  constructor(
    private accessPolicyService: BackofficeFeatureAccessPolicyService
  ) {
  }

  ngOnInit() {
    this.menuItems = [];
    if (this.accessPolicyService.isBackofficeEuropeOrHasCrossRegionAccess()) {
      this.menuItems.push(MENU_ITEMS_ENUM.BACKOFFICE);
    }
    this.menuItems.push(
      MENU_ITEMS_ENUM.TECHNICIANS,
      MENU_ITEMS_ENUM.MROS,
      MENU_ITEMS_ENUM.AGENCIES
    );
    this.activeItem = this.menuItems[0];
  }

  public handleSelectItem(item: string): void {
    this.activeItem = item;
  }
}
