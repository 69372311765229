import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import {
  AcceptAgencyOfferContract,
  DeclineAgencyOfferContract,
  MarkAgencyOfferContractAsDocumentsNeeded
} from '@libs/request-overview-common/state/requests-overview.actions';
import { Store } from '@ngrx/store';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { ModalService } from '@libs/common-ui/services/modal.service';

@Component({
  selector: 'agency-offer-info-expand',
  templateUrl: './agency-offer-info-expand.component.html',
  styleUrls: ['./agency-offer-info-expand.component.scss']
})
export class AgencyOfferInfoExpandComponent {
  @Input() public contract: AgencyOfferContractOutDto = null;
  @Input() public isLoading: boolean;

  @Output()
  public openPublicProfile: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private store: Store,
    private modalService: ModalService
  ) {}

  get hasReasons(): boolean {
    return this.contract.isRejected || this.contract.areDocumentsNeeded
  }

  get isOfferEndedOrClosed(): boolean {
    return this.contract.isAgencyOfferFinished || this.contract.isAgencyOfferExpired;
  }

  get hasTechnicianAcceptedAlready(): boolean {
    return this.contract.technicianStatus === ContractStatus.accepted;
  }

  get hasTechnicianDeclinedAlready(): boolean {
    return this.contract.technicianStatus === ContractStatus.rejected;
  }

  public acceptContract() {
    this.store.dispatch(AcceptAgencyOfferContract({
      refNumber: this.contract.offerRefNumber,
      url: getLink(this.contract, RequestOverviewLinkRel.AcceptAgencyOfferContract).href,
      isBackoffice: true
    }));
  }

  public declineContract() {
    this.store.dispatch(DeclineAgencyOfferContract({
      refNumber: this.contract.offerRefNumber,
      url: getLink(this.contract, RequestOverviewLinkRel.DeclineAgencyOfferContract).href,
      isBackoffice: true
    }));
  }

  public markContractAsDocumentsNeeded() {
    this.modalService.openTextAreaDialog(
      'Request More Documents',
      'Please, inform an explanation for requesting additional documents.',
      null,
      (text) => {
        this.store.dispatch(MarkAgencyOfferContractAsDocumentsNeeded({
          refNumber: this.contract.offerRefNumber,
          url: getLink(this.contract, RequestOverviewLinkRel.MarkAgencyOfferContractAsDocumentsNeeded).href,
          explanation: text,
          isBackoffice: false
        }));
      }
    );
  }


  public onOpenPublicProfile(entityType, contract: AgencyOfferContractOutDto) {
    this.openPublicProfile.emit({ entityType, contract: { ...contract } });
  }
}
