<div class="guidelines">
    Here you can see the list of the ({{ languages.length }}) available
    languages that technicians can select in their profile. They are always
    listed in alphabetical order.
</div>
<div class="language-list">
    <div class="language" *ngFor="let language of languages">
        {{ language.name }}
    </div>
</div>
