import { Component, OnInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';

import {
  FaqsListLoaded,
  GetFaqsList,
  ManageContentActionTypes,
  SubmitFaqsList
} from '../../state/manage-content.actions';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { BulletPointItem } from '@libs/shared/models/bullet-point.model';

@Component({
  selector: 'staffnow-faq-bo',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqBOComponent implements OnInit {
  public faqs: BulletPointItem[] = [];
  public isTouched: boolean = false;

  get canSubmit() {
    return this.isTouched;
  }

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject
  ) {
    this.actionsSubject
      .pipe(
        filter(
          action => action.type === ManageContentActionTypes.FaqsListLoaded
        )
      )
      .subscribe((action: FaqsListLoaded) => {
        this.faqs = cloneDeep(action.faqsList);
      });
  }

  public ngOnInit(): void {
    this.store.dispatch(new GetFaqsList());
  }

  public addFaqItem(): void {
    this.isTouched = true;
    this.faqs.push({ label: '', description: '' });
  }

  public handleQuestionChange({ index, value }): void {
    this.isTouched = true;
    this.faqs[index].label = value;
  }

  public handleAnswerChange({ index, value }): void {
    this.isTouched = true;
    this.faqs[index].description = value;
  }

  public submitFaqs(): void {
    this.isTouched = false;
    this.store.dispatch(new SubmitFaqsList({ bulletPoints: this.faqs }));
  }

  public handleItemDelete(itemIndex: any): void {
    this.isTouched = true;
    this.faqs = this.faqs.filter((item, index) => index !== itemIndex);
  }
}
