<div class="general-settings" [ngStyle]="{ padding: '20px' }">
    <span class="font-weight-bold">{{ 'Aircraft list ' | translate }} ({{ aircraftList.length }}):</span>
    <div class="text-muted">
        {{ '*Here you can add and remove aircraft from the list.' | translate }}
    </div>
    <div class="add-entity">
        <input type="text" class="form-control name" [(ngModel)]="newAircraftName" />
        <general-button
            text="Add"
            [isDisabled]="!newAircraftName || isLoading"
            [customClass]="'btn-loader'"
            (onClick)="addNewAircraft()"
        ></general-button>
    </div>
    <div class="entries">
        @for (entry of aircraftList; track entry.id; let index = $index) {
        <div class="single-entry">
            <input
                type="text"
                class="form-control company-name"
                [ngModel]="entry.name"
                (ngModelChange)="updateAircraftList(entry, $event, index)"
            />
            <borderless-button
                (onClick)="deleteAircraft(index)"
                actionType="danger"
                color="dark"
                icon="delete"
            ></borderless-button>
        </div>
        }
    </div>
    <general-button
        class="save-button"
        text="Save"
        [isLoading]="isLoading"
        [isDisabled]="!isChangeMade"
        [customClass]="'btn-loader'"
        (onClick)="saveChanges()"
    ></general-button>
</div>
