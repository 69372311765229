import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { EnvironmentLoaded } from '@libs/shared/bms-common/environment/environment.actions';
import { environment as builtEnv} from '../../../../../../apps/back-office/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoadApiRoot, ReloadApiRoot } from '@libs/shared/bms-common/api-root/api-root.actions';
import { AppRegionEnum } from '@libs/shared/bms-common/environment/environment.model';

@UntilDestroy()
@Injectable()
export class EnvironmentEffects {
  constructor(private readonly actions: Actions, private readonly httpService: HttpClient) {}

  loadEnvironment$ = createEffect(() => {
    return this.actions.pipe(
      ofType(LoadApiRoot, ReloadApiRoot),
      switchMap(action => {
        const baseUrl = action.region === AppRegionEnum.EU ? builtEnv.europeApiUrl : builtEnv.usaApiUrl;
        return this.httpService
          .get(baseUrl + builtEnv.environmentEndpoint)
          .pipe(map(response => {
            return EnvironmentLoaded({ payload: response})
          }))
      })
      );
  });
}
