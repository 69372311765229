<div *ngIf="(mros$ | async) as mros">
    <ng-select
        bindLabel="name"
        bindValue="uuid"
        [(ngModel)]="selectedMroUuid"
        [items]="mros"
        [loading]="loading"
        (close)="onSearch('')"
        (clear)="onSearch('')"
        (scrollToEnd)="loadMore(mros)"
        (search)="onSearch($event.term)"
        [searchable]="true"
        [placeholder]="'GENERAL.SEARCH_BY_NAME' | translate"
    >
    </ng-select>
</div>
