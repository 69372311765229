import { Action } from '@ngrx/store';

export enum ReportsActionTypes {
  LoadMroGroupsList = '[Reports - BO] Load list of MRO Groups',
  MroGroupsListLoaded = '[Reports - BO] Mro groups list loaded',
  FailedToLoadMroGroupsList = '[Reports - BO] Failed to load list of MRO Groups',
  LoadAgenciesList = '[Reports - BO] Load list of agencies',
  AgenciesListLoaded = '[Reports - BO] Agencies list loaded',
  FailedToLoadAgenciesList = '[Reports - BO] Failed to load list of agencies',
  GenerateReportBO = '[Reports - BO] Generate report in BO',
  ReportGenerated = '[Reports - BO] Report generated successfully',
  FailedToGenerateReportBO = '[Reports - BO] Failed to generate report in BO',
  ResetToInitialState = '[Reports - BO] Reset reports lists to initial state'
}

export class ResetToInitialState implements Action {
  public readonly type = ReportsActionTypes.ResetToInitialState;
}

export class LoadMroGroupsList implements Action {
  public readonly type = ReportsActionTypes.LoadMroGroupsList;
}

export class MroGroupsListLoaded implements Action {
  public readonly type = ReportsActionTypes.MroGroupsListLoaded;
  public readonly payload = [];

  constructor(payload: Array<any>) {
    this.payload = payload;
  }
}

export class LoadAgenciesList implements Action {
  public readonly type = ReportsActionTypes.LoadAgenciesList;
}

export class AgenciesListLoaded implements Action {
  public readonly type = ReportsActionTypes.AgenciesListLoaded;
  public readonly payload = [];

  constructor(payload: Array<any>) {
    this.payload = payload;
  }
}

export class GenerateReportBO implements Action {
  public readonly type = ReportsActionTypes.GenerateReportBO;
  public readonly payload: any = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class ReportGenerated implements Action {
  public readonly type = ReportsActionTypes.ReportGenerated;
}

export class FailedToGenerateReportBO implements Action {
  public readonly type = ReportsActionTypes.FailedToGenerateReportBO;
}

export class FailedToLoadMroGroupsList implements Action {
  public readonly type = ReportsActionTypes.FailedToLoadMroGroupsList;
}

export class FailedToLoadAgenciesList implements Action {
  public readonly type = ReportsActionTypes.FailedToLoadAgenciesList;
}


export type ReportsAction =
  | ResetToInitialState
  | LoadMroGroupsList
  | MroGroupsListLoaded
  | FailedToLoadMroGroupsList
  | LoadAgenciesList
  | AgenciesListLoaded
  | FailedToLoadAgenciesList

export const fromReportsActions = {
  ResetToInitialState,
  LoadMroGroupsList,
  MroGroupsListLoaded,
  FailedToLoadMroGroupsList,
  LoadAgenciesList,
  AgenciesListLoaded,
  FailedToLoadAgenciesList,
};
