<div class="guidelines">
    <p class="guidelines">
        Here you can configure the available personal documents for all the technicians titles
        depending on the region selected for the Backoffice
    </p>
    <p class="guidelines">The available actions are:</p>
    <ul>
        <li *ngFor="let guideline of manageDocumentsGuidelines">
            <strong>{{ guideline.action }}:</strong> {{ guideline.description }}
        </li>
    </ul>
</div>
<div class="documents-selection-form">
    <staffnow-document-types-list
        [documentTypes]=documentTypes
        documentAreaTitle="Personal"
    ></staffnow-document-types-list>
    <general-button
        (onClick)="savePersonalDocuments()"
        [isDisabled]="hasChanges()"
        id="save-personal-documents"
        text="Save Changes"
    ></general-button>
</div>

