import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { BulletPointItem } from '@libs/shared/models/bullet-point.model';


@Component({
  selector: 'staffnow-faq-item',
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss']
})
export class FaqItemComponent {
  @Input() public faqItem: BulletPointItem;

  @Input() public faqIndex: number;

  @Output() public updateQuestionValue: EventEmitter<any> = new EventEmitter();

  @Output() public updateAnswerValue: EventEmitter<any> = new EventEmitter();

  @Output() public deleteItem: EventEmitter<any> = new EventEmitter();

  public config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }]
    ]
  };

  constructor(private confirmationService: ModalService) {}

  public editorReady(editor: any) {
    editor.clipboard.dangerouslyPasteHTML(0, this.faqItem.description);
  }

  public handleQuestionChange($event: any) {
    this.updateQuestionValue.emit({
      index: this.faqIndex,
      value: $event.target.value
    });
  }

  public handleAnswerChange($event: any) {
    this.updateAnswerValue.emit({ index: this.faqIndex, value: $event.html });
  }

  public handleItemDelete() {
    this.confirmationService.openConfirmModal(
      'SYSTEM.INFO.DELETE_FAQ_ITEM',
      () => this.deleteItem.emit(this.faqIndex)
    );
  }
}
