<staffnow-modal-window
    [content]="registration"
    [autoSize]="true"
    [disableBackdrop]="true"
    #modalWindow
></staffnow-modal-window>

<ng-template #registration>
    <div class="user-reg-form-container">
        <h2 class="h2">
            <strong>{{ 'User Information' | translate }}</strong>
        </h2>
        <form
            autocomplete="off"
            novalidate
            [ngrxFormState]="registrationForm | async"
        >
            <label for="firstName">{{ 'First Name*' | translate }}</label>
            <input
                id="firstName"
                class="form-control"
                name="firstName"
                type="text"
                [ngrxFormControlState]="
                    registrationFormValue.controls.firstName
                "
            />
            <label for="lastName">{{ 'Last Name*' | translate }}</label>
            <input
                id="lastName"
                class="form-control"
                name="lastName"
                type="text"
                [ngrxFormControlState]="registrationFormValue.controls.lastName"
            />
            <ng-container *ngIf="linkToFacility">
                <label for="position">Position*</label>
                <input
                    id="position"
                    type="text"
                    class="form-control"
                    name="position"
                    [ngrxFormControlState]="
                        registrationFormValue.controls.position
                    "
                />
                @if (entityGroup === 'Aviation Company') {
                    @if (mros$ | async; as mroList) {
                        <ng-container>
                            <label for="link">{{
                                    'Link to Aviation Company*' | translate
                                }}</label>
                            <ng-select
                                [ngrxFormControlState]="registrationFormValue.controls.facilityUuid"
                                bindLabel="name"
                                bindValue="uuid"
                                class="mb-3"
                                [items]="mroList"
                                [loading]="loading"
                                (close)="onClose()"
                                (clear)="onSearch('')"
                                (scrollToEnd)="loadMore(mroList)"
                                (search)="onSearch($event.term)"
                                [closeOnSelect]="true"
                                [searchable]="true"
                                [placeholder]="'REQUEST_LISTING.SELECT_AVIATION_COMPANY' | translate"
                            ></ng-select>
                        </ng-container>
                    }
                } @else {
                    <label for="link">{{
                        'Link to ' + entityGroup + '*' | translate
                    }}</label>
                    <select
                        id="link"
                        class="custom-select"
                        name="link"
                        [ngrxFormControlState]="
                            registrationFormValue.controls.facilityUuid
                        "
                    >
                        <option value="">
                            {{ 'Select a ' + entityGroup | translate }}
                        </option>
                        <option
                            *ngFor="let facility of facilityList"
                            value="{{ facility.uuid }}"
                        >
                            {{ facility.name }}
                        </option>
                    </select>
                }
            </ng-container>
            <ng-container *ngIf="!this.isAgencyRelatedFacility()">
                <label for="role">{{ 'Role*' | translate }}</label>
                <select
                    id="role"
                    class="custom-select"
                    name="role"
                    (change)="handleRoleChange()"
                    [ngrxFormControlState]="registrationFormValue.controls.role"
                >
                    <option value="">{{ 'Select a Role' | translate }}</option>
                    <option
                        *ngFor="let role of userRoles"
                        value="{{ role.serverName }}"
                    >
                        {{ role.name }}
                    </option>
                </select>
            </ng-container>
            <label
                *ngIf="
                    registrationFormValue.controls.role?.value ===
                    'ROLE_AGENCY_TECHNICIAN'
                "
                for="agency"
                >{{ 'Agency*' | translate }}</label
            >
            <simple-select
                *ngIf="
                    registrationFormValue.controls.role?.value ===
                    'ROLE_AGENCY_TECHNICIAN'
                "
                id="agency"
                [items]="agenciesList"
                placeholder="Select an Agency"
                [getValue]="getFacilityUuid"
                [ngrxFormControlState]="
                    registrationFormValue.controls.agencyUuid
                "
            >
            </simple-select>
            <label
                *ngIf="
                    registrationFormValue.controls.role?.value ===
                    'ROLE_TECHNICIAN'
                "
                for="staffieMro"
                >{{ 'Mro*' | translate }}</label
            >
            <simple-select
                *ngIf="
                    registrationFormValue.controls.role?.value ===
                    'ROLE_TECHNICIAN'
                "
                id="staffieMro"
                [items]="mroList"
                placeholder="Select an Mro"
                [getValue]="getFacilityUuid"
                [ngrxFormControlState]="
                    registrationFormValue.controls.staffieMroUuid
                "
            >
            </simple-select>
            <label for="email">{{ 'Email*' | translate }}</label>
            <input
                id="email"
                class="form-control"
                type="text"
                name="email"
                [ngrxFormControlState]="registrationFormValue.controls.email"
            />
            <ng-container *ngIf="linkToFacility">
                <label for="defaultUser">{{
                    'Default user' | translate
                }}</label>
                <input
                    class="ml-2"
                    id="defaultUser"
                    type="checkbox"
                    name="defaultUser"
                    [ngrxFormControlState]="
                        registrationFormValue.controls.defaultUser
                    "
                />
            </ng-container>
        </form>
        <div class="action-buttons">
            <general-button
                (onClick)="closeRegistrationForm()"
                backgroundStyle="outline"
                text="CANCEL"
            ></general-button>
            <general-button
                text="REGISTER"
                [isLoading]="isLoading"
                [isDisabled]="
                    !registrationFormValue.isValid ||
                    !registrationFormValue.isTouched
                "
                [customClass]="'btn-loader'"
                (onClick)="submitForm()"
            ></general-button>
        </div>
    </div>
</ng-template>
