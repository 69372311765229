import { InjectionToken } from '@angular/core';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';

import {
  initialState,
  ManageContentStateModel
} from './manage-content-state.model';
import {
  AmeTitleDocumentsOptionsFailed,
  AmeTitleDocumentsOptionsLoaded,
  ExistingOptionsLoaded,
  FailedLoadTitles,
  FailedSubmitTitles, FailedToLoadAvailableLocations,
  FailedToUpdateDocumentsForTitle, LoadAvailableLocations,
  LoadTitles,
  SubmitTitles,
  SuccessfulLoadTitles,
  SuccessfulUpdateDocumentsForTitle, SuccessfulLoadAvailableLocations,
  UpdateDocumentsForTitle, UpdateAvailableLocations, SuccessfulLoadDocumentTypes, FailedToLoadDocumentTypes
} from './manage-content.actions'
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export function getReducers(): ActionReducerMap<ManageContentStateModel> {
  return {
    titles: getTitlesReducer,
    locations: getLocationsReducer,
    isLoading: isLoadingReducer,
    ameTitleDocumentsOptions: ameTitleDocumentsOptionsReducer,
    existingOptionsForTitle: existingOptionsForTitleReducer,
    documentTypes: documentTypesReducer
  };
}

const getTitlesReducer = createReducer(
  initialState.titles,
  on(GlobalReset, () => initialState.titles),
  on(SuccessfulLoadTitles, (state, { titles }) => titles),
  on(FailedLoadTitles, () => [])
);

const getLocationsReducer = createReducer(
  initialState.locations,
  on(GlobalReset, () => initialState.locations),
  on(SuccessfulLoadAvailableLocations, (state, { locations }) => locations),
  on(FailedToLoadAvailableLocations, () => [])
);

const isLoadingReducer = createReducer(
  initialState.isLoading,
  on(GlobalReset, () => initialState.isLoading),
  on(
    SuccessfulLoadTitles,
    FailedLoadTitles,
    FailedSubmitTitles,
    SuccessfulUpdateDocumentsForTitle,
    FailedToUpdateDocumentsForTitle,
    SuccessfulLoadAvailableLocations,
    FailedToLoadAvailableLocations,
    () => false
  ),
  on(LoadTitles, SubmitTitles, UpdateDocumentsForTitle, LoadAvailableLocations, UpdateAvailableLocations, () => true)
);

const ameTitleDocumentsOptionsReducer = createReducer(
  initialState.ameTitleDocumentsOptions,
  on(GlobalReset, () => initialState.ameTitleDocumentsOptions),
  on(
    AmeTitleDocumentsOptionsLoaded,
    (state, { ameTitleDocumentsOptions }) => ameTitleDocumentsOptions
  ),
  on(
    AmeTitleDocumentsOptionsFailed,
    () => initialState.ameTitleDocumentsOptions
  )
);

const existingOptionsForTitleReducer = createReducer(
  initialState.existingOptionsForTitle,
  on(GlobalReset, () => initialState.existingOptionsForTitle),
  on(
    ExistingOptionsLoaded,
    (state, { existingOptionsForTitle }) => existingOptionsForTitle
  ),
  on(AmeTitleDocumentsOptionsFailed, () => initialState.existingOptionsForTitle)
);

const documentTypesReducer = createReducer(
  initialState.documentTypes,
  on(GlobalReset, () => initialState.documentTypes),
  on(
    SuccessfulLoadDocumentTypes,
    (state, { documentTypes }) => documentTypes
  ),
  on(FailedToLoadDocumentTypes, () => initialState.documentTypes)
);

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<ManageContentStateModel>
>('[Manage Content] Registered Reducers');
