import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { debounceTime, EMPTY, of } from 'rxjs';

import { ToastMessageService } from '@libs/toast-messages/toast-message.service';
import { USERS_STATE_KEY, UsersListingModel, UsersState } from './users-state.model';
import {
  CreateNewGroup,
  EditGroup,
  FacilitiesListReceived,
  FailedDeleteUser,
  FailedToCreateNewGroup,
  FailedToEditNewGroup,
  FailedToGetFacilities,
  FailedToGetUsersList,
  FailedToLoadMROGroups,
  FailedToRegisterNewFacility,
  FailedUserRegistration,
  GetEntitiesList,
  GetMroGroupsList,
  GetPageOfFacilities,
  LoadedMoreFacilitiesReceived,
  LoadMoreFacilities,
  MroGroupsListLoaded,
  SubmitDeleteFacility,
  SubmitDeleteUser,
  SubmitFacilityRegistrationForm,
  SubmitRegistrationForm,
  SuccessCreatingNewGroup,
  SuccessEditingGroup,
  SuccessfullyDeletedUser,
  SuccessfullyRegistredFacility,
  SuccessfulUserRegistration,
  UsersActionTypes,
  UsersListReceived
} from './users.actions';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { UsersLinkRel } from '@libs/shared/linkrels/users.linkrel';
import { getEmbeddedResource, getLink } from '@libs/shared/bms-common/rest/resource.utils';
import { getReqUrl } from '@libs/shared/helpers/get-url-from-resource';
import { UsersService } from '@libs/shared/services/users.service';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { Link } from '@libs/shared/bms-common/rest/resource.model';
import { CustomURLEncoder } from '@libs/shared/helpers/custom-url-encoder';
import { DURATION_1000_MILLISECONDS } from '@libs/shared/constants/duration.constants';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRoles } from '@libs/shared/models/roles.enum';

@Injectable()
export class UsersEffects {
  PAGE_SIZE = 20;
  public submitUserRegistrationForm$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.SubmitRegistrationForm),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: SubmitRegistrationForm) => {
        let entity = null;

        if (
          this.currentEntity === EntityTypes.MRO_GROUPS ||
          this.currentEntity === EntityTypes.MRO ||
          this.currentEntity === EntityTypes.MRO_FACILITIES
        ) {
          entity = EntityTypes.MRO;
        } else if (this.currentEntity === EntityTypes.AGENCY_FACILITIES || this.currentEntity === EntityTypes.AGENCY) {
          entity = EntityTypes.AGENCY;
        } else {
          entity = this.currentEntity;
        }

        let reqLink = this.createUsersOrFacilitiesEndpoint(entity, true);
        if (action.payload.role === UserRoles.ROLE_TECHNICIAN) {
          reqLink = this.getRegisterTechnicianEndpoint();
        }
        return this.httpService.post(reqLink.href, action.payload).pipe(
          switchMap((response: any) => {
            this.toastMessageService.success(response.message);
            return [new SuccessfulUserRegistration(), new GetEntitiesList(this.USERS_LIST.page, this.currentEntity)];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedUserRegistration());
          })
        );
      })
    )
  );

  public submitFacilityRegistrationForm$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.SubmitFacilityRegistrationForm),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: SubmitFacilityRegistrationForm) => {
        let entity = null;

        if (
          this.currentEntity === EntityTypes.MRO_GROUPS ||
          this.currentEntity === EntityTypes.MRO ||
          this.currentEntity === EntityTypes.MRO_FACILITIES
        ) {
          entity = EntityTypes.MRO;
        } else if (this.currentEntity === EntityTypes.AGENCY_FACILITIES || this.currentEntity === EntityTypes.AGENCY) {
          entity = EntityTypes.AGENCY;
        } else {
          entity = this.currentEntity;
        }

        const reqLink = this.createUsersOrFacilitiesEndpoint(entity, false);

        if (reqLink) {
          return this.httpService.post(reqLink.href, action.formValue).pipe(
            switchMap(() => {
              this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.FACILITY_CREATED'));
              return [new SuccessfullyRegistredFacility(), new GetPageOfFacilities({page: 0, term: ''}, this.currentEntity)];
            }),
            catchError(response => {
              this.errorMessageService.handleErrorResponse(response);
              return of(new FailedToRegisterNewFacility());
            })
          );
        }
      })
    )
  );

  public getFacilitiesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.GetPageOfFacilities),
      debounceTime((500)),
      switchMap((action: GetPageOfFacilities) => {
        this.currentEntity = action.entity;
        const reqLink: Link = this.getFacilitiesEndpoint(action.entity);
        const {term, page} = action.payload;
        return this.getFacilities(reqLink, term, page, FacilitiesListReceived);
      })
    )
  );

  public loadMoreFacilities$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.LoadMoreFacilities),
      withLatestFrom(this.store.pipe(select(state => state[USERS_STATE_KEY]))),
      switchMap(([action, state]: [LoadMoreFacilities, UsersState]) => {
        this.currentEntity = action.entity;
        const reqLink: Link = this.getFacilitiesEndpoint(action.entity);
        const {term, page} = state.listOfFacilities;
        return this.getFacilities(reqLink, term, page + 1, LoadedMoreFacilitiesReceived);
      })
    )
  );

  private getFacilities(reqLink: Link, term: string, page: number, actionClass: any) {
    return this.httpService.get(`${reqLink.href.split('?')[0]}?searchTerm=${term}&page=${page}&size=${this.PAGE_SIZE}&sort=name,asc`).pipe(
      map(resp => new actionClass({ ...resp, term })),
      catchError(response => {
        this.errorMessageService.handleErrorResponseWithCustomMessage(
          response,
          this.translateService.instant('SYSTEM.INFO.FAILED_GET_FACILITY_LIST')
        );
        return of(new FailedToGetFacilities());
      })
    );
  }

  public getEntitiesList$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.GetEntitiesList),
      withLatestFrom(this.store.pipe(select(state => state[USERS_STATE_KEY]))),
      switchMap(([action, state]: [GetEntitiesList, UsersState]) => {
        this.currentEntity = action.entity;
        const reqLink = this.getUsersEndpoint(action.entity);
        const params = new HttpParams({
          fromObject: this.usersService.createFilterValueObject(
            state.usersListingFilters,
            action.sortingQuery,
            action.payload
          ),
          encoder: new CustomURLEncoder()
        });
        return this.httpService.get(reqLink.href, { params }).pipe(
          map(resp => new UsersListReceived(resp)),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              this.translateService.instant('SYSTEM.ERROR.FAILED_TO_GET_USERS_LIST')
            );
            return of(new FailedToGetUsersList());
          })
        );
      })
    )
  );

  public createNewGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.CreateNewGroup),
      throttleTime(DURATION_1000_MILLISECONDS),
      switchMap((action: CreateNewGroup) => {
        return this.httpService.post(this.userEndpoints[UsersLinkRel.CreateGroup].href, action.formValue).pipe(
          switchMap(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.MRO_GROUP_CREATED'));
            return [new SuccessCreatingNewGroup(), new GetMroGroupsList()];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              this.translateService.instant('SYSTEM.ERROR.FAILED_TO_CREATE_NEW_GROUP')
            );
            return of(new FailedToCreateNewGroup());
          })
        );
      })
    )
  );

  public editGroup$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.EditGroup),
      switchMap((action: EditGroup) => {
        return this.httpService.put(getReqUrl(action.group, UsersLinkRel.EditGroup), action.newValue).pipe(
          map(() => {
            this.toastMessageService.success(
              this.translateService.instant('You have successfully edited Aviation Company Group!')
            );
            return new SuccessEditingGroup();
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              this.translateService.instant('SYSTEM.ERROR.FAILED_TO_EDIT_GROUP')
            );
            return of(new FailedToEditNewGroup());
          })
        );
      })
    )
  );
  public getMroGroupsList$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.GetMroGroupsList),
      switchMap((action: GetMroGroupsList) => {
        const reqLink = action.withFilters
          ? this.userEndpoints[UsersLinkRel.FilterMroGroups]
          : this.userEndpoints[UsersLinkRel.GetAllMroGroups];
        return this.httpService.get(reqLink.href).pipe(
          map((resp: any) => new MroGroupsListLoaded(getEmbeddedResource(resp, 'groups'))),
          catchError(response => {
            this.errorMessageService.handleErrorResponseWithCustomMessage(
              response,
              this.translateService.instant('SYSTEM.ERROR.FAILED_TO_LOAD_MRO_GROUPS')
            );
            return of(new FailedToLoadMROGroups());
          })
        );
      })
    )
  );

  public deleteUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(UsersActionTypes.SubmitDeleteUser),
      switchMap((action: SubmitDeleteUser) => {
        return this.httpService.delete(action.deleteUserLink).pipe(
          switchMap(() => {
            this.toastMessageService.success(this.translateService.instant('SYSTEM.INFO.USER_DELETED'));
            return [new SuccessfullyDeletedUser(), new GetEntitiesList(this.USERS_LIST.page, this.currentEntity)];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return of(new FailedDeleteUser());
          })
        );
      })
    )
  );

  public deleteFacility$ = createEffect(() =>
    this.actions.pipe(
      ofType(SubmitDeleteFacility),
      switchMap(action => {
        return this.httpService.delete(action.url).pipe(
          switchMap(() => {
            this.toastMessageService.success('Successfully deleted!');
            return [new GetEntitiesList(this.USERS_LIST.page, this.currentEntity)];
          }),
          catchError(response => {
            this.errorMessageService.handleErrorResponse(response);
            return EMPTY;
          })
        );
      })
    )
  );

  private USERS_LIST: UsersListingModel = null;
  private currentEntity: string = null;
  private userEndpoints: { [key: string]: Link } = {};

  constructor(
    private actions: Actions,
    private toastMessageService: ToastMessageService,
    private store: Store<any>,
    private httpService: HttpClient,
    private usersService: UsersService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService
  ) {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      const keys = Object.keys(apiRoot._links);

      keys.forEach(key => {
        this.userEndpoints[key] = getLink(apiRoot, key);
      });
    });

    this.store.pipe(select(state => state[USERS_STATE_KEY])).subscribe(state => {
      this.USERS_LIST = state.usersList;
    });
  }

  private getUsersEndpoint(entity: string): Link {
    switch (entity) {
      case EntityTypes.ADMIN:
        return this.userEndpoints[UsersLinkRel.GetAdminUsers];
      case EntityTypes.MRO:
        return this.userEndpoints[UsersLinkRel.GetMROUsers];
      case EntityTypes.AGENCY:
        return this.userEndpoints[UsersLinkRel.GetAgencyUsers];
      case EntityTypes.TECHNICIAN:
        return this.userEndpoints[UsersLinkRel.GetTechnicianUsers];
      case EntityTypes.MRO_FACILITIES:
        return this.userEndpoints[UsersLinkRel.GetMROFacilitiesFull];
      case EntityTypes.MRO_GROUPS:
        return this.userEndpoints[UsersLinkRel.FilterMroGroups];
      case EntityTypes.AGENCY_FACILITIES:
        return this.userEndpoints[UsersLinkRel.GetAgencyFacilitiesFull];
      default:
        return null;
    }
  }

  private getFacilitiesEndpoint(entity: string): Link {
    switch (entity) {
      case EntityTypes.MRO_FACILITIES:
      case EntityTypes.MRO:
      case EntityTypes.MRO_GROUPS:
        return this.userEndpoints[UsersLinkRel.GetMROFacilitiesPaged];
      case EntityTypes.AGENCY_FACILITIES:
      case EntityTypes.AGENCY:
        return this.userEndpoints[UsersLinkRel.GetAgencyFacilities];
      default:
        return null;
    }
  }

  private createUsersOrFacilitiesEndpoint(entity: string, createUsers: boolean): Link {
    if (createUsers) {
      switch (entity) {
        case EntityTypes.ADMIN:
          return this.userEndpoints[UsersLinkRel.CreateAdminUser];
        case EntityTypes.MRO:
          return this.userEndpoints[UsersLinkRel.CreateMROUser];
        case EntityTypes.AGENCY:
          return this.userEndpoints[UsersLinkRel.CreateAgencyUser];
        default:
          return null;
      }
    } else {
      switch (entity) {
        case EntityTypes.MRO:
          return this.userEndpoints[UsersLinkRel.CreateMROFacility];
        case EntityTypes.AGENCY:
          return this.userEndpoints[UsersLinkRel.CreateAgencyFacility];
        default:
          return null;
      }
    }
  }

  private getRegisterTechnicianEndpoint() {
    return this.userEndpoints[UsersLinkRel.CreateTechnicianUser];
  }
}
