import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminRequestOverviewComponent } from './admin-request-overview.component';
import { RequestListingComponent } from './components/request-listing/request-listing.component';
import { UserRoles } from '@libs/shared/models/roles.enum';

const routes: Routes = [
  {
    path: '',
    component: AdminRequestOverviewComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'technicians'
      },
      {
        path: 'technicians',
        component: RequestListingComponent,
        data: {
          role: UserRoles.ROLE_TECHNICIAN
        }
      },
      {
        path: 'aviation-companies',
        component: RequestListingComponent,
        data: {
          role: UserRoles.ROLE_MRO_PRODUCTION
        }
      },
      {
        path: 'agencies',
        component: RequestListingComponent,
        data: {
          role: UserRoles.ROLE_AGENCY
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestOverviewRoutingModule {}
