import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslationModule } from '@libs/translation/translation.module';
import { AgencyOrderingComponent } from './components/agency-ordering/agency-ordering.component';
import { AgencyOrderingRouteModule } from './agency-ordering-routing.module';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';

@NgModule({
  imports: [
    CommonModule,
    AgencyOrderingRouteModule,
    DragDropModule,
    TranslationModule,
    CommonUiModule
  ],
  declarations: [AgencyOrderingComponent],
  exports: [AgencyOrderingComponent]
})
export class AgencyOrderingModule {}
