import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export const AGENCY_REGISTRATION_FORM_ID = 'agencyRegistrationForm';

export interface AgencyRegistrationStateModel {
  name: string;
  location: string;
}

export interface AgencyRegistrationFormState
  extends FormGroupState<AgencyRegistrationStateModel> {}

export const initialAgencyFormState: AgencyRegistrationFormState = createFormGroupState<AgencyRegistrationStateModel>(
  AGENCY_REGISTRATION_FORM_ID,
  {
    name: '',
    location: ''
  }
);
