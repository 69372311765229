<div class="slideshow-upload">
    <div class="form-group">
        <input
            ng2FileSelect
            #fileInput
            hidden
            type="file"
            name="picture"
            class="form-control-file"
            [multiple]="false"
            [uploader]="uploader"
        />
        <general-button
            class="upload-btn"
            text="Upload New Picture"
            [isLoading]="loading"
            [isDisabled]="isPicturesLimitReached"
            [customClass]="'btn-loader'"
            (onClick)="fileInput.click()"
        ></general-button>
        <span class="file-restrictions">{{
            fileRestrictionsTooltip | translate
        }}</span>
    </div>

    <div class="slideshow-pictures-list">
        <div class="item" *ngFor="let picture of slideshowPicturesList">
            <div
                class="image"
                [ngStyle]="{
                    'background-image': 'url(' + pictureUrl(picture) + ')'
                }"
            ></div>
            <div class="image-overlay">
                <i
                    class="material-icons delete-icon"
                    (click)="handlePictureDelete(picture)"
                >
                    delete
                </i>
            </div>
        </div>
    </div>
</div>
