import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AbstractExpandComponent } from '../abstract-expand.component';
import { Store } from '@ngrx/store';
import { QualityAcceptComponent } from '@libs/common-ui/quality-accept/quality-accept.component';
import { addUtils, AgencyContractDto, Contract } from '@libs/shared/models/contract.model';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { StartDatePhase, StartDatePhaseUtils } from '@libs/shared/models/start-date-phase.model';
import { DatePickerDialogComponent } from '@libs/common-ui/pickers/modal/date-picker-dialog/date-picker-dialog.component';
import {
  AcceptStartDate,
  EndContract,
  ProposeStartDate
} from '@libs/request-overview-common/state/requests-overview.actions';
import { JobOpeningContractDeclinationService } from '@libs/shared/services/job-opening-contract-declination.service';
import { ExtendedPendingReason, RejectReason } from '@libs/shared/models/reasons.model';
import { getCurrentUTCDateString, isFirstDateBeforeTheSecond } from '@libs/shared/helpers/date-utils';
import { OfferOutDto } from '@libs/shared/models/offer.model';
import { AdminRequestOverviewLinkRel } from '@libs/shared/linkrels/admin-request-overview.linkrel';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { toReadable, UserRoles } from '@libs/shared/models/roles.enum';
import { ResetContractStatus } from '../../state/admin-requests-overview.actions';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'contracts-list-expand',
  templateUrl: './contracts-list-expand.component.html',
  styleUrls: ['./contracts-list-expand.component.scss']
})
export class ContractsListExpandComponent extends AbstractExpandComponent implements OnChanges {
  @Input() contractsList: Array<AgencyContractDto | Contract> = [];
  @Input() isLoading: boolean;
  @Input() offerVacancies: number;
  @Input() vacancyMatches: number;
  @Input() reasonsArray: Array<RejectReason> = [];
  @Output()
  setAgencyContract: EventEmitter<{ contract: Contract; offer: OfferOutDto }> = new EventEmitter<{
    contract: Contract;
    offer: OfferOutDto;
  }>();

  private isMroAnyQuality(): boolean {
    return this.effectiveRole?.isMroAnyQuality();
  }

  constructor(
    private store: Store<any>,
    private bsModalService: BsModalService,
    private modalService: ModalService,
    private jobOpeningContractDeclinationService: JobOpeningContractDeclinationService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnChanges() {
    if (this.offer) {
      this.contractsList = this.contractsList.map(contract => ({
        ...contract,
        endDate: contract.endDate ? contract.endDate : contract.provisionalEndDate
      }));
    }
  }

  isMroProduction(): boolean {
    return this.effectiveRole?.isMroProduction();
  }

  isMroHumanResources(): boolean {
    return this.effectiveRole?.isHumanResources();
  }

  shouldDisplayAcceptButton(contract: Contract): boolean {
    return this.canAccept(contract) && this.isActingAsAppropriateRole(contract);
  }

  shouldDisplayDeclineButton(contract: Contract): boolean {
    return this.canDecline(contract) && this.isActingAsAppropriateRole(contract);
  }

  get isAgency() {
    return this.effectiveRole?.isAgency();
  }

  isDeclineDateLimitReached(contract: AgencyContractDto): boolean {
    return isFirstDateBeforeTheSecond(contract.agencyDeclineDateLimit, DateTime.utc().toString());
  }

  acceptContractMro(contract: Contract): void {
    if (this.isMroAnyQuality() || (this.isMroHumanResources() && !contract.requiresQualityAcceptance)) {
      this.onAcceptMroQuality(contract);
    } else {
      this.onAcceptContract(contract);
    }
  }

  onAcceptMroQuality(contract: Contract): void {
    this.bsModalService.show(QualityAcceptComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (notes: string) => {
          const contractWithNotes = {
            ...contract,
            notes: notes ? notes : null
          };
          return this.onAcceptContract(contractWithNotes);
        }
      }
    });
  }

  isAlreadyMroAccepted(contract: Contract): boolean {
    return this.getMroStatus(contract) === ContractStatus.accepted;
  }

  onDeclineContractAsMro(contract: Contract): void {
    if (this.isMroAnyQuality() || (this.isMroHumanResources() && !contract.requiresQualityAcceptance)) {
      this.jobOpeningContractDeclinationService.onDeclineMroQuality(contract, this.reasonsArray);
    } else {
      this.jobOpeningContractDeclinationService.onDeclineMroProduction(contract, this.reasonsArray);
    }
  }

  onDeclineContractAsAgency(contract: Contract): void {
    this.jobOpeningContractDeclinationService.onDeclineAgency(contract, this.reasonsArray[0]);
  }

  onDocumentsNeededMro(contract: Contract): void {
    this.jobOpeningContractDeclinationService.onDocumentsNeeded(contract);
  }

  isAlreadyMroRejected(contract: Contract): boolean {
    return this.getMroStatus(contract) === ContractStatus.rejected;
  }

  private getMroStatus(contract: Contract): ContractStatus {
    return this.isMroAnyQuality()
      ? contract.mroQualityStatus
      : this.isMroProduction()
      ? contract.mroStatus
      : contract.mroHumanResourcesStatus;
  }

  isRejected(agencyStatus: ContractStatus): boolean {
    return agencyStatus === ContractStatus.rejected;
  }

  canDecline(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.DeclineContract);
  }

  canMarkAsDocumentsNeeded(contract: AgencyContractDto): boolean {
    return (
      hasLink(contract, RequestOverviewLinkRel.MarkAsDocumentsNeeded) &&
      ((contract.requiresQualityAcceptance && this.isMroAnyQuality()) ||
        (!contract.requiresQualityAcceptance &&
          contract.requiresHumanResourcesAcceptance &&
          this.isMroHumanResources()) ||
        (!contract.requiresQualityAcceptance &&
          !contract.requiresHumanResourcesAcceptance &&
          contract.requiresProductionAcceptance &&
          this.isMroProduction()))
    );
  }

  private isActingAsAppropriateRole(contract: Contract): boolean {
    return (
      (contract.requiresQualityAcceptance && this.isMroAnyQuality()) ||
      (contract.requiresProductionAcceptance && this.isMroProduction()) ||
      (contract.requiresHumanResourcesAcceptance && this.isMroHumanResources())
    );
  }

  canAccept(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.AcceptContract);
  }

  canEnd(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.EndContract);
  }

  hasAcceptStartDateLink(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.AcceptContractStartDate);
  }

  hasProposeStartDateLink(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.ProposeContractStartDate);
  }

  datePhaseIsAccepted(contract: Contract): boolean {
    return StartDatePhaseUtils.isAccepted(contract.startDatePhase);
  }

  public acceptStartDate(contract: Contract): void {
    this.store.dispatch(AcceptStartDate({ contract }));
  }

  public handleStartDateProposal(contract: Contract): void {
    this.bsModalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (startDate: string) => this.proposeStartDate(contract, startDate),
        selectedDate: this.getPreselectedDate(),
        warningMessage: this.warningMessage(contract),
        title: this.translateService.instant('TASKS.VIEW.DESCRIPTION.PROPOSE_START_DATE'),
        reminders: [
          {
            label: this.translateService.instant('SYSTEM.INFO.START_DATE_AGENCY_REMINDER.LABEL'),
            text: this.translateService.instant('SYSTEM.INFO.START_DATE_AGENCY_REMINDER.TEXT')
          },
          {
            label: this.translateService.instant('SYSTEM.INFO.START_DATE_PERMANENT_MRO_REMINDER.LABEL'),
            text: this.translateService.instant('SYSTEM.INFO.START_DATE_PERMANENT_MRO_REMINDER.TEXT')
          }
        ]
      }
    });
  }

  public handleContractEnd(contract: Contract): void {
    this.bsModalService.show(DatePickerDialogComponent, {
      ignoreBackdropClick: true,
      initialState: {
        onAcceptCallback: (endDate: string) =>
          this.store.dispatch(
            EndContract({
              contract: contract,
              endDate
            })
          ),
        title: 'End contract',
        minDate: this.offer.periodFrom,
        maxDate: getCurrentUTCDateString(),
        reminders: [
          {
            label: 'SYSTEM.INFO.END_CONTRACT_REMINDER.LABEL',
            text: 'SYSTEM.INFO.END_CONTRACT_REMINDER.TEXT'
          }
        ]
      }
    });
  }

  private proposeStartDate(contract: Contract, startDate: string): void {
    this.store.dispatch(ProposeStartDate({ startDate, contract }));
  }

  private getPreselectedDate(): string {
    const today = getCurrentUTCDateString();
    const offerStartDate = this.offer.periodFrom;
    return isFirstDateBeforeTheSecond(offerStartDate, today) ? today : offerStartDate;
  }

  private warningMessage(contract: Contract): string {
    if (this.datePhaseIsAccepted(contract)) {
      return 'This contract already has a start date. If you change it, the date selection process will begin again.';
    }
    return null;
  }

  public isPendingOrUnset(phase: StartDatePhase): boolean {
    return StartDatePhaseUtils.isPendingOrUnset(phase);
  }

  public isProposed(phase: StartDatePhase): boolean {
    return StartDatePhaseUtils.isProposed(phase);
  }

  public getProposedByLabel(phase: StartDatePhase): string {
    if (StartDatePhaseUtils.isProposedByMRO(phase)) {
      return 'proposed by<br>Aviation Company';
    }
    return 'proposed by Agency';
  }

  public getPendingReason(contract: AgencyContractDto): ExtendedPendingReason {
    const contractWithUtils = addUtils(contract);
    return contractWithUtils.hasPendingReasons()
      ? {
          ...contractWithUtils.getPendingReason(),
          name: 'More Documents Needed'
        }
      : null;
  }

  public getAcceptanceNotes(contract: AgencyContractDto): string {
    const contractWithUtils = addUtils(contract);
    return contractWithUtils.hasAcceptanceNotes() ? contractWithUtils.getAcceptanceNotes() : null;
  }

  public backofficeCanSetAgency(contract: Contract): boolean {
    return hasLink(contract, AdminRequestOverviewLinkRel.LinkAgencyToContract);
  }

  public setAgencyForContract(contract: Contract, offer: OfferOutDto): void {
    this.setAgencyContract.emit({ contract, offer });
  }

  public backofficeCanResetContractStatus(contract: Contract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.ResetContractStatus);
  }

  public resetContractStatus(contract: Contract): void {
    this.modalService.openCustomizableConfirmModal({
      messages: [`Are you sure that you want to set this contract as "PENDING" by ${this.getStatusLabel()}?`],
      cancelText: 'NO',
      acceptText: 'YES',
      onConfirmedCallback: () => this.store.dispatch(ResetContractStatus({ contract }))
    });
  }

  private getStatusLabel(): string {
    if (this.isMroAnyQuality()) {
      return 'Quality';
    }
    if (this.isMroProduction()) {
      return 'Production';
    }
    if (this.isMroHumanResources()) {
      return 'HR';
    }
    throw Error(`${toReadable(this.effectiveRole.getRole() as UserRoles)} status cannot be reset.`);
  }
}
