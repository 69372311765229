import { EntityTypes } from '@libs/shared/models/entity-types.enum';

export interface EntityTab {
  label: string;
  entity: EntityTypes;
}

interface EntityTabsGroups {
  mro: Array<EntityTab>;
  agency: Array<EntityTab>;
  admin: Array<EntityTab>;
  technician: Array<EntityTab>;
}

export const tabsGroups: EntityTabsGroups = {
  mro: [
    {
      label: 'Users',
      entity: EntityTypes.MRO
    },
    {
      label: 'Companies',
      entity: EntityTypes.MRO_FACILITIES
    },
    {
      label: 'Groups',
      entity: EntityTypes.MRO_GROUPS
    }
  ],
  agency: [
    {
      label: 'Users',
      entity: EntityTypes.AGENCY
    },
    {
      label: 'Companies',
      entity: EntityTypes.AGENCY_FACILITIES
    },
    {
      label: 'Ordering',
      entity: EntityTypes.AGENCY_ORDERING
    }
  ],
  admin: [],
  technician: []
};
