<ng-template [ngIf]="!isAppLoading">
    <staffnow-header
        *ngIf="authorizedUserProfile"
        [notificationsCount]="bellNotificationsCount"
        [messagesCount]="mailboxNotificationsCount"
        [hasMailbox]="hasMailboxLink"
        [version]="version"
        [authorizedUser]="authorizedUserProfile"
        (toggleMenu)="toggleAsideMenu()"
        (logoutUser)="logoutUser()"
        (toggleNotifications)="toggleNotificationList($event)"
    >
        <ng-container region-selector>
            <staffnow-region-selector></staffnow-region-selector>
        </ng-container>
        <ng-container notification-list>
            <notification-list
                *ngIf="notificationListOpened"
                (toggleNotifications)="toggleNotificationList($event)"
                [notificationsCount]="bellNotificationsCount"
                closeOnOutside
                [triggerClass]="['notifications-trigger']"
                (close)="toggleNotificationList()"
            ></notification-list>
        </ng-container>
    </staffnow-header>
    <staffnow-navbar-mobile
        *ngIf="authorizedUserProfile"
        [isAsideMenuOpen]="isAsideMenuOpen"
        [menuItems]="menuItems"
    >
    </staffnow-navbar-mobile>
    <main
        [ngClass]="{
            'menu-opened': authorizedUserProfile && isAsideMenuOpen,
            'notifications-opened': authorizedUserProfile && notificationListOpened,
            'logged-in': authorizedUserProfile
        }"
    >
        <router-outlet></router-outlet>
    </main>
</ng-template>
<div
    *ngIf="isAppLoading"
    class="cover-full-height splash-screen-container"
>
    <div><b>BACKOFFICE</b> is loading</div>
    <staff-loader [customClass]="'splash-screen-loader'"></staff-loader>
</div>
<toast-list></toast-list>
