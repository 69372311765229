import { createSelector, select } from '@ngrx/store';
import {
  ManageContentStateModel,
  STATE_FEATURE_KEY
} from './manage-content-state.model';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';

const selectFeatureFromAppState = (state: any) =>
  state[STATE_FEATURE_KEY] as ManageContentStateModel;

const filterValueExists = filter(value => !!value);

export const selectTitles = createSelector(
  selectFeatureFromAppState,
  state => state.titles
);
export const selectLocations = createSelector(
  selectFeatureFromAppState,
  state => state.locations
);

export const selectIsLoading = createSelector(
  selectFeatureFromAppState,
  state => state.isLoading
);

export const getAmeTitleDocumentsOptions = pipe(
  select(
    createSelector(
      selectFeatureFromAppState,
      state => state.ameTitleDocumentsOptions
    )
  ),
  filterValueExists
);

export const getExistingOptionsForSelectedTitle = pipe(
  select(
    createSelector(
      selectFeatureFromAppState,
      state => state.existingOptionsForTitle
    )
  ),
  filterValueExists
);

export const getExistingDocuments = createSelector(
  selectFeatureFromAppState,
  state => state.documentTypes
);
