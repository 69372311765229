<div class="faq-bo-wrapper">
    <div class="action-buttons-wrapper">
        <general-button
            (onClick)="addFaqItem()"
            text="ADD NEW ENTRY"
            backgroundStyle="outline"
            class="mb-4 mr-4 add"
        ></general-button>
        <general-button
            (onClick)="submitFaqs()"
            [isDisabled]="!canSubmit"
            text="SUBMIT ALL"
        ></general-button>
    </div>

    <staffnow-faq-item
        *ngFor="let item of faqs; let i = index"
        [faqItem]="item"
        [faqIndex]="i"
        (deleteItem)="handleItemDelete($event)"
        (updateQuestionValue)="handleQuestionChange($event)"
        (updateAnswerValue)="handleAnswerChange($event)"
    >
    </staffnow-faq-item>

    <h3 class="text-center p-5" *ngIf="faqs.length === 0">
        {{ 'There are no entries added.' | translate }}
    </h3>
</div>
