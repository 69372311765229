import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgrxFormsModule } from 'ngrx-forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { TranslationModule } from '@libs/translation/translation.module';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { BackofficeRegistrationComponent } from './components/registration/backoffice-registration.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import {
  FEATURE_STATE_KEY,
  reducersInjectionToken,
  getReducers as getRegistrationReducers
} from './state/users.reducer';
import {
  UsersState,
  initialState as usersInitialState
} from './state/users-state.model';
import { UsersEffects } from './state/users.effects';
import { MroRegistrationComponent } from './components/mro-facility-registration/mro-registration.component';
import { AgencyRegistrationComponent } from './components/agency-facility-registration/agency-registration.component';
import { ManageWrapperComponent } from './components/manage-wrapper/manage-wrapper.component';
import { GroupRegistrationComponent } from './components/group-registration/group-registration.component';
import { AgencyOrderingModule } from '../agency-ordering/agency-ordering.module';
import { TableListingComponent } from './components/table-listing/table-listing.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { StaffFiltersModule } from '@libs/staff-filters/staff-filters.module';
import { UserProfileModule } from '@libs/user-profile/user-profile.module';
import { SharedModule } from '@libs/shared/shared.module';

export function getInitialState(): Partial<UsersState> {
  return {
    ...usersInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    UsersRoutingModule,
    FormsModule,
    CommonUiModule,
    TranslationModule,
    UserProfileModule,
    StoreModule.forFeature(FEATURE_STATE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    EffectsModule.forFeature([UsersEffects]),
    PaginationModule.forRoot(),
    NgrxFormsModule,
    SharedModule,
    StaffFiltersModule,
    AgencyOrderingModule
  ],
  providers: [
    { provide: reducersInjectionToken, useFactory: getRegistrationReducers }
  ],
  declarations: [
    UsersComponent,
    BackofficeRegistrationComponent,
    ManageUsersComponent,
    MroRegistrationComponent,
    AgencyRegistrationComponent,
    ManageWrapperComponent,
    GroupRegistrationComponent,
    TableListingComponent
  ],
  exports: [UsersComponent, BackofficeRegistrationComponent]
})
export class UsersModule {}
