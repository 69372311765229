<div class="report-item">
    <h1>
        {{ report.type | translate }}
    </h1>

    <div
        class="custom-controls-stacked"
        *ngIf="report.backofficeCanSelectMroGroup && report.canSelectMroFacility"
    >
        <div class="custom-control custom-radio">
            <input
                id="specific-{{ report.key }}"
                type="radio"
                name="{{ report.key }}"
                class="custom-control-input"
                [(ngModel)]="report.loadGroup"
                (ngModelChange)="resetUuid()"
                [value]="false"
            />
            <label
                for="specific-{{ report.key }}"
                class="custom-control-label"
                >{{ 'For a specific Aviation Company' | translate }}</label
            >
        </div>
        <div class="custom-control custom-radio">
            <input
                id="group-{{ report.key }}"
                type="radio"
                name="{{ report.key }}"
                class="custom-control-input"
                [(ngModel)]="report.loadGroup"
                (ngModelChange)="resetUuid()"
                [value]="true"
            />
            <label for="group-{{ report.key }}" class="custom-control-label">{{
                'For an Aviation Company Group' | translate
            }}</label>
        </div>
    </div>

    <ng-container *ngIf="!isBackofficeTab">
        <label for="custom-select">
            {{ 'Entity*' | translate }}
        </label>
        {{entity}}
        @switch (entity) {
            @case (menuItemsEnum.TECHNICIANS) {
                <technician-paginated-selector
                    [(selectedTechnicianUuid)]="report.uuid"
                    [technicianRetrieverRel]="UsersLinkRel.GetWorkingTechnicians"
                >
                </technician-paginated-selector>
            }

            @case (menuItemsEnum.MROS) {
                @if (report.loadGroup) {
                    <ng-container [ngTemplateOutlet]="selector"/>
                } @else {
                    <mro-selector [(selectedMroUuid)]="report.uuid" [loadMros]="loadMroList.bind(this)"/>
                }
            }
            @default {
                <ng-container [ngTemplateOutlet]="selector"/>
            }
        }
        <ng-template #selector>
            <simple-select
                id="custom-select"
                placeholder="Select an entity"
                [(ngModel)]="report.uuid"
                [items]="getDropdownListByEntity()"
                [getValue]="getUuid"
                [getLabel]="getLabel"
            >
            </simple-select>
        </ng-template>
    </ng-container>

    <div *ngIf="isRangeSelectAvailable(report.key)">
        <label>{{ 'Range*' | translate }}</label>
        <date-picker-range
            *ngIf="!report.isMonthly"
            [range]="getReportRange()"
            (dateUpdated)="handleDateRangeUpdate($event)"
            [placeholder]="'Pick a range' | translate"
        ></date-picker-range>
        <date-picker-single
            *ngIf="report.isMonthly"
            mode="month"
            [initialValue]="getReportDate()"
            (dateUpdated)="handleDateRangeUpdate($event)"
            [placeholder]="'Pick a range' | translate"
        ></date-picker-single>
    </div>
    <div class="generate-buttons">
        <general-button
            text="GENERATE"
            [isLoading]="isLoading"
            [isDisabled]="isSubmitDisabled()"
            (onClick)="generateReportByEntity()"
        ></general-button>
    </div>
</div>
