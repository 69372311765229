<staffnow-modal-window
    [content]="agencyFacilityRegistration"
    [autoSize]="true"
    [disableBackdrop]="true"
    #modalWindow
></staffnow-modal-window>
<ng-template #agencyFacilityRegistration>
    <div class="facility-reg-form-container">
        <h2 class="h2">
            <strong>{{ 'Agency Information' | translate }}</strong>
        </h2>
        <form
            autocomplete="off"
            novalidate
            [ngrxFormState]="agencyRegistrationForm | async"
        >
            <label>{{ 'Company Name' | translate }}</label>
            <input
                class="form-control"
                type="text"
                name="email"
                [ngrxFormControlState]="
                    (agencyRegistrationForm | async).controls.name
                "
            />
            <label>{{ 'Company Location' | translate }}</label>
            <input
                class="form-control"
                type="text"
                name="email"
                [ngrxFormControlState]="
                    (agencyRegistrationForm | async).controls.location
                "
            />
        </form>
        <div class="action-buttons">
            <general-button
                (onClick)="closeRegistrationForm()"
                backgroundStyle="outline"
                text="CANCEL"
            ></general-button>
            <general-button
                text="REGISTER"
                [isLoading]="isLoading"
                [isDisabled]="
                    !(agencyRegistrationForm | async).isValid ||
                    !(agencyRegistrationForm | async).isTouched
                "
                [customClass]="'btn-loader'"
                (onClick)="submitForm()"
            ></general-button>
        </div>
    </div>
</ng-template>
