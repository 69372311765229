import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { MENU_ITEMS_ENUM } from '../../../shared/config/menuItems.enum';
import { MRO_ROLES } from '../../../shared/config/roles';
import { RequestsOverviewActions, SetOfferType } from '@libs/request-overview-common/state/requests-overview.actions';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserRoles } from '@libs/shared/models/roles.enum';
import { isEmpty } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AGENCY_JOB_OPENING,
  FIXED_PRICE_JOB_OPENING,
  JobOpeningFilterType,
  OfferType,
  PERMANENT_JOB_OPENING,
  temporaryJobOpeningFilterGetter
} from '@libs/shared/models/offer.model';
import { getBrandName } from '@libs/shared/bms-common/environment/environment.selector';
import { Observable, Subscriber } from 'rxjs';
import { MroFacilitySimple } from '../../../../../../../staffnow-platform/src/app/state/app-state.model';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { LoadMrosList } from '../../state/admin-requests-overview.actions';
import { ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY } from '../../state/admin-requests-overview.reducer';

interface EntityList {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  uuid: string;
  userUuid: string;
  allowsPackageOffers: boolean;
  allowsPermanentJobOffers: boolean;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-overview-wrapper',
  templateUrl: './overview-wrapper.component.html',
  styleUrls: ['./overview-wrapper.component.scss']
})
export class OverviewWrapperComponent implements OnChanges {
  @Input() activeItem;
  @Input() menuItems: Array<string> = [];
  @Input() entityList: Array<EntityList> = [];
  @Input() isLoading = false;
  @Output() changeRoute: EventEmitter<string> = new EventEmitter<string>();

  menuItemsEnum = MENU_ITEMS_ENUM;
  selectedValueUuid: string = '';
  MRO_ROLES = MRO_ROLES;
  selectedMroRole = UserRoles.ROLE_MRO_PRODUCTION;
  selectedOfferType = OfferType.TEMPORARY;
  mroTypeAheadValue: string = '';
  mroDataSource: Observable<MroFacilitySimple[]>;
  private offerTypes: JobOpeningFilterType[];
  protected readonly MENU_ITEMS_ENUM = MENU_ITEMS_ENUM;

  constructor(
    private store: Store<any>,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.watchQueryParams();
    this.mroDataSource = new Observable((observer: Subscriber<string>) => {
      observer.next(this.mroTypeAheadValue);
    })
      .pipe(
        debounceTime(500),
        mergeMap((val) => {
          this.store.dispatch(new LoadMrosList(val))
          return this.store.select(state => state[ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY])
            .pipe(
              map(({ mroList }) => {
                return mroList.map((payload) => ({ name: payload.name, uuid: payload.uuid }));
              })
            );
        }));
  }

  private watchQueryParams() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => this.updateFromParams(params));
  }

  private updateFromParams(params: Params) {
    this.store.pipe(getBrandName, untilDestroyed(this)).subscribe(brandName => {
      const temporaryJobOpeningFilterType = temporaryJobOpeningFilterGetter();
      this.offerTypes = [
        temporaryJobOpeningFilterType,
        PERMANENT_JOB_OPENING,
        FIXED_PRICE_JOB_OPENING,
        AGENCY_JOB_OPENING
      ]
    });
    this.selectedValueUuid = (params.uuid as string) ?? '';
    this.selectedMroRole =
      (params.role as UserRoles) ?? UserRoles.ROLE_MRO_PRODUCTION;
    this.selectedOfferType =
      (params.offerType as OfferType) ?? OfferType.TEMPORARY;
    this.store.dispatch(
      RequestsOverviewActions.EntityUuidSet({
        uuid: this.selectedValueUuid
      })
    );
    this.triggerListLoad();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.activeItem) {
      this.updateActingAs();
    }
    if (
      Object.hasOwn(changes, 'entityList') &&
      !changes.entityList.firstChange
    ) {
      if (this.entityList && this.entityList.length > 0) {
        this.entitiesListModifier();
      }
    }
  }

  entitiesListModifier(): void {
    if (this.activeItem === MENU_ITEMS_ENUM.TECHNICIANS) {
      this.entityList.map(entity => {
        entity.name =
          entity.firstName === null && entity.lastName === null
            ? `${entity.email}`
            : `${entity.firstName} ${entity.lastName}`;
        entity.uuid = entity.userUuid;
      });
    }
  }

  handleSelectedEntityChange(uuid: string): void {
    this.mergeQueryParam({ uuid: uuid });
  }

  onBlurHandler(currentValue: string): void {
    if (currentValue === '') {
      this.store.dispatch(RequestsOverviewActions.FailedToLoadList());
    }
  }

  handleEntityNavigationChange(item: string): void {
    this.changeRoute.emit(item);
  }

  isMroSelected() {
    return this.activeItem === MENU_ITEMS_ENUM.MROS;
  }

  isAgencySelected() {
    return this.activeItem === MENU_ITEMS_ENUM.AGENCIES;
  }

  get managingRole(): UserRoles {
    switch (this.activeItem) {
      case MENU_ITEMS_ENUM.TECHNICIANS:
        return UserRoles.ROLE_TECHNICIAN;
      case MENU_ITEMS_ENUM.MROS:
        return this.selectedMroRole;
      case MENU_ITEMS_ENUM.AGENCIES:
        return UserRoles.ROLE_AGENCY;
      default:
        return null;
    }
  }

  updateOfferType(): void {
    this.store.dispatch(
      SetOfferType({
        offerType: this.selectedOfferType
      })
    );
    this.mergeQueryParam({ offerType: this.selectedOfferType });
  }

  handleMroRoleChange() {
    this.updateActingAs();
  }

  private updateActingAs() {
    this.store.dispatch(
      RequestsOverviewActions.SetActingAs({ role: this.managingRole })
    );
    this.store.dispatch(
      SetOfferType({
        offerType: this.selectedOfferType
      })
    );
    this.mergeQueryParam({
      role: this.managingRole,
      offerType: this.selectedOfferType
    });
  }

  private triggerListLoad() {
    this.store.dispatch(RequestsOverviewActions.LoadListBO());
  }

  private mergeQueryParam(queryParams: Params) {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams
    });
  }

  canOpenCreatePackageOfferPage(): boolean {
    if (this.isMroSelected() && !isEmpty(this.selectedValueUuid)) {
      const selectedMro = this.entityList.find(
        it => it.uuid === this.selectedValueUuid
      );
      return selectedMro ? selectedMro.allowsPackageOffers : false;
    }
    return false;
  }

  canOpenCreatePermanentOfferPage(): boolean {
    if (this.isMroSelected() && !isEmpty(this.selectedValueUuid)) {
      const selectedMro = this.entityList.find(
        it => it.uuid === this.selectedValueUuid
      );
      return selectedMro ? selectedMro.allowsPermanentJobOffers : false;
    }
    return false;
  }

  openCreateJobOfferPage(): void {
    this.router.navigate(['/offer', 'create', this.selectedValueUuid], {
      state: {
        preselectedFilters: {},
        isPrivate: false,
        selectedTechnicians: [],
        isEdit: false,
        breadcrumbs: [
          'Job Openings and Applications',
          'Aviation Companies',
          'Create Temporary Job Opening'
        ],
        mroUuid: this.selectedValueUuid
      }
    });
  }

  openCreatePermanentOfferPage(): void {
    this.router.navigate(
      ['/permanent-offer', 'create', this.selectedValueUuid],
      {
        state: {
          preselectedFilters: {},
          isPrivate: false,
          selectedTechnicians: [],
          isEdit: false,
          breadcrumbs: [
            'Job Openings and Applications',
            'Aviation Companies',
            'Create Permanent Job Opening'
          ],
          mroUuid: this.selectedValueUuid
        }
      }
    );
  }

  openCreatePackageOfferPage(): void {
    this.router.navigate(['/package-offer', 'create', this.selectedValueUuid], {
      state: {
        isEdit: false,
        breadcrumbs: [
          'Job Openings and Applications',
          'Aviation Companies',
          'Create Fixed-Price Job Opening'
        ],
        mroUuid: this.selectedValueUuid
      }
    });
  }

  get filteredOfferTypes(): JobOpeningFilterType[] {
    return this.offerTypes.filter(it => {
      if (this.isMroSelected()) {
        return it.value !== OfferType.AGENCY;
      } else if (this.isAgencySelected()) {
        return it.value !== OfferType.PERMANENT;
      } else {
        return true;
      }
    });
  }
}
