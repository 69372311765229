import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { AdminRequestsOverviewActionTypes, LoadAgenciesList, LoadMrosList, LoadRequestsOverviewLinks } from './state/admin-requests-overview.actions';
import { MENU_ITEMS_ENUM } from '../shared/config/menuItems.enum';
import { ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY } from './state/admin-requests-overview.reducer';
import { filter } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { Actions, ofType } from '@ngrx/effects';
import { RequestsOverviewActions } from '@libs/request-overview-common/state/requests-overview.actions';

@Component({
  selector: 'staffnow-admin-request-overview',
  templateUrl: './admin-request-overview.component.html'
})
export class AdminRequestOverviewComponent implements OnInit {
  public menuItems: Array<string> = [
    MENU_ITEMS_ENUM.TECHNICIANS,
    MENU_ITEMS_ENUM.MROS,
    MENU_ITEMS_ENUM.AGENCIES
  ];

  private menuItemsAsUrlPaths: any = {};

  public activeItem: string = this.menuItems[0];
  public entityList: Array<any> = [];
  public isLoading: boolean = false;
  public facility: string = '';

  private subs: Array<Subscription> = [];

  constructor(
    private store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    private actions: Actions
  ) {
    this.constructMenuItemsAsUrlPaths();
    this.clearState();
    this.subs.push(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(navEnd => {
          this.isLoading = true;
          this.activeItem = this.menuItems.find(item =>
            navEnd['urlAfterRedirects'].includes(this.menuItemsAsUrlPaths[item])
          );
          this.fetchEntityList();
        })
    );

    this.subs.push(
      this.actions
        .pipe(
          filter(
            action =>
              action.type ===
              AdminRequestsOverviewActionTypes.RequestOverviewLinksLoaded
          )
        )
        .subscribe(() => {
          this.loadingTimeout();
          this.fetchEntityList();
        })
    );

    this.subs.push(
      this.actions
        .pipe(
          filter(
            action =>
              action.type === AdminRequestsOverviewActionTypes.FailedToLoadList
          )
        )
        .subscribe(() => this.loadingTimeout())
    );

    this.subs.push(
      this.actions
        .pipe(ofType(RequestsOverviewActions.ListLoaded))
        .subscribe(() => this.loadingTimeout())
    );

    this.subs.push(
      this.actions
        .pipe(ofType(RequestsOverviewActions.FailedToLoadList))
        .subscribe(() => this.loadingTimeout())
    );

    this.subs.push(
      this.store
        .pipe(select(state => state[ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY]))
        .subscribe(state => {
          this.entityList = cloneDeep(state[this.loadEntityList()]);
        })
    );
  }

  private constructMenuItemsAsUrlPaths() {
    this.menuItemsAsUrlPaths[MENU_ITEMS_ENUM.TECHNICIANS] = 'technicians';
    this.menuItemsAsUrlPaths[MENU_ITEMS_ENUM.MROS] = 'aviation-companies';
    this.menuItemsAsUrlPaths[MENU_ITEMS_ENUM.AGENCIES] = 'agencies';
  }

  ngOnInit() {
    this.activeItem = this.menuItems.find(item =>
      this.router.url.includes(this.menuItemsAsUrlPaths[item])
    );

    this.store.dispatch(new LoadRequestsOverviewLinks());

    this.subs.push(
      this.route.queryParams.subscribe(params => {
        if (params.facility) {
          this.facility = params.facility;
        } else {
          this.facility = '';
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  public handleRouteChange(item: string): void {
    this.clearState();
    this.router.navigate([`./${this.menuItemsAsUrlPaths[item]}`], {
      relativeTo: this.route,
      queryParams: {}
    });
  }

  private clearState(): void {
    this.store.dispatch(RequestsOverviewActions.ResetRequestsOverviewState());
  }

  private loadEntityList(): string {
    switch (this.activeItem) {
      case MENU_ITEMS_ENUM.TECHNICIANS:
        return 'technicianList';
      case MENU_ITEMS_ENUM.MROS:
        return 'mroList';
      case MENU_ITEMS_ENUM.AGENCIES:
        return 'agencyList';
      default:
        break;
    }
  }

  private fetchEntityList(term?: string): any {
    switch (this.activeItem) {
      case MENU_ITEMS_ENUM.MROS:
        this.store.dispatch(new LoadMrosList(term));
        break;
      case MENU_ITEMS_ENUM.AGENCIES:
        this.store.dispatch(new LoadAgenciesList());
        break;
      default:
        break;
    }
  }

  private loadingTimeout(): void {
    setTimeout(() => (this.isLoading = false), 300);
  }
}
