import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'staffnow-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
