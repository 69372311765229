import { Action } from '@ngrx/store';
import { minLength, required } from 'ngrx-forms/validation';
import {
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate
} from 'ngrx-forms';

import { UsersActionTypes } from '../../state/users.actions';
import {
  AgencyRegistrationFormState,
  AgencyRegistrationStateModel,
  initialAgencyFormState
} from './agency-registration-state.model';

const agencyFormReducer = createFormStateReducerWithUpdate(
  updateGroup<AgencyRegistrationStateModel>({
    name: validate<string>(required, minLength(2)),
    location: validate<string>(required, minLength(2))
  })
);

export function agencyRegistrationFormReducer(
  state: AgencyRegistrationFormState = initialAgencyFormState,
  action: Action
): FormGroupState<AgencyRegistrationStateModel> {
  const form = agencyFormReducer(state, action);
  if (action.type === UsersActionTypes.ResetFacilityRegistrationForm) {
    return {
      ...initialAgencyFormState
    };
  }
  return form;
}
