<div class="bo-container manage-wrapper-container">
    <tab-navigation
        [menuItems]="currentTabsGroup"
        [(ngModel)]="activeTab"
        (ngModelChange)="onChangeTab()"
    ></tab-navigation>
    @if(!isAgencyOrdering) {
    <div>
        <div class="action-buttons">
            @if(userPermissions.isAbleToCreateFacilities) {
            <general-button (onClick)="openFacilityRegistration()" text="Register new company"></general-button>
            } @if(userPermissions.isAbleToCreateUsers) {
            <general-button (onClick)="openRegForm()" text="Add new user"></general-button>
            } @if(userPermissions.isAbleToCreateGroups) {
            <general-button (onClick)="openCreateGroupForm()" text="Add new group"></general-button>
            }
            <mro-facility-registration #mroRegistrationForm [mroGroupsList]="mroGroupsList"></mro-facility-registration>
            <agency-facility-registration #agencyRegistrationForm></agency-facility-registration>
            <staffnow-backoffice-registration
                #regForm
                [facilityName]="entity"
                [facilityList]="listOfFacilities"
            ></staffnow-backoffice-registration>
        </div>
        <staffnow-manage-users
            [filtersConfig]="filtersConfig"
            [isLoading]="isLoading"
            [currentPage]="currentPage"
            [nextPage]="nextPage"
            [pageSize]="pageSize"
            [totalElements]="totalElements"
            [usersList]="usersList"
            [aviationCompanies]="listOfFacilities"
            [entity]="entity"
            (pageChanged)="pageChanged($event)"
            (searchUsers)="searchUsers($event)"
            (clearFilters)="clearFilters()"
            (rowClicked)="navigateToProfile($event)"
            (deleteUser)="deleteUser($event)"
            (deleteFacility)="deleteFacility($event)"
            (sortingTrigger)="sortingTrigger($event)"
        >
        </staffnow-manage-users>
    </div>
    } @if(isAgencyOrdering) {
    <staffnow-agency-ordering></staffnow-agency-ordering>
    }
</div>
