import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLanguagesSortedByName } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { take } from 'rxjs/operators';
import { Language } from '@libs/shared/models/language.model';

@Component({
  selector: 'staffnow-language-list-bo',
  templateUrl: './languages-bo.component.html',
  styleUrls: ['./languages-bo.component.scss']
})
export class LanguagesBoComponent {
  public languages: Language[];

  constructor(private store: Store<any>) {
    this.store
      .pipe(getLanguagesSortedByName, take(1))
      .subscribe(languages => (this.languages = languages));
  }
}
