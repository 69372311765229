import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UsersFilterValueModel, UsersListingModel } from '../../state/users-state.model';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';
import { EntityTypes, HumanReadableEntities } from '@libs/shared/models/entity-types.enum';
import { Facility } from '@libs/shared/models/facility.model';
import { AviationCompany } from '../../../../../../../../libs/offer-management/shared/services/mro-facility-loader.service';

interface Pagination {
  itemsPerPage: number;
  page: number;
}

@Component({
  selector: 'staffnow-manage-users',
  templateUrl: './manage-users.component.html'
})
export class ManageUsersComponent {
  HUMAN_READABLE_ENTITIES = HumanReadableEntities;

  @Input() filtersConfig: Array<FilterConfigModel> = [];
  @Input() isLoading: boolean = false;
  @Input() currentPage: number = 0;
  @Input() nextPage: number;
  @Input() pageSize: number;
  @Input() totalElements: number;
  @Input() usersList: Array<UsersListingModel>;
  @Input() aviationCompanies: Array<AviationCompany>;
  @Input() entity: string;

  @Output() pageChanged: EventEmitter<Pagination> = new EventEmitter();
  @Output() searchUsers: EventEmitter<UsersFilterValueModel> = new EventEmitter();
  @Output() clearFilters: EventEmitter<void> = new EventEmitter();
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();
  @Output() deleteUser: EventEmitter<any> = new EventEmitter();
  @Output() deleteFacility: EventEmitter<Facility> = new EventEmitter();
  @Output() sortingTrigger: EventEmitter<any> = new EventEmitter<any>();

  get isDisplayingTechnicians(): boolean {
    return this.entity === EntityTypes.TECHNICIAN.toString();
  }

  onPageChanged($event: Pagination): void {
    this.pageChanged.emit($event);
  }

  onSearchUsers(newValues: UsersFilterValueModel): void {
    this.searchUsers.emit(newValues);
  }

  onClearFilters(): void {
    this.clearFilters.emit();
  }

  onRowClicked(user: any): void {
    this.rowClicked.emit(user);
  }

  onDeleteUser(user: any): void {
    this.deleteUser.emit(user);
  }

  onDeleteFacility(facility: Facility): void {
    this.deleteFacility.emit(facility);
  }

  onSortingTrigger(sortingQuery: string): void {
    this.sortingTrigger.emit(sortingQuery);
  }
}
