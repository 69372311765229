import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AgencyOrderingComponent } from './components/agency-ordering/agency-ordering.component';
import { AuthenticationGuardService } from '@libs/auth/services/authentication-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: AgencyOrderingComponent,
    canActivate: [AuthenticationGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgencyOrderingRouteModule {}
