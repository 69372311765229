export const ManageContentTabs = {
  aircraft: {
    label: 'Aircraft',
    path: 'aircraft'
  },
  languages: {
    label: 'Languages',
    path: 'languages'
  },
  locations: {
    label: 'Locations',
    path: 'locations'
  },
  titles: {
    label: 'Titles',
    path: 'titles'
  },
  personalDocuments: {
    label: 'Personal documents',
    path: 'personal-documents'
  },
  titleDocumentsConfig: {
    label: "Title Documents",
    path: 'title-documents-config'
  },
  slideshow: {
    label: 'Slideshow',
    path: 'slideshow'
  },
  faq: {
    label: 'FAQ',
    path: 'faq'
  }
};
