<div class="general-settings" [ngStyle]="{ padding: '20px' }">
    <div class="guidelines">
        <p>Here you can add and remove locations from the list.</p>
    </div>
    <div class="add-entity">
        <input
            type="text"
            class="form-control name"
            placeholder="Write the new location name"
            [(ngModel)]="newLocationName"
        />
        <general-button
            [isDisabled]="!newLocationName || (isLoading$ | async)"
            (onClick)="addNewLocation()"
            text="Add"
        ></general-button>
        <span class="text-danger" *ngIf="duplicatedLocation">
            The new location name already exists!
        </span>
    </div>
    <div class="horizontal-divider"></div>
    <div class="entries-wrapper">
        <h1 class="h1">Available Locations ({{ activeLocations.length }})</h1>
        <div class="guidelines">
            <p>The following are the locations that users can select when creating a job opening.</p>
        </div>
        <div class="entries">
            <div
                    class="single-entry"
                    *ngFor="let entry of activeLocations; index as index"
            >
                <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="entry.name"
                        (ngModelChange)="markAsChanged()"
                />
                <borderless-button
                    (onClick)="deleteLocation(index)"
                    actionType="danger"
                    color="dark"
                    icon="delete"
                ></borderless-button>
            </div>
        </div>
    </div>
    <div class="horizontal-divider"></div>
    <div class="entries-wrapper">
        <h1 class="h1">Disabled Locations ({{ disabledLocations.length }})</h1>
        <div class="guidelines">
            <p>The following are the locations that could not be deleted because they have been used in a job opening, but are no longer available to use.</p>
        </div>
        <div class="entries">
            <div
                    class="single-entry"
                    *ngFor="let entry of disabledLocations"
            >
            <div class="form-control">
                {{ entry.name }}
            </div>
            </div>
        </div>
    </div>
    <general-button
        text="Save"
        class="save-button"
        [isLoading]="(isLoading$ | async)"
        [isDisabled]="!isChangeMade"
        [customClass]="'btn-loader'"
        (onClick)="saveChanges()"
    ></general-button>
</div>
