<div class="offer-info-container" *ngIf="!isLoading && contract">
    <div class="basic-info">
        <div>
            <label for="overview">{{ 'Overview:' | translate }}</label>
            <p id="overview">{{ offer?.shortPresentation }}</p>
        </div>

        <div>
            <label for="experience">{{ 'Min Experience:' | translate }}</label>
            <span id="experience">
                {{ offer?.minExperience }}+
                <small class="text-muted">{{ '(year/s)' }}</small></span
            >
        </div>

        <div>
            <label for="shifts">{{ 'Shifts:' | translate }}</label>
            <span id="shifts">
                {{ offer?.workDaysOn }} on - {{ offer?.workDaysOff }} off</span
            >
        </div>

        <div>
            <label for="start-date">{{ 'Start date:' | translate }}</label>
            <span id="start-date">
                {{ contract?.startDate | absoluteDateFormat }}</span
            >
        </div>

        <div>
            <label for="end-date">{{ 'End date:' | translate }}</label>
            <span id="end-date">
                {{ contract?.endDate | absoluteDateFormat }}</span
            >
        </div>
    </div>
    <div *ngIf="isLoading || !contract"></div>
    <div class="contract-status" *ngIf="!isLoading && contract">
        <contract-status
            [isAgencyOfferContract]="true"
            [contract]="contract"
            (openPublicProfile)="onOpenPublicProfile($event, contract)"
        >
        </contract-status>
    </div>
    <staffnow-agency-offer-contract-reasons-list
        *ngIf="hasReasons"
        [contract]="contract"
    ></staffnow-agency-offer-contract-reasons-list>
    <div class="action-buttons">
        <general-button
            (onClick)="acceptContract()"
            *ngIf="!hasTechnicianAcceptedAlready"
            [isDisabled]="isOfferEndedOrClosed"
            size="small"
            text="ACCEPT"
        ></general-button>
        <general-button
            (onClick)="declineContract()"
            *ngIf="!hasTechnicianDeclinedAlready"
            [isDisabled]="isOfferEndedOrClosed"
            backgroundStyle="outline"
            color="danger"
            size="small"
            text="DECLINE"
            title="{{'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate}}"
        ></general-button>
    </div>
</div>
