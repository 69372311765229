import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';
import { FilterTypes } from '@libs/staff-filters/constants/FilterTypes';

export interface ManagementFilters {
  mro: FilterConfigModel[];
  admin: FilterConfigModel[];
  agency: FilterConfigModel[];
  technician: FilterConfigModel[];
  agencyFacilities: FilterConfigModel[];
  mroFacilities: FilterConfigModel[];
  mroGroups: FilterConfigModel[];
}

export const filtersConfig: ManagementFilters = {
  admin: [
    {
      type: FilterTypes.Text,
      key: 'term',
      className: 'term-filter',
      label: 'Search',
      placeholder: 'Type email, first name or last name'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'regDate',
      className: 'date-filter'
    },
    {
      type: FilterTypes.Enum,
      label: 'Role',
      placeholder: 'Select a role',
      key: 'role',
      className: 'term-filter',
      multiple: false,
      labelForId: 'name',
      items: [
        {
          name: 'ADMIN'
        },
        {
          name: 'MODERATOR'
        }
      ],
      isIdText: true,
      initialValue: ''
    }
  ],
  mro: [
    {
      type: FilterTypes.Text,
      key: 'term',
      className: 'term-filter',
      label: 'Search',
      placeholder: 'Type email, first name or last name'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'regDate',
      className: 'date-filter'
    },
    {
      label: 'Aviation Companies',
      placeholder: 'Select an Aviation Company',
      key: 'mroFacility',
      name: 'mro',
      className: 'ame-type-filter',
      type: FilterTypes.Enum,
      multiple: false,
      labelForId: 'id',
      items: [],
      lazy: true,
      isIdText: true,
      initialValue: ''
    },
    {
      type: FilterTypes.Text,
      label: 'Position',
      placeholder: 'Type a position',
      key: 'position',
      className: 'term-filter'
    }
  ],
  agency: [
    {
      type: FilterTypes.Text,
      key: 'term',
      className: 'term-filter',
      label: 'Search',
      placeholder: 'Type email, first name or last name'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'regDate',
      className: 'date-filter'
    },
    {
      label: 'Agencies',
      placeholder: 'Select an agency',
      key: 'agencyFacility',
      name: 'agency',
      className: 'ame-type-filter',
      type: FilterTypes.Enum,
      multiple: false,
      labelForId: 'id',
      items: [],
      isIdText: true,
      initialValue: ''
    },
    {
      type: FilterTypes.Text,
      label: 'Position',
      placeholder: 'Type a position',
      key: 'position',
      className: 'term-filter'
    }
  ],
  technician: [
    {
      type: FilterTypes.Text,
      key: 'term',
      className: 'term-filter',
      label: 'Search',
      placeholder: 'Type email, ref. number, first name or last name'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'regDate',
      className: 'date-filter'
    },
    {
      label: 'Account Status',
      placeholder: 'Select a status',
      key: 'accountStatus',
      className: 'user-status-filter',
      type: FilterTypes.Enum,
      multiple: false,
      labelForId: 'id',
      bindLabel: 'name',
      isIdText: true,
      items: [],
      initialValue: ''
    },
    {
      label: 'Profile Status',
      placeholder: 'Select a status',
      key: 'profileStatus',
      className: 'user-status-filter',
      type: FilterTypes.Enum,
      multiple: false,
      labelForId: 'id',
      bindLabel: 'name',
      isIdText: true,
      items: [],
      initialValue: ''
    },
    {
      type: FilterTypes.BreakLine
    },
    {
      type: FilterTypes.AmeTypeLicense,
      key: 'ameTypeLicense',
      className: 'ameTypeLicense-filter',
      initialValue: {}
    },
    {
      label: 'Aircraft type',
      placeholder: 'Select a type',
      key: 'aircraft',
      className: 'aircrafts-filter',
      type: FilterTypes.Enum,
      multiple: true,
      labelForId: 'id',
      bindLabel: 'name',
      items: []
    }
  ],
  mroFacilities: [
    {
      type: FilterTypes.Text,
      key: 'name',
      className: 'term-filter facility ',
      label: 'Search',
      placeholder: 'Type company name'
    },
    {
      type: FilterTypes.Enum,
      key: 'group',
      className: 'group-filter',
      label: 'Group',
      name: 'mroFacilities',
      placeholder: 'Select an Aviation Company Group'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'createdOn',
      className: 'date-filter'
    }
  ],
  agencyFacilities: [
    {
      type: FilterTypes.Text,
      key: 'name',
      className: 'term-filter facility ',
      label: 'Search',
      placeholder: 'Type company name'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'createdOn',
      className: 'date-filter'
    }
  ],
  mroGroups: [
    {
      type: FilterTypes.Text,
      key: 'name',
      className: 'term-filter facility ',
      label: 'Search',
      placeholder: 'Type Aviation Company group name'
    },
    {
      label: 'Registration date',
      placeholder: 'Pick a date from the calendar',
      type: FilterTypes.Date,
      key: 'createdOn',
      className: 'date-filter'
    }
  ]
};
