import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AcceptContract,
  AcceptNewPriceForPackageContract,
  AddTechniciansToPackageContract,
  AgencyAcceptsPackageContractInvitation,
  AgencyEditsPackageContract,
  TechniciansForPackageContractLoaded,
  DeclineContract,
  LoadTechniciansForPackageContract,
  LoadPackageOfferWithContracts,
  ProposeNewPriceForPackageContract,
  RemoveTechnicianFromPackageContract
} from '@libs/request-overview-common/state/requests-overview.actions';
import { selectSelectedOfferContracts } from '@libs/request-overview-common/state/requests-overview.selectors';
import { cloneDeep, isEmpty } from 'lodash-es';
import { OperatorFunction } from 'rxjs';
import { PackageContract } from '@libs/shared/models/package-contract.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  getEmbeddedResource,
  getLink,
  hasEmbeddedResource,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { PackageOfferDto } from '@libs/shared/models/offer.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { SelectEntitiesFromListModalComponent } from '@libs/common-ui/select-entities-from-list-modal/select-entities-from-list-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Resource } from '@libs/shared/bms-common/rest/resource.model';
import { CustomNavigationService } from '@libs/shared/services/custom-navigation.service';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { RoleWrapper } from '@libs/shared/models/roles.enum';
import { PackageContractRejectionComponent } from '@libs/common-ui/package-contract-rejection/package-contract-rejection.component';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { DownloadAgencyApprovals } from '@libs/common-ui/facility-profile/facility-profile.actions';
import { StaffnowDocument } from '@libs/user-profile/model/documents.model';
import { FileListDialogComponent } from '@libs/common-ui/file-upload/file-list-dialog/file-list-dialog.component';
import { PackageContractLinkRel } from '@libs/shared/linkrels/package-contract.linkrel';

@UntilDestroy()
@Component({
  selector: 'staffnow-package-contract-list-expand',
  templateUrl: './package-contract-list-expand.component.html',
  styleUrls: ['./package-contract-list-expand.component.scss']
})
export class PackageContractListExpandComponent implements OnChanges {
  @Input() public offer: PackageOfferDto = null;
  @Input() public isLoading: boolean = true;
  @Input() public effectiveRole: RoleWrapper = null;
  public contractsList: PackageContract[] = [];
  private agencyUuid: string = null;

  public agencyApprovals: StaffnowDocument[] = [];

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private customNavigationService: CustomNavigationService
  ) {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => {
        this.agencyUuid = params.uuid;
      });
    this.storeSubscribe(
      select(selectSelectedOfferContracts),
      selectedOfferContracts => {
        if (selectedOfferContracts) {
          this.contractsList = cloneDeep(selectedOfferContracts.contracts);
        }
      }
    );
    this.onAction(TechniciansForPackageContractLoaded, action =>
      this.openAddTechnicianModal(action.technicians, action.contract)
    );
  }

  private storeSubscribe<T, S>(
    pipedSelector: OperatorFunction<T, S>,
    subscribeFn: (a: S) => void
  ) {
    this.store.pipe(untilDestroyed(this), pipedSelector).subscribe(subscribeFn);
  }

  private onAction(...args: any[]) {
    const subscribeFn = args.pop();
    this.actions
      .pipe(untilDestroyed(this), ofType(...args))
      .subscribe(subscribeFn);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offer) {
      this.store.dispatch(
        LoadPackageOfferWithContracts({ refNumber: this.offer.refNumber })
      );
    }
  }

  public canAccept(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.AcceptContract);
  }

  public canEdit(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.Edit);
  }

  public canDecline(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.DeclineContract);
  }

  public canAddAgencyTechnicians(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.AddTechnicians);
  }

  public canRemoveTechnician(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.RemoveTechnician);
  }

  private isAgencyPending(contract: PackageContract): boolean {
    return this.canAccept(contract) && isEmpty(contract.agencyOfferedPrices);
  }

  public acceptContract(contract: PackageContract): void {
    if (this.effectiveRole.isAgency() && this.isAgencyPending(contract)) {
      this.modalService.openCreatePackageContractForm(this.offer, payload =>
        this.store.dispatch(
          AgencyAcceptsPackageContractInvitation({
            contract,
            payload,
            agencyUuid: this.agencyUuid
          })
        )
      );
    } else {
      this.store.dispatch(AcceptContract({ contract }));
    }
  }

  public declineContract(contract: PackageContract): void {
    if (this.getWorkers(contract).length > 0) {
      this.bsModalService.show(PackageContractRejectionComponent, {
        ignoreBackdropClick: true,
        initialState: {
          onRejectCallback: reason =>
            this.declineContractInternal(contract, reason)
        }
      });
    } else {
      this.declineContractInternal(contract);
    }
  }

  private declineContractInternal(contract: PackageContract, reason?: string) {
    this.store.dispatch(
      DeclineContract({
        contract,
        reasons: [reason]
      })
    );
  }

  public addAgencyTechnicians(contract: PackageContract): void {
    this.store.dispatch(
      LoadTechniciansForPackageContract({
        agencyUuid: this.agencyUuid,
        contract
      })
    );
  }

  private openAddTechnicianModal(
    technicians: Array<{ name: string; uuid: string }>,
    contract: PackageContract
  ) {
    this.bsModalService.show(SelectEntitiesFromListModalComponent, {
      ignoreBackdropClick: true,
      class: 'modal-center',
      initialState: {
        placeholder: 'Select technicians',
        entityList: technicians.map(elem => {
          return {
            ...elem,
            fullName: elem.name
          };
        }),
        onConfirmCallback: selectedTechnicians => {
          this.store.dispatch(
            AddTechniciansToPackageContract({
              contract,
              agencyUuid: this.agencyUuid,
              technicians: selectedTechnicians.map(c => c.uuid)
            })
          );
        }
      }
    });
  }

  public openSeeAgencyApprovalsModal(contract: PackageContract) {
    if (
      hasEmbeddedResource(
        contract,
        FacilityProfileLinkRel.AgencyEmbeddedAgencyApprovals
      )
    ) {
      this.agencyApprovals = getEmbeddedResource(
        contract,
        FacilityProfileLinkRel.AgencyEmbeddedAgencyApprovals
      );
    } else {
      this.agencyApprovals = [];
    }
    this.bsModalService.show(FileListDialogComponent, {
      class: 'modal-center modal-big',
      initialState: {
        onDownloadCallback: $event => this.downloadAgencyApprovals($event),
        documents: this.agencyApprovals,
        showLabel: true,
        title: 'Agency Approvals'
      }
    });
  }

  downloadAgencyApprovals($event: any) {
    this.store.dispatch(DownloadAgencyApprovals({ document: $event }));
  }

  public getWorkers(contract: PackageContract): Array<Resource> {
    return getEmbeddedResource(contract, RequestOverviewLinkRel.Workers);
  }

  public openTechnicianProfile(uuid: string): void {
    this.customNavigationService.openNewTab(['technicians', 'profile', uuid]);
  }

  public removeTechnician(contract: PackageContract, worker: Resource): void {
    const profile = getEmbeddedResource<any>(
      worker,
      UserProfileLinkRel.Profile
    );
    this.modalService.openConfirmModal(
      `Are you sure you want to remove technician ${profile.firstName} ${profile.lastName}?`,
      () =>
        this.store.dispatch(
          RemoveTechnicianFromPackageContract({
            technician: profile.userUuid,
            agencyUuid: this.agencyUuid,
            contract
          })
        )
    );
  }

  public canProposePrice(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.ProposePrice);
  }

  public canAcceptPrice(contract: PackageContract): boolean {
    return hasLink(contract, RequestOverviewLinkRel.AcceptPrice);
  }

  public proposePrice(contract: PackageContract): void {
    this.modalService.openNumberPickerDialog(
      'Counteroffer',
      'Input the new price: ',
      number =>
        this.store.dispatch(
          ProposeNewPriceForPackageContract({
            newPrice: number,
            contract
          })
        )
    );
  }

  public acceptPrice(contract: PackageContract): void {
    const isAgency = this.effectiveRole.isAgency();
    const [entity, price] = [
      isAgency ? 'Aviation Company' : 'Agency',
      isAgency
        ? contract.mroOfferedPrice
        : cloneDeep(contract.agencyOfferedPrices).pop()
    ];
    this.modalService.openConfirmModal(
      `Are you sure you want to accept the ${entity} price (${price.currencySymbol} ${price.amount})?`,
      () => this.store.dispatch(AcceptNewPriceForPackageContract({ contract }))
    );
  }

  public editContract(contract: PackageContract): void {
    this.modalService.openCreatePackageContractForm(
      this.offer,
      payload =>
        this.store.dispatch(
          AgencyEditsPackageContract({
            contract,
            payload,
            agencyUuid: this.agencyUuid
          })
        ),
      contract
    );
  }

  public canCreateAgencyOffer(contract: PackageContract): boolean {
    return hasLink(contract, PackageContractLinkRel.CreateAgencyOffer);
  }

  public openCreateAgencyOfferPage(contract: PackageContract): void {
    this.router.navigate(['/agency-offer', contract.id], {
      state: {
        preselectedFilters: {},
        location: this.offer.location,
        isEdit: false,
        startDate: contract.startDate,
        endDate: contract.endDate,
        breadcrumbs: [
          'Job Openings and Applications',
          'Agencies',
          'Create Agency Job Opening'
        ],
        mroUuid: contract.mroUuid,
        packageContractId: contract.id,
        createUrl: getLink(contract, PackageContractLinkRel.CreateAgencyOffer)
      }
    });
  }
}
