import {Action, createAction, props} from '@ngrx/store';
import {BulletPointItem, BulletPointItemList} from '@libs/shared/models/bullet-point.model';
import {FullTitleDto, FullTitleDtoReadOnly} from './full-title-dto.model';
import {AmeTitleDocumentsOptions, DocumentTypeOption, ExtendedDocumentType} from './title-documents.model';
import {LocationPO} from "@libs/shared/models/location.model";

export enum ManageContentActionTypes {
  SaveGeneralSettings = '[Manage Content] Save General Settings',
  UpdateAircraftList = '[Manage Content] Update aircraft list',
  FailedToUpdateAircraftList = '[Manage Content] Failed to update aircraft list',
  GetSlideshowPictureList = '[Manage Content] Get slideshow picture list',
  SlideshowPictureListLoaded = '[Manage Content] Slideshow picture list loaded',
  FailedToGetSlideshowPictureList = '[Manage Content] Failed to get slideshow picture list',
  InitiatePictureDelete = '[Manage Content] Initiate slideshow picture delete',
  SuccessfulPictureDelete = '[Manage Content] Slideshow picture successfully deleted',
  FailedToDeletePicture = '[Manage Content] Failed to delete slideshow picture',
  GetFaqsList = '[Manage Content] Get list of faqs',
  FaqsListLoaded = '[Manage Content] List of faqs loaded',
  FailedToGetFaqs = '[Manage Content] Failed to get list of faqs',
  SubmitFaqsList = '[Manage Content] Submit new faqs list',
  SuccessfulSubmittedFaqsList = '[Manage Content] New FAQs list submitted successfully',
  FailedToSubmitFaqs = '[Manage Content] Failed to submit new faqs list'
}

export class GetFaqsList implements Action {
  public readonly type = ManageContentActionTypes.GetFaqsList;
}

export class FaqsListLoaded implements Action {
  public readonly type = ManageContentActionTypes.FaqsListLoaded;

  constructor(public faqsList: BulletPointItem[]) {
    this.faqsList = faqsList;
  }
}

export class FailedToGetFaqs implements Action {
  public readonly type = ManageContentActionTypes.FailedToGetFaqs;
}

export class SubmitFaqsList implements Action {
  public readonly type = ManageContentActionTypes.SubmitFaqsList;

  constructor(public faqsList: BulletPointItemList) {
    this.faqsList = faqsList;
  }
}

export class SuccessfulSubmittedFaqsList implements Action {
  public readonly type = ManageContentActionTypes.SuccessfulSubmittedFaqsList;
}

export class FailedToSubmitFaqs implements Action {
  public readonly type = ManageContentActionTypes.FailedToSubmitFaqs;
}

export class FailedToUpdateAircraftList implements Action {
  public readonly type = ManageContentActionTypes.FailedToUpdateAircraftList;
}

export class FailedToGetSlideshowPictureList implements Action {
  public readonly type =
    ManageContentActionTypes.FailedToGetSlideshowPictureList;
}

export class SaveGeneralSettings implements Action {
  public readonly type: string = ManageContentActionTypes.SaveGeneralSettings;

  constructor(public readonly aircraftList: Array<any>) {
  }
}

export class GetSlideshowPictureList implements Action {
  public readonly type: string =
    ManageContentActionTypes.GetSlideshowPictureList;

  constructor(public readonly url: string) {
  }
}

export class SlideshowPictureListLoaded implements Action {
  public readonly type: string =
    ManageContentActionTypes.SlideshowPictureListLoaded;
  public pictureList: any[] = [];

  constructor(public picturesList: any[]) {
    this.picturesList = picturesList;
  }
}

export class InitiatePictureDelete implements Action {
  public readonly type = ManageContentActionTypes.InitiatePictureDelete;

  constructor(public picture: any) {
    this.picture = picture;
  }
}

export class SuccessfulPictureDelete implements Action {
  public readonly type = ManageContentActionTypes.SuccessfulPictureDelete;

  constructor(public pictureId: number) {
    this.pictureId = pictureId;
  }
}

export class FailedToDeletePicture implements Action {
  public readonly type = ManageContentActionTypes.FailedToDeletePicture;
}

export class UpdateAircraftList implements Action {
  public readonly type: string = ManageContentActionTypes.UpdateAircraftList;

  constructor(public readonly aircraftList: Array<any>) {
  }
}

export const LoadTitles = createAction('[Manage Content] Load list of titles');

export const SuccessfulLoadTitles = createAction(
  '[Manage Content] Successful load list of titles',
  props<{ titles: FullTitleDtoReadOnly[] }>()
);

export const FailedLoadTitles = createAction(
  '[Manage Content] Failed load list of titles'
);

export const SubmitTitles = createAction(
  '[Manage Content] Submit new titles',
  props<{ titles: FullTitleDto[] }>()
);

export const FailedSubmitTitles = createAction(
  '[Manage Content] Failed submit new titles'
);

export const GetAmeTitleDocumentsOptions = createAction(
  '[Manage Content] Get ameTitle documents options'
);

export const AmeTitleDocumentsOptionsLoaded = createAction(
  '[Manage Content] AmeTitle documents options loaded',
  props<{ ameTitleDocumentsOptions: AmeTitleDocumentsOptions }>()
);

export const AmeTitleDocumentsOptionsFailed = createAction(
  '[Manage Content] AmeTitle documents options failed'
);

export const GetExistingOptionsForTitle = createAction(
  '[Manage Content] Get existing options for title',
  props<{ ameTitleId: number }>()
);

export const ExistingOptionsLoaded = createAction(
  '[Manage Content] Existing options for title loaded',
  props<{ existingOptionsForTitle: DocumentTypeOption[] }>()
);

export const UpdateDocumentsForTitle = createAction(
  '[Manage Content] Update Documents for title',
  props<{
    ameTitleId: number;
    payload: ExtendedDocumentType[];
  }>()
);

export const FailedToUpdateDocumentsForTitle = createAction(
  '[Manage Content] Failed to update documents for title'
);

export const SuccessfulUpdateDocumentsForTitle = createAction(
  '[Manage Content] Successful update documents for title'
);

export const LoadAvailableLocations = createAction('[Manage Content] LoadAvailableLocations');
export const SuccessfulLoadAvailableLocations = createAction(
  '[Manage Content] SuccessfulLoadAvailableLocations',
  props<{ locations: LocationPO[] }>()
);
export const FailedToLoadAvailableLocations = createAction('[Manage Content] FailedToLoadAvailableLocations');
export const UpdateAvailableLocations = createAction(
  '[Manage Content] UpdateAvailableLocations',
  props<{ locations: LocationPO[] }>()
);

export const GetDocumentTypesByDocumentAreaId = createAction(
  '[Manage Content] Get documents types by document area id',
  props<{ documentAreaId: number }>()
);

export const SuccessfulLoadDocumentTypes = createAction(
  '[Manage Content] SuccessfulLoadDocumentTypes',
  props<{ documentTypes: ExtendedDocumentType[] }>()
);

export const FailedToLoadDocumentTypes = createAction('[Manage Content] FailedToLoadDocumentsTypes');

export const UpdateDocumentTypes = createAction(
  '[Manage Content] Update Documents types',
  props<{
    payload: ExtendedDocumentType[];
  }>()
);

export const SuccessfulUpdateDocumentTypes = createAction(
  '[Manage Content] Successful update document types'
);

export const FailedToUpdateDocumentTypes = createAction(
  '[Manage Content] Failed to update document types'
);
