import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoadTitles, SubmitTitles} from '../../state/manage-content.actions';
import {selectIsLoading, selectTitles} from '../../state/manage-content.selectors';
import {FullTitleDto, FullTitleDtoReadOnly} from '../../state/full-title-dto.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {isNil, lowerCase} from 'lodash-es';
import {Observable} from 'rxjs';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {isBlank} from '@libs/shared/helpers/help-functions';

function arrayHasDuplicates(array): boolean {
  return new Set(array).size !== array.length;
}

function mapToIds(array: FullTitleDto[]): string[] {
  return array.map(title => lowerCase(title.type.trim() + (!isNil(title.license) ? '-' + title.license.trim() : '')));
}

@UntilDestroy()
@Component({
  selector: 'staffnow-title-management',
  templateUrl: './title-management.component.html',
  styleUrls: ['./title-management.component.scss']
})
export class TitleManagementComponent {
  public availableTitles: FullTitleDtoReadOnly[] = [];
  public titlesToAdd: FullTitleDto[] = [];
  public isLoading$: Observable<boolean>;
  public errorMessages: string[] = [];

  private titleStringIds: string[] = [];
  private existingSingletonTypes: string[] = [];
  private existingNonSingletonTypes: string[] = [];

  constructor(private store: Store<any>, private modalService: ModalService) {
    this.isLoading$ = this.store.select(selectIsLoading);
    this.store
      .select(selectTitles)
      .pipe(untilDestroyed(this))
      .subscribe(titles => {
        this.availableTitles = titles;
        this.titleStringIds = mapToIds(this.availableTitles);
        this.existingSingletonTypes = this.availableTitles
          .filter(title => title.isSingletonType)
          .map(title => lowerCase(title.type.trim()));
        this.existingNonSingletonTypes = this.availableTitles
          .filter(title => !title.isSingletonType)
          .map(title => lowerCase(title.type.trim()));
        this.titlesToAdd = [];
      });
    this.store.dispatch(LoadTitles());
  }

  public addNewTitle(): void {
    this.titlesToAdd.push({
      id: null,
      type: null,
      license: null,
      isSingletonType: true
    });
  }

  public saveNewTitles(): void {
    this.modalService.openConfirmModal(
      'Are you sure you want to add the title(s)? Please, double check for spelling mistakes.',
      () =>
        this.store.dispatch(
          SubmitTitles({
            titles: this.titlesToAdd.map(title => ({
              ...title,
              type: title.type.trim(),
              license: title.license?.trim()
            }))
          })
        )
    );
  }

  public removeTitle(index: number): void {
    this.titlesToAdd.splice(index, 1);
  }

  public isFormValid(): boolean {
    this.errorMessages = [];
    return (
      this.titlesToAdd.length > 0 &&
      !this.someTitleIsMissingTypeOrLicense() &&
      !this.hasDuplicates() &&
      !this.someSingletonTypeHasError()
    );
  }

  private hasDuplicates(): boolean {
    const mappedLicenses = mapToIds(this.titlesToAdd);
    if (
      arrayHasDuplicates(mappedLicenses) ||
      this.titleStringIds.some(titleId => mappedLicenses.indexOf(titleId) >= 0)
    ) {
      this.errorMessages.push('One ore more titles are duplicated.');
      return true;
    }
    return false;
  }

  private someTitleIsMissingTypeOrLicense(): boolean {
    if (this.titlesToAdd.some(title => isBlank(title.type))) {
      this.errorMessages.push('One or more titles are missing the technician type.');
      return true;
    }
    if (this.titlesToAdd.some(title => (!title.isSingletonType && isBlank(title.license)))) {
      this.errorMessages.push('One or more titles are missing the license name.');
      return true;
    }
    return false;
  }

  private someSingletonTypeHasError(): boolean {
    const singleton = this.getSingletonTypes();
    if (singleton.some(newType => this.existingNonSingletonTypes.some(type => type === newType))) {
      this.errorMessages.push('A title must have either one registry with no license or multiple registries with licenses.');
      return true;
    }
    const nonSingleton = this.getNonSingletonTypes();
    if (nonSingleton.some(newType => this.existingSingletonTypes.some(type => type === newType))) {
      this.errorMessages.push('A title must have either one registry with no license or multiple registries with licenses.');
      return true;
    }
    if (singleton.some(newTitle1 => nonSingleton.some(newType2 => newType2 === newTitle1))
    ) {
      this.errorMessages.push('A title must have either one registry with no license or multiple registries with licenses.');
      return true;
    }
    return false;
  }

  private getSingletonTypes() {
    return this.titlesToAdd
      .filter(title => title.isSingletonType)
      .map(title => lowerCase(title.type.trim()));
  }

  private getNonSingletonTypes() {
    return this.titlesToAdd
      .filter(title => !title.isSingletonType)
      .map(title => lowerCase(title.type.trim()));
  }
}
