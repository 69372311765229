import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'group-registration',
  templateUrl: './group-registration.component.html'
})
export class GroupRegistrationComponent {
  public form: UntypedFormGroup = null;
  public isEdit: boolean = false;

  @Input() public onCreateCallback: Function;
  @Input() public initialState: any = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private bsModalRef: BsModalRef
  ) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      isThreeMonthRuleAccepted: [false]
    });
  }

  ngOnInit() {
    if (this.initialState && this.initialState.group) {
      this.isEdit = true;
      this.form.patchValue(this.initialState.group);
    }
  }

  public createGroup(): void {
    this.onCreateCallback(this.form.value);
    this.close();
  }

  public close(): void {
    this.bsModalRef.hide();
  }
}
