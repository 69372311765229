<div class="bo-container">
    <div class="agency-ordering-container">
        <div class="list-container agencies-list" *ngIf="agenciesList">
            <h1>{{ 'List of not featured agencies' | translate }}</h1>

            <div
                cdkDropList
                class="list"
                id="agencies-list"
                cdkDropListConnectedTo="ordered-agencies-list"
                [cdkDropListData]="agenciesList"
                (cdkDropListDropped)="drop($event)"
            >
                <div class="empty-list" *ngIf="agenciesList.length === 0">
                    <i class="material-icons"> swap_horiz </i>
                    <p>
                        {{
                            "Drag'n'Drop items from list of featured agencies."
                                | translate
                        }}
                    </p>
                </div>
                <div
                    class="list-item"
                    *ngFor="let agency of agenciesList"
                    cdkDrag
                >
                    {{ agency.name }}
                </div>
            </div>
        </div>
        <div class="list-container featured-list">
            <h1>{{ 'List of featured agencies' | translate }}</h1>

            <div
                cdkDropList
                class="list"
                id="ordered-agencies-list"
                cdkDropListConnectedTo="agencies-list"
                [cdkDropListData]="orderedAgenciesList"
                (cdkDropListDropped)="drop($event)"
            >
                <div
                    class="empty-list"
                    *ngIf="orderedAgenciesList.length === 0"
                >
                    <i class="material-icons"> swap_horiz </i>
                    <p>
                        {{
                            "Drag'n'Drop items from list of not featured agencies."
                                | translate
                        }}
                    </p>
                </div>
                <div
                    class="list-item"
                    *ngFor="let agency of orderedAgenciesList"
                    cdkDrag
                >
                    {{ agency.name }}
                </div>
            </div>
        </div>
    </div>
    <div class="action">
        <general-button
            text="Submit"
            [isLoading]="isLoading"
            [isDisabled]="!isChanged"
            [customClass]="'btn-loader'"
            (onClick)="submitFeaturedAgencies()"
        ></general-button>
    </div>
</div>
