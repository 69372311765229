<div class="contracts-list-container">
    <div *ngIf="isLoading" class="no-applicants">
        <staff-loader
            *ngIf="isLoading"
            [customClass]="'splash-screen-loader'"
        ></staff-loader>
    </div>
    <div *ngIf="!isLoading && contractsList.length > 0" class="col-titles">
        <span class="col-name">{{ 'Name' | translate }}</span>
        <span class="col-name">{{ 'Start Date' | translate }}</span>
        <span class="col-name">{{ 'End Date' | translate }}</span>
        <span class="col-name">{{ 'Contract status' | translate }}</span>
        <span class="col-name">
            {{ 'Reject/Pending Reasons' | translate }}
        </span>
        <span class="col-actions">{{ 'Actions' | translate }}</span>
    </div>
    <div *ngIf="!isLoading && contractsList.length > 0" class="applicants-list">
        <div
            *ngFor="let contract of contractsList; let i = index"
            class="position-relative"
        >
            <div
                [ngClass]="{
                    expired: contract.isExpired,
                    finished: contract.isFinished
                }"
                class="single-contract-row"
            >
                <!-- TECHNICIAN COLUMN -->
                <div class="technician-info">
                    <div
                        *ngIf="contract?._links?.profilePicture"
                        [ngStyle]="{
                            'background-image':
                                'url(\'' +
                                contract?._links?.profilePicture.href +
                                '\')'
                        }"
                        class="profile-pic"
                    ></div>
                    <div
                        *ngIf="!contract?._links?.profilePicture"
                        class="profile-pic"
                    >
                        <i class="material-icons"> person </i>
                    </div>
                    <span class="h6">
                        {{ contract?._embedded?.profile?.firstName }}
                        {{ contract?._embedded?.profile?.lastName }}
                    </span>
                </div>
                <!-- START DATE COLUMN -->
                <span class="start-date">
                    {{ contract.startDate | absoluteDateFormat }}
                </span>
                <!-- END DATE COLUMN -->
                <span class="end-date">
                    {{ contract.endDate | absoluteDateFormat }}
                </span>
                <!-- CONTRACT STATUS COLUMN -->
                <contract-status
                    [isAgencyOfferContract]="true"
                    (openPublicProfile)="onOpenPublicProfile($event, contract)"
                    [contract]="contract"
                >
                </contract-status>
                <!-- REASONS COLUMN -->
                <staffnow-agency-offer-contract-reasons-list
                    *ngIf="hasReasons"
                    [contract]="contract"
                ></staffnow-agency-offer-contract-reasons-list>

                <!-- ACTIONS COLUMN -->
                <div class="action-buttons" *ngIf="!contract.isFinished">
                    <general-button
                        *ngIf="!hasAgencyAcceptedAlready(contract)"
                        [isDisabled]="isOfferEndedOrClosed(contract)"
                        (onClick)="acceptContract(contract)"
                        size="small"
                        text="ACCEPT"
                    ></general-button>
                    <general-button
                        *ngIf="!hasAgencyDeclinedAlready(contract)"
                        [isDisabled]="isOfferEndedOrClosed(contract)"
                        (onClick)="declineContract(contract)"
                        title="{{declineOfferTooltip}}"
                        size="small"
                        backgroundStyle="outline"
                        color="danger"
                        text="DECLINE"
                    ></general-button>
                    <general-button
                        [isDisabled]="isOfferEndedOrClosed(contract)"
                        (onClick)="markContractAsDocumentsNeeded(contract)"
                        title="{{documentsNeededTooltip}}"
                        size="small"
                        backgroundStyle="outline"
                        color="dark"
                        text="MORE DOCS"
                    ></general-button>
                </div>
            </div>
        </div>
        <div *ngIf="offerVacancies !== null" class="extra-row">
            <i class="material-icons"> people </i>
            <small class="text-muted">
                {{
                    offerVacancies - vacancyMatches >= 0
                        ? offerVacancies - vacancyMatches
                        : 0
                }}
                opened position/s left
            </small>
        </div>
    </div>
    <div *ngIf="!isLoading && contractsList.length === 0" class="no-applicants">
        <h1 class="h1">
            {{ 'There are no applicants for this request yet.' | translate }}
        </h1>
    </div>
</div>
