<div class="guidelines">
    <p>In order to create titles, you must follow these rules:</p>
    <ul>
        <li>Type of Technician field cannot be empty</li>
        <li>License field is optional</li>
    </ul>
    <p>
        Keep in mind that any repetition of "Type of Technician" and "License" (including empty) would
        be invalid (case insensitive).
    </p>
</div>

<div class="loader-wrapper">
    <staff-loader
        *ngIf="isLoading$ | async"
        [customClass]="'purple-loader'"
    ></staff-loader>
</div>

<div class="actions">
    <general-button
        (onClick)="addNewTitle()"
        backgroundStyle="outline"
        text="Add New Title"
    ></general-button>
    <general-button
        (onClick)="saveNewTitles()"
        [isDisabled]="(isLoading$ | async) || !isFormValid()"
        text="SAVE NEW TITLES"
    ></general-button>
</div>

<div *ngIf="titlesToAdd.length > 0" class="new-titles">
    <div>
        <p *ngFor="let message of errorMessages" class="message">
            {{ message }}
        </p>
    </div>
    <staffnow-new-title-form
        (remove)="removeTitle(index)"
        *ngFor="let title of titlesToAdd; index as index"
        [index]="index"
        [title]="title"
    ></staffnow-new-title-form>
</div>

<staffnow-title-list-backoffice
    [titles]="availableTitles"
    label="Available Titles"
></staffnow-title-list-backoffice>
