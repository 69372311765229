import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuardService } from '@libs/auth/services/authentication-guard.service';
import { NotFoundPageComponent } from '@libs/common-ui/not-found-page/not-found-page.component';
import { MailboxComponent } from '@libs/mailbox/mailbox.component';
import { UserProfileComponent } from '@libs/user-profile/user-profile.component';
import { UserProfileResolver } from '@libs/user-profile/user-profile.resolver';
import { ReportsResolverService } from '@libs/shared/services/reports-resolver.service';
import { CreateOfferPageComponent } from '@libs/create-offer-page/create-offer-page.component';
import { CreatePackageOfferComponent } from '@libs/package-offer/components/create-package-offer/create-package-offer.component';
import { CreateAgencyOfferPageComponent } from '@libs/create-agency-offer-page/create-agency-offer-page.component';
import { BackofficeLoginComponent } from './modules/backoffice-login/backoffice-login.component';
import { UnauthorizedPageComponent } from '@libs/common-ui/unauthorized-page/unauthorized-page.component';
import { BackofficeHomeComponent } from './modules/backoffice-home/backoffice-home.component';
import { ChangePasswordComponent } from '@libs/common-ui/password-management/change-password/change-password.component';
import { RequestChangePasswordComponent } from '@libs/common-ui/password-management/request-change-password/request-change-password.component';
import { UpdateAppComponent } from '@libs/common-ui/update-app/update-app.component';
import { CreatePermanentOfferPageComponent } from '../../../../libs/offer-management/create-permanent-offer/src/lib/create-permanent-offer-page.component';

export const routes: Routes = [
  {
    path: '',
    component: BackofficeHomeComponent,
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService],
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: BackofficeLoginComponent
  },
  {
    path: 'request-new-password',
    component: RequestChangePasswordComponent
  },
  {
    path: 'reset-password',
    component: ChangePasswordComponent
  },
  {
    path: 'technicians',
    loadChildren: () =>
      import('apps/back-office/src/app/modules/users/users.module').then(
        m => m.UsersModule
      ),
    data: {
      entity: 'technician',
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'mailbox/:id',
    component: MailboxComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      isAdmin: true,
      application: 'backoffice'
    }
  },
  {
    path: 'mailbox',
    component: MailboxComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      isAdmin: true,
      application: 'backoffice'
    }
  },
  {
    path: 'offer',
    component: CreateOfferPageComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'offer/create/:mroUuid',
    component: CreateOfferPageComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'permanent-offer',
    component: CreatePermanentOfferPageComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'permanent-offer/create/:mroUuid',
    component: CreatePermanentOfferPageComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'package-offer/create/:mroUuid',
    component: CreatePackageOfferComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'package-offer/edit/:refNumber',
    component: CreatePackageOfferComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'agency-offer/:packageContractId',
    component: CreateAgencyOfferPageComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    }
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    },
    resolve: {
      profile: UserProfileResolver
    }
  },
  {
    path: 'users',
    loadChildren: () =>
      import('apps/back-office/src/app/modules/users/users.module').then(
        m => m.UsersModule
      ),
    canActivate: [AuthenticationGuardService],
    data: {
      entity: 'admin',
      application: 'backoffice'
    }
  },
  {
    path: 'mro',
    loadChildren: () =>
      import('apps/back-office/src/app/modules/users/users.module').then(
        m => m.UsersModule
      ),
    canActivate: [AuthenticationGuardService],
    data: {
      entity: 'mro',
      application: 'backoffice'
    }
  },
  {
    path: 'agency',
    loadChildren: () =>
      import('apps/back-office/src/app/modules/users/users.module').then(
        m => m.UsersModule
      ),
    canActivate: [AuthenticationGuardService],
    data: {
      entity: 'agency',
      application: 'backoffice'
    }
  },
  {
    path: 'request-overview',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/admin-request-overview/admin-request-overview.module'
      ).then(m => m.AdminRequestOverviewModule),
    canActivate: [AuthenticationGuardService],
    data: {
      accessLinkRel: 'offers',
      application: 'backoffice'
    }
  },
  {
    path: 'manage-content',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/manage-content/manage-content.module'
      ).then(m => m.ManageContentModule),
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('apps/back-office/src/app/modules/reports/reports.module').then(
        m => m.ReportsModule
      ),
    canActivate: [AuthenticationGuardService],
    data: {
      application: 'backoffice'
    },
    resolve: {
      links: ReportsResolverService
    }
  },
  {
    path: 'agency-ordering',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/agency-ordering/agency-ordering.module'
      ).then(m => m.AgencyOrderingModule),
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'experience-letters',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/experience-letters/experience-letters.module'
      ).then(m => m.ExperienceLettersModule),
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'experience-confirmation',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/experience-confirmation/experience-confirmation.module'
      ).then(m => m.ExperienceConfirmationModule),
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'other-queries',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/other-queries/other-queries.module'
      ).then(m => m.OtherQueriesModule),
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'dashboards',
    loadChildren: () =>
      import(
        'apps/back-office/src/app/modules/dashboards/dashboards.module'
      ).then(m => m.DashboardsModule),
    data: {
      application: 'backoffice'
    },
    canActivate: [AuthenticationGuardService]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent
  },
  {
    path: 'update-app',
    component: UpdateAppComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  },
  {
    path: '404',
    component: NotFoundPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled',
      useHash: false,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
