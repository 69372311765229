import { createSelector, select } from '@ngrx/store';
import { AppState } from './app-state.model';
import { pipe } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';

export const selectFeature = (state: AppState) => state;

export const selectFacilityProfile = select(
  createSelector(selectFeature, state => state.facilityProfile)
);

const selectFacilityProfileFiltered = pipe(
  selectFacilityProfile,
  filter(facilityProfile => !!facilityProfile._links)
);

export const selectApprovedAgenciesUrl = pipe(
  selectFacilityProfileFiltered,
  filter(facilityProfile =>
    hasLink(facilityProfile, FacilityProfileLinkRel.ApprovedAgencies)
  ),
  select(facilityProfile =>
    getUrl(facilityProfile, FacilityProfileLinkRel.ApprovedAgencies)
  )
);

export const selectMroApprovalsUrl = pipe(
  selectFacilityProfileFiltered,
  filter(facilityProfile =>
    hasLink(facilityProfile, FacilityProfileLinkRel.MroApprovals)
  ),
  select(facilityProfile =>
    getUrl(facilityProfile, FacilityProfileLinkRel.MroApprovals)
  )
);

export const selectMroRatesUrl = pipe(
  selectFacilityProfileFiltered,
  filter(facilityProfile =>
    hasLink(facilityProfile, FacilityProfileLinkRel.MroRates)
  ),
  select(facilityProfile =>
    getUrl(facilityProfile, FacilityProfileLinkRel.MroRates)
  )
);
