<div class="bo-container">
    <staffnow-overview-wrapper
        (changeRoute)="handleRouteChange($event)"
        [activeItem]="activeItem"
        [entityList]="entityList"
        [isLoading]="isLoading"
        [menuItems]="menuItems"
    >
    </staffnow-overview-wrapper>
</div>
