import {RouterReducerState} from '@ngrx/router-store';

import {ManageContentStateModel} from '../modules/manage-content/state/manage-content-state.model';
import {ActivatedView, NavigationState} from '@libs/shared/bms-common/navigation/navigation.model';
import {ApiRootState} from '@libs/shared/bms-common/api-root/api-root.model';
import {Resource} from '@libs/shared/bms-common/rest/resource.model';
import {EnvironmentState} from '@libs/shared/bms-common/environment/environment.model';

export const AGENCIES_ORDERING_KEY = 'agenciesOrdering';

export interface AppState {
  navigation: NavigationState;
  apiRoot: ApiRootState;
  environment?: EnvironmentState;
  router: RouterReducerState<ActivatedView>;
  dashboard?: ManageContentStateModel;
  facilityProfile?: FacilityProfileState;
  agenciesOrdering: AgenciesOrderingState;
}

export interface AgenciesOrderingState {
  notFeaturedAgencies: Array<any>;
  featuredAgencies: Array<any>;
}

export interface FacilityProfileState extends Resource {
  location: string;
  name: string;
  shortPresentation: string | null;
  uuid: string;
  type: 'AGENCY' | 'MRO';
  markUp?: string;
  checklist: string;
  groupId?: string;
  groupName?: string;
  allowPrivateOffers?: boolean;
  offerDoubleCheck?: boolean;
  approvedAgenciesStatus: 'IDLE' | 'LOADING' | 'DIRTY';
  approvedAgencies: {
    selectedFacilities: Array<any>;
    availableFacilities: Array<any>;
  };
  mroApprovals?: Array<any>;
}

export const initialState: AppState = {
  apiRoot: {
    apiRoot: null
  },
  navigation: {
    activatedView: null,
    routeResources: {}
  },
  router: {
    navigationId: 1,
    state: {
      url: '',
      data: {},
      params: {},
      routePath: ['/'],
      routes: [],
      viewName: 'root',
      root: '/'
    }
  },
  facilityProfile: {
    location: '',
    name: '',
    shortPresentation: null,
    uuid: '',
    type: 'AGENCY',
    checklist: '',
    approvedAgenciesStatus: 'IDLE',
    approvedAgencies: {
      selectedFacilities: [],
      availableFacilities: []
    },
    mroApprovals: [],
    _links: null
  },
  agenciesOrdering: {
    notFeaturedAgencies: [],
    featuredAgencies: []
  }
};
