<ng-container *ngIf="amountOfTitles > 0; else emptyList">
    <h1 class="h1">{{ label }} ({{ amountOfTitles }})</h1>
    <table class="titles table table-striped table-bordered">
        <thead>
        <tr>
            <th class="type-name">Type of Technician</th>
            <th>License Name</th>
            <th>Availability for Aviation Companies</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let group of groupedTitles">
            <tr *ngFor="let title of group.titles; first as isFirst">
                <td
                    *ngIf="isFirst"
                    class="type-name"
                    [rowSpan]="group.titles.length"
                >
                    {{ group.type }}
                </td>
                <ng-container
                    *ngIf="title.isSingletonType; else nonSingletonTitle"
                >
                    <td>
                        <div class="additional-info singleton">
                            No Licenses Title
                        </div>
                    </td>
                </ng-container>
                <ng-template #nonSingletonTitle>
                    <td>{{ title.license }}</td>
                </ng-template>
                <td>
                    <ng-container
                        *ngIf="title.isLufthansa; else globalTitle"
                    >
                        <div class="additional-info lufthansa">
                            Lufthansa Group Only
                        </div>
                    </ng-container>
                    <ng-template #globalTitle>
                        <div class="additional-info global">
                            All Aviation Companies
                        </div>
                    </ng-template>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>

<ng-template #emptyList>
    <h1 class="h1">The title list is not loaded!</h1>
</ng-template>
