import { Action } from '@ngrx/store';
import { minLength, required } from 'ngrx-forms/validation';
import {
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate
} from 'ngrx-forms';

import { UsersActionTypes } from '../../state/users.actions';
import {
  initialMroFormState,
  MroRegistrationFormState,
  MroRegistrationStateModel
} from './mro-registration-state.model';

const mroFormReducer = createFormStateReducerWithUpdate(
  updateGroup<MroRegistrationStateModel>({
    name: validate<string>(required, minLength(2)),
    locationId: validate<number>(required)
  })
);

export function mroRegistrationFormReducer(
  state: MroRegistrationFormState = initialMroFormState,
  action: Action
): FormGroupState<MroRegistrationStateModel> {
  const form = mroFormReducer(state, action);
  if (action.type === UsersActionTypes.ResetFacilityRegistrationForm) {
    return {
      ...initialMroFormState
    };
  }
  return form;
}
