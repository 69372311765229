import { Component, OnInit } from '@angular/core';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';

import { AGENCIES_ORDERING_KEY } from '../../../../state/app-state.model';
import {
  GetAgencyOrderingList,
  SubmitOrderedAgenciesList,
  AgencyOrderingResetState,
  AgencyOrderingListLoaded,
  OrderedAgenciesListSubmitted,
  FailedToSubmitOrderedAgenciesList
} from '../../../../state/app.actions';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'staffnow-agency-ordering',
  templateUrl: './agency-ordering.component.html',
  styleUrls: ['./agency-ordering.component.scss']
})
export class AgencyOrderingComponent implements OnInit {
  public agenciesList: Array<any> = [];
  public orderedAgenciesList: Array<any> = [];
  public isLoading: boolean = true;
  public isChanged: boolean = false;
  public readonly orderedListId: string = 'ordered-agencies-list';
  private subs: Array<Subscription> = [];

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject
  ) {
    this.subs.push(
      this.store
        .pipe(select(state => state[AGENCIES_ORDERING_KEY]))
        .subscribe(agenciesOrdering => {
          this.agenciesList = [...agenciesOrdering.notFeaturedAgencies];
          this.orderedAgenciesList = [...agenciesOrdering.featuredAgencies];
        })
    );

    this.subs.push(
      this.actionsSubject
        .pipe(ofType(AgencyOrderingListLoaded))
        .subscribe(() => {
          this.isLoading = false;
        })
    );

    this.subs.push(
      this.actionsSubject
        .pipe(ofType(OrderedAgenciesListSubmitted))
        .subscribe(() => {
          this.isChanged = false;
          this.isLoading = false;
        })
    );

    this.subs.push(
      this.actionsSubject
        .pipe(ofType(FailedToSubmitOrderedAgenciesList))
        .subscribe(() => {
          this.isLoading = false;
        })
    );
  }

  ngOnInit() {
    this.store.dispatch(GetAgencyOrderingList());
  }

  ngOnDestroy() {
    this.store.dispatch(AgencyOrderingResetState());
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public drop(event: CdkDragDrop<any>) {
    this.isChanged = true;

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public submitFeaturedAgencies() {
    const featuredAgenciesUuidList = this.orderedAgenciesList.map(
      agency => agency.uuid
    );

    this.isLoading = true;
    this.store.dispatch(
      SubmitOrderedAgenciesList({ payload: featuredAgenciesUuidList })
    );
  }
}
