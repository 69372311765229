import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BackofficeReportConfig } from '@libs/shared/models/reports.model';
import { ReportsHelper } from '@libs/shared/helpers/reports.helper';
import { ReportDropDownEntity, ReportsState } from '../../state/reports-state.model';
import { isDate, isEmpty, isEqual, isNil } from 'lodash-es';
import { MroFacility, MroGroup } from '@libs/shared/models/facility.model';
import { dateTimeFromString, getCurrentUTCDateString } from '@libs/shared/helpers/date-utils';
import { UsersLinkRel } from '@libs/shared/linkrels/users.linkrel';
import { Interval } from 'luxon';
import { Store } from '@ngrx/store';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { map, switchMap, take } from 'rxjs/operators';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { MroFacilityLoaderService } from '../../../../../../../../libs/offer-management/shared/services/mro-facility-loader.service';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';

@Component({
  selector: 'staffnow-report-backoffice',
  templateUrl: './report-backoffice.component.html',
  styleUrls: ['./report-backoffice.component.scss']
})
export class ReportBackofficeComponent implements OnChanges {
  @Input() public report: BackofficeReportConfig;
  @Input() public reportsState: ReportsState = null;
  @Input() public entity: string;
  @Input() public menuItemsEnum = null;
  @Input() public isLoading: boolean = false;
  getUuid = entity => entity.uuid || entity.userUuid || entity.id;
  getLabel = entity => entity.name || entity.firstName + ' ' + entity.lastName;
  private readonly store = inject(Store);
  private readonly mroLoaderService = inject(MroFacilityLoaderService);
  protected readonly UsersLinkRel = UsersLinkRel;

  @Output()
  public generateReport: EventEmitter<BackofficeReportConfig> = new EventEmitter<BackofficeReportConfig>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.report && !isEqual(changes.report.currentValue, changes.report.previousValue)) {
      this.report.range = this.getInitialRangeValue();
    }
  }

  get isBackofficeTab(): boolean {
    return this.entity === this.menuItemsEnum.BACKOFFICE;
  }

  resetUuid(): void {
    this.report.uuid = null;
  }

  getDropdownListByEntity(): Array<ReportDropDownEntity> {
    switch (this.entity) {
      case this.menuItemsEnum.AGENCIES:
        return this.reportsState.agenciesList;
      case this.menuItemsEnum.MROS:
        return this.getMroDropdownList(this.report);
      default:
        return [];
    }
  }

  getMroDropdownList(report: BackofficeReportConfig): Array<MroFacility | MroGroup> {
    if (report.loadGroup) {
      return this.reportsState.mroGroupsList;
    }
  }

  isRangeSelectAvailable(reportKey: string): boolean {
    return ReportsHelper.isRangeSelectAvailable(reportKey);
  }

  generateReportByEntity(): void {
    this.generateReport.emit({ ...this.report });
    this.resetUuid();
    this.report.range = this.getInitialRangeValue();
  }

  isSubmitDisabled(): boolean {
    return (
      isNil(this.report.range) ||
      (!isDate(this.report.range) && isEmpty(this.report.range)) ||
      (isNil(this.report.uuid) && !this.isBackofficeTab) ||
      this.isSelectedRangeGreaterThanAllowed()
    );
  }

  private getInitialRangeValue() {
    if (this.report.isMonthly) {
      return null;
    }
    const today = getCurrentUTCDateString();
    return this.isRangeSelectAvailable(this.report.key) ? [] : [today, today];
  }

  getReportDate(): string {
    return this.report.range as string;
  }

  getReportRange(): string[] {
    return this.report.range as string[];
  }

  handleDateRangeUpdate(dates: any): void {
    this.report.range = dates;
  }

  loadMroList(term: string, pageNumber: number, pageSize: number) {
    return this.store.pipe(
      getFilteredApiRoot,
      take(1),
      switchMap((apiRoot: ApiRootResource) => this.mroLoaderService.getMroFacilities(getUrl(apiRoot, UsersLinkRel.GetMROFacilitiesIncludingDeleted), term, pageNumber, pageSize)),
      map(facilityPage => facilityPage._embedded.facilities)
    );
  }

  private isSelectedRangeGreaterThanAllowed() {
    return (
      this.report.maxDays &&
      Interval.fromDateTimes(dateTimeFromString(this.report.range[0]), dateTimeFromString(this.report.range[1])).length(
        'days'
      ) > this.report.maxDays
    );
  }
}
