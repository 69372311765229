<div class="backoffice-login-wrapper staffnow">
    <div class="log-box-container">
        <div class="log-form-column">
            <div class="logo-container">
                <div class="logo staffnow"></div>
            </div>
            <div class="log-form-content">
                <h1>BACKOFFICE</h1>
                <ng-container>
                    <staffnow-login-form
                        [privacyPolicyUrl]="privacyPolicyUrl"
                        [termsAndConditionsUrl]="termsAndConditionsUrl"
                    >
                    </staffnow-login-form>
                </ng-container>
            </div>
        </div>
    </div>
</div>
