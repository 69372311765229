import { Injectable } from '@angular/core';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { MenuItem } from '@libs/shared/models/menu-item.model';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Link } from '@libs/shared/bms-common/rest/resource.model';
import { UserRoles } from '@libs/shared/models/roles.enum';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { AuthorizedUserProfileModel } from '@libs/shared/models/authorized-user-profile.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../state/app-state.model';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';

interface MenuConfiguration {
  label: string;
  route: string;
  enablingLinks: string[];
}

const BACKOFFICE_MENUS: MenuConfiguration[] = [
  {
    label: 'Profile',
    route: 'profile',
    enablingLinks: [ApiRootLinkRel.MyProfile]
  },
  {
    label: 'Technicians',
    route: 'technicians',
    enablingLinks: [ApiRootLinkRel.Technicians]
  },
  {
    label: 'Aviation Companies',
    route: 'mro',
    enablingLinks: [ApiRootLinkRel.GetMroUsers]
  },
  {
    label: 'Agencies',
    route: 'agency',
    enablingLinks: [ApiRootLinkRel.GetAgencyUsers]
  },
  {
    label: 'Users and Roles',
    route: 'users',
    enablingLinks: [ApiRootLinkRel.AdministrativeUsers]
  },
  {
    label: 'Job Openings and Applications',
    route: 'request-overview',
    enablingLinks: [ApiRootLinkRel.Offers]
  },
  {
    label: 'Experience Letters',
    route: 'experience-letters',
    enablingLinks: [ApiRootLinkRel.ExperienceLetter]
  },
  {
    label: 'Confirmation of Experience',
    route: 'experience-confirmation',
    enablingLinks: [ApiRootLinkRel.ExperienceConfirmationRequest]
  },
  {
    label: 'Reports',
    route: 'reports',
    enablingLinks: [ApiRootLinkRel.Reports]
  },
  {
    label: 'Manage Content',
    route: 'manage-content',
    enablingLinks: [ApiRootLinkRel.UpdateAircraft]
  },
  {
    label: 'Dashboards',
    route: 'dashboards',
    enablingLinks: []
  },
  {
    label: 'Other Queries',
    route: 'other-queries',
    enablingLinks: [ApiRootLinkRel.Queries]
  }
];

@Injectable({ providedIn: 'root' })
export class BackofficeNavbarService {
  authorizedUserProfile: AuthorizedUserProfileModel = null;
  private apiRoot: ApiRootResource = null;

  constructor(private store: Store<AppState>) {
    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      this.apiRoot = cloneDeep(apiRoot);
      this.authorizedUserProfile = getEmbeddedResource(this.apiRoot, ApiRootLinkRel.AuthorizedUserProfile);
    });
  }

  public convertRoutesToMenuItems(routes: { [key: string]: Link }): MenuItem[] {
    if (routes) {
      const routePaths: string[] = Object.keys(routes);
      return BACKOFFICE_MENUS.filter(it => this.isMenuAccessible(it, routePaths)).map(it => ({
        route: it.route,
        label: it.label
      }));
    }
    return [];
  }

  private isMenuAccessible(config: MenuConfiguration, routePaths: string[]): boolean {
    return isEmpty(config.enablingLinks) || config.enablingLinks.some(link => routePaths.includes(link));
  }
}
