import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { AdminRequestsOverviewAction, AdminRequestsOverviewActionTypes } from './admin-requests-overview.actions';
import { getEmbeddedResource } from '@libs/shared/bms-common/rest/resource.utils';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';

export const ADMIN_REQUESTS_OVERVIEW_FEATURE_KEY = 'adminRequestsOverview';

export function getReducers(): ActionReducerMap<AdminRequestsOverviewState> {
  return {
    technicianList: technicianListReducer,
    mroList: mroListReducer,
    agencyList: agencyListReducer,
    _links: linksReducer
  };
}

export interface AdminRequestsOverviewState {
  technicianList: Array<any>;
  mroList: Array<any>;
  agencyList: Array<any>;
  _links: any;
}

export const initialState: AdminRequestsOverviewState = {
  technicianList: [],
  mroList: [],
  agencyList: [],
  _links: null
};

export function technicianListReducer(
  state: Array<any> = initialState.technicianList,
  action: AdminRequestsOverviewAction | any
): Array<any> {
  if (action.type === GlobalReset.type) {
    return initialState.technicianList;
  }
  return state;
}

export function mroListReducer(
  state: Array<any> = initialState.mroList,
  action: AdminRequestsOverviewAction | any
): Array<any> {
  switch (action.type) {
    case AdminRequestsOverviewActionTypes.MrosListLoaded:
      const list = getEmbeddedResource<Array<any>>(
        action.payload,
        'facilities'
      );
      return [...list];
    case GlobalReset.type:
      return initialState.mroList;
  }
  return state;
}

export function agencyListReducer(
  state: Array<any> = initialState.agencyList,
  action: AdminRequestsOverviewAction | any
): Array<any> {
  switch (action.type) {
    case AdminRequestsOverviewActionTypes.AgenciesListLoaded:
      const list = getEmbeddedResource<Array<any>>(
        action.payload,
        'facilities'
      );
      return [...list];
    case GlobalReset.type:
      return initialState.agencyList;
  }
  return state;
}

export function linksReducer(
  state: number = initialState._links,
  action: AdminRequestsOverviewAction | any
): any {
  switch (action.type) {
    case AdminRequestsOverviewActionTypes.RequestOverviewLinksLoaded:
      return {
        ...action.payload._links
      };
    case GlobalReset.type:
      return initialState._links;
  }
  return state;
}

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<AdminRequestsOverviewState>
>('[Requests Overview] Registered Reducers');
