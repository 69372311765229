import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {take} from 'rxjs/operators';
import {cloneDeep, flatten, isNil, sortBy} from 'lodash-es';
import {GetAmeTitleDocumentsOptions, GetExistingOptionsForTitle, UpdateDocumentsForTitle} from '../../state/manage-content.actions';
import {getAmeTitleDocumentsOptions, getExistingOptionsForSelectedTitle, selectIsLoading} from '../../state/manage-content.selectors';
import {AmeTitleDocumentsOptions, DocumentTypeOption, ExtendedDocumentArea} from '../../state/title-documents.model';
import {ModalService} from '@libs/common-ui/services/modal.service';
import {ManageContentGuideline, ManageDocumentsGuidelines} from "../../manage-content-guides.constants";

interface AmeTitleForDropdown {
  id: number;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'staffnow-title-documents-management',
  templateUrl: './title-documents-management.component.html',
  styleUrls: ['./title-documents-management.component.scss']
})
export class TitleDocumentsManagementComponent {
  public optionsForCurrentTitle: ExtendedDocumentArea[] = [];
  public availableTitles: AmeTitleForDropdown[] = [];
  public selectedTitle: AmeTitleForDropdown = null;
  public isLoading: boolean = false;
  public documentsGuidelines: ManageContentGuideline[] = ManageDocumentsGuidelines;
  private ameTitleDocumentsOptions: AmeTitleDocumentsOptions;

  constructor(private store: Store<any>, private modalService: ModalService) {
    this.store.dispatch(GetAmeTitleDocumentsOptions());
    this.setUpStoreSubscriptions();
  }

  private setUpStoreSubscriptions() {
    this.store
      .pipe(getAmeTitleDocumentsOptions, take(1))
      .subscribe((response: AmeTitleDocumentsOptions) => {
        this.ameTitleDocumentsOptions = response;
        this.clearSelectedTitle();
        this.availableTitles = sortBy(
          this.ameTitleDocumentsOptions.ameTitles.map(title => {
            let license = '';
            if (!title.isSingletonType) {
              license = ' ' + title.license;
            }
            return {id: title.id, name: title.type + license};
          }),
          ['name']
        );
      });
    this.store
      .select(selectIsLoading)
      .pipe(untilDestroyed(this))
      .subscribe(isLoading => (this.isLoading = isLoading));
    this.store
      .pipe(getExistingOptionsForSelectedTitle, untilDestroyed(this))
      .subscribe((existingOptions: DocumentTypeOption[]) => {
        this.optionsForCurrentTitle = this.ameTitleDocumentsOptions?.documentOptions.map(
          documentArea => {
            return {
              ...documentArea,
              types: documentArea.types.map(type => {
                const option = existingOptions.find(
                  o => o.documentTypeId == type.id
                );
                return {
                  ...type,
                  isSelected: !isNil(option),
                  ...(option ?? {
                    isMandatory: false,
                    documentTypeId: type.id,
                    isExpirationDateRequired: false
                  })
                };
              })
            };
          }
        );
      });
  }

  public handleTitleChange(title: AmeTitleForDropdown): void {
    if (!isNil(title)) {
      this.store.dispatch(GetExistingOptionsForTitle({ameTitleId: title.id}));
    } else {
      this.clearSelectedTitle();
    }
  }

  private clearSelectedTitle() {
    this.optionsForCurrentTitle = [];
    this.selectedTitle = null;
  }

  public submit(): void {
    this.modalService.openConfirmModal(
      `Are you sure you want to change the documents configuration for the title '${this.selectedTitle.name}'?`,
      () =>
        this.store.dispatch(
          UpdateDocumentsForTitle({
            ameTitleId: this.selectedTitle.id,
            payload: cloneDeep(
              flatten(this.optionsForCurrentTitle.map(option => option.types))
            )
          })
        )
    );
  }

}
