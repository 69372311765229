import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment as builtEnv } from './environments/environment';
import {
  loadEnvironment,
  saveBrandName,
  saveEnvironment
} from '@libs/shared/bms-common/environment/environment.loader';

if (builtEnv.production) {
  enableProdMode();
}

/*
 * Creating this wrapper function is necessary, because otherwise Angular doesn't
 * detect the bootstrap code correctly and lazy loaded modules are left unbundled
 */
function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}

loadEnvironment(builtEnv.europeApiUrl + builtEnv.environmentEndpoint).then(
  remoteData => {
    saveBrandName(builtEnv.brandConfig.brandName);
    saveEnvironment({
      ...remoteData,
      ...builtEnv,
      remoteEndPoint: remoteData.apiBackofficeEndpoint
    });
    if (builtEnv.production) {
      enableProdMode();
    }
    bootstrap();
  }
);
