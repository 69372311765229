export const TABLE_COLS_CONFIG = {
  admin: [
    {
      name: 'Email',
      key: 'email',
      sortable: true
    },
    {
      name: 'First Name',
      key: 'firstName',
      sortable: true
    },
    {
      name: 'Last Name',
      key: 'lastName',
      sortable: true
    },
    {
      name: 'Role',
      key: 'role',
      type: 'role',
      sortable: false
    },
    {
      name: 'Created',
      key: 'createdOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Last Login',
      key: 'lastLoginOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Account Status',
      key: 'accountStatus',
      sortable: true
    }
  ],
  mro: [
    {
      name: 'Email',
      key: 'email',
      sortable: true
    },
    {
      name: 'First Name',
      key: 'firstName',
      sortable: true
    },
    {
      name: 'Last Name',
      key: 'lastName',
      sortable: true
    },
    {
      name: 'Aviation Company',
      key: 'facilityName',
      sortable: false
    },
    {
      name: 'Role',
      key: 'role',
      type: 'role',
      sortable: false
    },
    {
      name: 'Created',
      key: 'createdOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Last Login',
      key: 'lastLoginOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Account Status',
      key: 'accountStatus',
      sortable: true
    }
  ],
  agency: [
    {
      name: 'Email',
      key: 'email',
      sortable: true
    },
    {
      name: 'First Name',
      key: 'firstName',
      sortable: true
    },
    {
      name: 'Last Name',
      key: 'lastName',
      sortable: true
    },
    {
      name: 'Agency',
      key: 'facilityName',
      sortable: false
    },
    {
      name: 'Created',
      key: 'createdOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Last Login',
      key: 'lastLoginOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Account Status',
      key: 'accountStatus',
      sortable: true
    }
  ],
  technician: [
    {
      name: 'Email',
      key: 'email',
      sortable: true
    },
    {
      name: 'Ref. Number',
      key: 'refNumber',
      sortable: true
    },
    {
      name: 'First Name',
      key: 'firstName',
      sortable: true
    },
    {
      name: 'Last Name',
      key: 'lastName',
      sortable: true
    },
    {
      name: 'Last Login',
      key: 'lastLoginOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Account Status',
      key: 'accountStatus',
      sortable: true
    },
    {
      name: 'Profile Status',
      key: 'profileStatus',
      sortable: true
    },
    {
      name: 'Reviewed by',
      key: 'statusChangedBy',
      type: 'name',
      sortable: false
    }
  ],
  mroFacilities: [
    {
      name: 'Name',
      key: 'name',
      sortable: true
    },
    {
      name: 'Location',
      key: 'location',
      sortable: true
    },
    {
      name: 'Group',
      key: 'groupName',
      sortable: true
    },
    {
      name: 'Created on',
      key: 'createdOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Status',
      key: 'status'
    }
  ],
  agencyFacilities: [
    {
      name: 'Name',
      key: 'name',
      sortable: true
    },
    {
      name: 'Location',
      key: 'location',
      sortable: true
    },
    {
      name: 'Created on',
      key: 'createdOn',
      type: 'date',
      sortable: true
    },
    {
      name: 'Markup',
      key: 'markUp',
      sortable: true
    }
  ],
  mroGroups: [
    {
      name: 'Name',
      key: 'name',
      sortable: true
    },
    {
      name: 'Created on',
      key: 'createdOn',
      type: 'date',
      sortable: true
    }
  ]
};
