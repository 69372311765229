import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalService } from '@libs/common-ui/services/modal.service';
import {
  GetDocumentTypesByDocumentAreaId,
  SuccessfulUpdateDocumentTypes,
  UpdateDocumentTypes
} from '../../state/manage-content.actions';
import { getExistingDocuments } from '../../state/manage-content.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT_AREA_PERSONAL, ExtendedDocumentType } from '../../state/title-documents.model';
import { cloneDeep, flatten, isNil } from 'lodash-es';
import { getBrandName } from '@libs/shared/bms-common/environment/environment.selector';
import { ManageContentGuideline, ManageDocumentsGuidelines } from '../../manage-content-guides.constants';
import {
  DocumentChange,
  Operation
} from '../document-change-confirmation-modal/document-change-confirmation-modal.component';
import { Actions, ofType } from '@ngrx/effects';

@UntilDestroy()
@Component({
  selector: 'staffnow-personal-documents',
  templateUrl: './personal-documents.component.html',
  styleUrls: ['./personal-documents.component.scss']
})
export class PersonalDocumentsComponent {
  public documentTypes: ExtendedDocumentType[] = [];
  public serverDocuments: any[] = [];
  public brandName: string = '';
  public manageDocumentsGuidelines: ManageContentGuideline[] = ManageDocumentsGuidelines;

  constructor(private store: Store, private modalService: ModalService, private actions: Actions) {

    this.actions.pipe(ofType(SuccessfulUpdateDocumentTypes))
      .subscribe(() => {
        this.getPersonalDocumentsFromServer();
      });

    this.getPersonalDocumentsFromServer();
    this.personalDocumentsSubscription();

    this.store.pipe(getBrandName, untilDestroyed(this)).subscribe(brandName => {
      this.brandName = brandName;
    });
  }

  public savePersonalDocuments(): void {
    this.showOpenConfirmModal();
  }

  public hasChanges(): boolean {
    return !this.serverDocuments.some(document => {
      const doc = this.documentTypes.find(local => local.id === document.id);
      return (
        doc.isSelected !== document.isSelected ||
        doc.isExpirationDateRequired !== document.isExpirationDateRequired ||
        doc.isMandatory !== document.isMandatory
      );
    });
  }

  private getPersonalDocumentsFromServer(): void {
    this.store.dispatch(
      GetDocumentTypesByDocumentAreaId({
        documentAreaId: DOCUMENT_AREA_PERSONAL
      })
    );
  }

  private personalDocumentsSubscription(): void {
    this.store
      .select(getExistingDocuments)
      .pipe(untilDestroyed(this))
      .subscribe((existingOptions: ExtendedDocumentType[]) => {
        this.serverDocuments = existingOptions;
        this.documentTypes = existingOptions?.map(type => {
          const option = existingOptions.find(o => o.id == type.id);
          return {
            ...type,
            isSelected: !isNil(option),
            ...(option ?? {
              isMandatory: false,
              id: type.id,
              isExpirationDateRequired: false,
              isEnabled: false
            })
          };
        });
      });
  }

  private showOpenConfirmModal(): void {
    const changes: DocumentChange[] = this.calculateChangesInDocuments();

    const documentsWithChanges: DocumentChange[] = changes.filter(
      change =>
        change.operation !== Operation.NO_OP ||
        change.expiration !== Operation.NO_OP ||
        change.mandatory !== Operation.NO_OP
    );

    this.modalService.openDocumentChangeDialog(documentsWithChanges, this.brandName, () => {
      this.store.dispatch(
        UpdateDocumentTypes({
          payload: cloneDeep(flatten(this.documentTypes))
        })
      );
    });
  }

  private calculateChangesInDocuments(): DocumentChange[] {
    return this.serverDocuments.map(serverDocument => {
      const localDocument: ExtendedDocumentType = this.documentTypes.find(localDocument => localDocument.id === serverDocument.id);
      const documentChange = new DocumentChange();
      documentChange.id = serverDocument.id;
      documentChange.title = serverDocument.title;
      documentChange.operation = this.calculateOperation(localDocument.isSelected, serverDocument.isSelected);
      documentChange.mandatory = this.calculateOperation(localDocument.isMandatory, serverDocument.isMandatory);
      documentChange.expiration = this.calculateOperation(localDocument.isExpirationDateRequired, serverDocument.isExpirationDateRequired);
      return documentChange;
    });
  }

  private calculateOperation(isSelected: boolean, wasSelected: boolean): Operation {
    if (isSelected === wasSelected) {
      return Operation.NO_OP;
    }
    return isSelected && !wasSelected ? Operation.ADDED : Operation.REMOVED;
  }
}
