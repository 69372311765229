import { ChangeDetectionStrategy, Component, inject, Input, model } from '@angular/core';
import { BehaviorSubject, debounceTime, EMPTY, Observable, startWith, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MroFacility, SimpleFacilityWithUuid } from '@libs/shared/models/facility.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ErrorMessageService } from '@libs/common-ui/services/error-message/error-message.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const DEFAULT_PAGE_SIZE = 20;

@UntilDestroy()
@Component({
  selector: 'mro-selector',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgSelectModule,
    TranslateModule,
    FormsModule
  ],
  templateUrl: './mro-selector.component.html',
  styleUrl: './mro-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MroSelectorComponent {
  @Input() loadMros: (searchTerm: string, page: number, pageSize: number ) => Observable<MroFacility[]>;
  selectedMroUuid = model<string>(null);
  private readonly errorMessageService = inject(ErrorMessageService);
  mros$: Observable<SimpleFacilityWithUuid[]>;
  search$ = new BehaviorSubject({term: '', pageSize: DEFAULT_PAGE_SIZE, page: 0, mros: [] as SimpleFacilityWithUuid[] });
  loading: boolean = false;

  ngOnInit(): void {
    this.mros$ = this.search$
      .pipe(
        untilDestroyed(this),
        tap(() => this.loading = true),
        debounceTime(250),
        switchMap((searchParams) => this.loadMros(searchParams.term, searchParams.page, searchParams.pageSize)
          .pipe(
            map(mros => [...searchParams.mros, ...mros.map(mro => ({name: mro.name, uuid: mro.uuid}))] as SimpleFacilityWithUuid[]),
          )),
        catchError(err => {
            this.errorMessageService.handleErrorResponse(err);
            return EMPTY;
        }),
        tap(() => this.loading = false),
        startWith([])
      );
  }

  onSearch(term: string) {
    this.search$.next({
      term: term,
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      mros: [] as SimpleFacilityWithUuid[]
    });
  }

  loadMore(mroList: SimpleFacilityWithUuid[]) {
    const previousPageableParams = this.search$.getValue();
    this.search$.next({
      ...previousPageableParams,
      page: previousPageableParams.page + 1,
      mros: mroList
    });
  }
}
