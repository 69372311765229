import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SharedModule } from '../shared/shared.module';
import { ReportsComponent } from './reports.component';
import { ReportsRoutesModule } from './reports-routing.module';
import { ReportsEffects } from './state/reports.effects';
import { initialState as reportsInitialState, ReportsState } from './state/reports-state.model';
import { getReducers as getRegistrationReducers, reducersInjectionToken, REPORTS_FEATURE_KEY } from './state/reports.reducer';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { ReportBackofficeComponent } from './components/report-backoffice/report-backoffice.component';
import { ReportsBackofficeListComponent } from './components/reports-backoffice-list/reports-backoffice-list.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TechnicianPaginatedSelectorComponent } from '@libs/common-ui/technician-paginated-selector/technician-paginated-selector.component';
import { MroSelectorComponent } from '@libs/common-ui/mro-selector/mro-selector.component';

export function getInitialState(): Partial<ReportsState> {
  return {
    ...reportsInitialState
  };
}

@NgModule({
  imports: [
    CommonModule,
    ReportsRoutesModule,
    SharedModule,
    CommonUiModule,
    BsDropdownModule,
    StoreModule.forFeature(REPORTS_FEATURE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    }),
    EffectsModule.forFeature([ReportsEffects]),
    TechnicianPaginatedSelectorComponent,
    MroSelectorComponent
  ],
  providers: [
    {provide: reducersInjectionToken, useFactory: getRegistrationReducers}
  ],
  declarations: [
    ReportsComponent,
    ReportBackofficeComponent,
    ReportsBackofficeListComponent
  ]
})
export class ReportsModule {
}
