import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ManageContentComponent } from './manage-content.component';
import { AuthenticationGuardService } from '@libs/auth/services/authentication-guard.service';

export const routes: Routes = [
  {
    path: 'manage-content',
    component: ManageContentComponent,
    canActivate: [AuthenticationGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageContentRoutingModule {}
