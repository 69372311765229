import { Component } from "@angular/core";
import { getStaticEnvironment } from "@libs/shared/bms-common/environment/environment.selector";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngrx/store";

@UntilDestroy()
@Component({
  selector: 'staffnow-backoffice-login',
  templateUrl: './backoffice-login.component.html',
  styleUrls: ['backoffice-login.component.scss']
})
export class BackofficeLoginComponent {
  public privacyPolicyUrl: string;
  public termsAndConditionsUrl: string;

  constructor(
    private store: Store<any>,
  ) {
    this.store.pipe(getStaticEnvironment).subscribe(env => {
      this.privacyPolicyUrl = env.brandConfig.privacyPolicy;
      this.termsAndConditionsUrl = env.brandConfig.termsAndConditions;
    });
  }
}
