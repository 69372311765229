import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';

import { ManageContentRoutingModule } from './manage-content-routing.module';
import { ManageContentComponent } from './manage-content.component';
import { getReducers, reducersInjectionToken } from './state/manage-content.reducer';
import {
  initialState as manageContentInitialState,
  ManageContentStateModel,
  STATE_FEATURE_KEY
} from './state/manage-content-state.model';
import { ManageContentEffects } from './state/manage-content.effects';
import { SharedModule as BackOfficeSharedModule } from '../shared/shared.module';
import { AircraftListBackofficeComponent } from './components/aircraft-list-backoffice/aircraft-list-backoffice.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';
import { FaqBOComponent } from './components/faq/faq.component';
import { FaqItemComponent } from './components/faq-item/faq-item.component';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { SharedModule } from '@libs/shared/shared.module';
import { LanguagesBoComponent } from './components/languages-bo/languages-bo.component';
import { TitleManagementComponent } from './components/title-management/title-management.component';
import { TitleListBackofficeComponent } from './components/title-management/title-list-backoffice/title-list-backoffice.component';
import { NewTitleFormComponent } from './components/title-management/new-title-form/new-title-form.component';
import { TitleDocumentsManagementComponent } from './components/title-documents-management/title-documents-management.component';
import { LocationListBackoffice } from './components/location-list-backoffice/location-list-backoffice.component';
import { PersonalDocumentsComponent } from './components/personal-documents/personal-documents.component';
import { DocumentTypesListComponent } from './components/document-types-list/document-types-list.component';

export function getInitialState(): Partial<ManageContentStateModel> {
  return {
    ...manageContentInitialState
  };
}

@NgModule({
  declarations: [
    ManageContentComponent,
    AircraftListBackofficeComponent,
    SlideshowComponent,
    FaqBOComponent,
    FaqItemComponent,
    LanguagesBoComponent,
    TitleManagementComponent,
    TitleListBackofficeComponent,
    NewTitleFormComponent,
    TitleDocumentsManagementComponent,
    LocationListBackoffice,
    PersonalDocumentsComponent,
    DocumentTypesListComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ManageContentRoutingModule,
    SharedModule,
    CommonUiModule,
    BackOfficeSharedModule,
    FileUploadModule,
    BrowserAnimationsModule,
    QuillModule.forRoot(),
    EffectsModule.forFeature([ManageContentEffects]),
    StoreModule.forFeature(STATE_FEATURE_KEY, reducersInjectionToken, {
      initialState: getInitialState
    })
  ],
  providers: [{ provide: reducersInjectionToken, useFactory: getReducers }]
})
export class ManageContentModule {}
