import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OperatorFunction, Subscription } from 'rxjs';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { EntityTab, tabsGroups } from '../../users-tabs.constant';
import { filtersConfig } from '../../../shared/config/filtersConfig';
import { USERS_STATE_KEY, UsersFilterValueModel, UsersListingModel } from '../../state/users-state.model';
import {
  CreateNewGroup,
  EditGroup,
  GetEntitiesList,
  GetMroGroupsList,
  GetPageOfFacilities,
  LoadMoreFacilities,
  ResetFilters,
  ResetState,
  SubmitDeleteFacility,
  SubmitDeleteUser,
  UpdateFilters,
  UsersActionTypes
} from '../../state/users.actions';
import { GroupRegistrationComponent } from '../group-registration/group-registration.component';
import { AgencyRegistrationComponent } from '../agency-facility-registration/agency-registration.component';
import { MroRegistrationComponent } from '../mro-facility-registration/mro-registration.component';
import { ofType } from '@ngrx/effects';
import { cloneDeep, isEmpty } from 'lodash-es';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { FilterConfigModel } from '@libs/staff-filters/models/filter-config.model';
import { EntityTypes, EntityTypeUtils } from '@libs/shared/models/entity-types.enum';
import { UsersService } from '@libs/shared/services/users.service';
import { getFilteredApiRoot } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { ApiRootResource } from '@libs/shared/bms-common/api-root/api-root.model';
import { getEmbeddedResource, getUrl, hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { ApiRootLinkRel } from '@libs/shared/linkrels/api-root.linkrel';
import { UsersLinkRel } from '@libs/shared/linkrels/users.linkrel';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import {
  FacilityProfileLoaded,
  FacilityProfileUpdated
} from '@libs/common-ui/facility-profile/facility-profile.actions';
import { FacilityProfileLinkRel } from '@libs/shared/linkrels/facility-profie.linkrel';
import { BackofficeFeatureAccessPolicyService } from '@back-office/shared/services/backoffice-feature-access-policy/backoffice-feature-access-policy.service';

@Component({
  selector: 'manage-wrapper',
  templateUrl: './manage-wrapper.component.html'
})
export class ManageWrapperComponent implements OnInit, OnDestroy {
  currentTabsGroup: Array<EntityTab> = null;
  activeTab: EntityTab = null;
  lastActiveTab: EntityTab = null;

  @ViewChild('regForm') regForm: any;
  @ViewChild('mroRegistrationForm') mroRegistrationForm: MroRegistrationComponent;
  @ViewChild('agencyRegistrationForm') agencyRegistrationForm: AgencyRegistrationComponent;
  entity: EntityTypes;
  filtersConfig: Array<FilterConfigModel>;
  currentPage: number = 0;
  nextPage: number;
  pageSize: number;
  totalElements: number;
  userPermissions: any = {
    isAbleToCreateUsers: false,
    isAbleToCreatFacilities: false,
    isAbleToCreateGroups: false
  };
  isLoading: boolean = false;
  //TODO(SN-971): Model for facilities
  listOfFacilities: Array<any>;
  usersList: Array<UsersListingModel>;

  subs: Array<Subscription> = [];
  mroGroupsList: Array<any> = [];
  sortingQuery: string;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public actionsSubject: ActionsSubject,
    private modalService: BsModalService,
    private confirmationService: ModalService,
    private usersService: UsersService,
    private accessPolicyService: BackofficeFeatureAccessPolicyService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.entity = this.activatedRoute.snapshot.data.entity;
    this.currentTabsGroup = tabsGroups[this.entity];
    this.filtersConfig = cloneDeep(filtersConfig[this.entity]);
    this.storeSubscribe(getFilteredApiRoot, apiRoot => this.setUserPermissions(apiRoot, this.entity));
    this.watchQueryParams();
    this.createStateSubscriptions();
    this.createActionsSubscriptions();
    this.initializeMroFilterWithLazyLoadingEvents();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetFilters());
    this.store.dispatch(new ResetState());
    this.subs.forEach(sub => sub?.unsubscribe());
  }

  get isAgencyOrdering(): boolean {
    return EntityTypeUtils.isAgencyOrdering(this.entity);
  }

  setUserPermissions(apiRoot: ApiRootResource, entity: EntityTypes) {
    const keys = Object.keys(apiRoot._links);

    if (EntityTypeUtils.isTechnician(entity)) {
      this.prepareFilter('aircraft', getEmbeddedResource(apiRoot, ApiRootLinkRel.Airplanes));
      this.prepareFilter('accountStatus', getEmbeddedResource(apiRoot, ApiRootLinkRel.AccountStatusEnum));
      this.prepareFilter('profileStatus', getEmbeddedResource(apiRoot, ApiRootLinkRel.ProfileStatusEnum));
    }

    if (EntityTypeUtils.isAdmin(entity)) {
      this.userPermissions.isAbleToCreateUsers = keys.includes(UsersLinkRel.CreateAdminUser);
      this.userPermissions.isAbleToCreateUsers = keys.includes(UsersLinkRel.CreateTechnicianUser);
    }

    if (EntityTypeUtils.isMroRelated(entity)) {
      this.userPermissions.isAbleToCreateUsers = keys.includes(UsersLinkRel.CreateMROUser);
      this.userPermissions.isAbleToCreateFacilities = keys.includes(UsersLinkRel.CreateMROFacility);
      this.userPermissions.isAbleToCreateGroups = keys.includes(UsersLinkRel.CreateGroup);
    }

    if (EntityTypeUtils.isAgencyRelated(entity)) {
      this.userPermissions.isAbleToCreateUsers = keys.includes(UsersLinkRel.CreateAgencyUser);
      this.userPermissions.isAbleToCreateFacilities = keys.includes(UsersLinkRel.CreateAgencyFacility);
    }
  }

  openRegForm(): void {
    this.regForm.openRegistrationForm();
  }

  openFacilityRegistration(): void {
    if (EntityTypeUtils.isMroRelated(this.entity)) {
      this.mroRegistrationForm.openRegistrationForm();
    } else {
      this.agencyRegistrationForm.openRegistrationForm();
    }
  }

  pageChanged($event: any): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        page: $event.page - 1
      }
    });
  }

  updateFilters(filters: UsersFilterValueModel): void {
    if (filters.ameTypeLicense) {
      filters.ameType = filters.ameTypeLicense.ameType;
      filters.ameLicense = filters.ameTypeLicense.ameLicense;
      delete filters.ameTypeLicense;
    }
    this.store.dispatch(new UpdateFilters(filters));
  }

  clearFilters(): void {
    this.router.navigate([], {
      queryParams: { activeTab: this.activeTab?.label }
    });
    // By creating a new object, the ngOnChanges of staff-filter is triggered
    this.filtersConfig = this.filtersConfig.map(config => ({
      ...config,
      initialValue: null
    }));
  }

  searchUsers(filters: UsersFilterValueModel): void {
    this.updateFilters(filters);
    if (filters) {
      this.router.navigate([], {
        queryParams: {
          activeTab: this.activeTab?.label,
          ...this.usersService.createFilterValueObject(filters, null, this.currentPage)
        }
      });
    } else {
      this.isLoading = true;
      this.getEntitiesList(this.currentPage);
    }
  }

  navigateToProfile(user: any): void {
    if (EntityTypeUtils.isProfileRelated(this.entity)) {
      const userUuid = getEmbeddedResource(user, ApiRootLinkRel.Profile)['userUuid'];
      this.router.navigate(['profile', userUuid], {
        relativeTo: this.activatedRoute.parent
      });
    } else if (EntityTypeUtils.isMroFacilityOrAgencyFacility(this.entity)) {
      this.openFacilityEdit(user);
    } else if (EntityTypeUtils.isMroGroup(this.entity)) {
      this.openCreateGroupForm(user);
    }
  }

  deleteUser(user: any): void {
    if (hasLink(user, UserProfileLinkRel.DeleteUser)) {
      this.confirmationService.openConfirmModal('SYSTEM.INFO.DELETE_USER', () =>
        this.store.dispatch(new SubmitDeleteUser(getUrl(user, UserProfileLinkRel.DeleteUser)))
      );
    }
  }

  deleteFacility(entity: any): void {
    if (EntityTypeUtils.isMroFacilities(this.entity)) {
      if (hasLink(entity, FacilityProfileLinkRel.DeleteFacility)) {
        this.confirmationService.openConfirmModal('Are you sure you want to delete this facility?', () =>
          this.store.dispatch(
            SubmitDeleteFacility({
              url: getUrl(entity, FacilityProfileLinkRel.DeleteFacility)
            })
          )
        );
      }
    } else if (EntityTypeUtils.isMroGroup(this.entity)) {
      if (hasLink(entity, FacilityProfileLinkRel.DeleteFacility)) {
        this.confirmationService.openConfirmModal('Are you sure you want to delete all facilities in this group?', () =>
          this.store.dispatch(
            SubmitDeleteFacility({
              url: getUrl(entity, FacilityProfileLinkRel.DeleteFacility)
            })
          )
        );
      }
    }
  }

  onChangeTab(): void {
    if (this.activeTab) {
      const queryParamsHandling = this.activeTab === this.lastActiveTab ? 'merge' : null;
      this.router.navigate([], {
        queryParamsHandling,
        queryParams: {
          activeTab: this.activeTab.label
        }
      });
    }
  }

  openFacilityEdit(facilityProfile: any): void {
    this.store.dispatch(FacilityProfileLoaded({ payload: facilityProfile }));
    this.router.navigate(['facility', facilityProfile.uuid], {
      relativeTo: this.activatedRoute.parent
    });
  }

  openCreateGroupForm(group: any = null): void {
    this.modalService.show(GroupRegistrationComponent, {
      ignoreBackdropClick: true,
      initialState: {
        initialState: group ? { mroList: this.listOfFacilities, group: group } : null,
        onCreateCallback: (formValue: any) => this.createNewGroup(formValue, group)
      },
      class: 'modal-center'
    });
  }

  createNewGroup(createGroupFormValue: any, group: any): void {
    if (group) {
      this.store.dispatch(new EditGroup(group, createGroupFormValue));
    } else {
      this.store.dispatch(new CreateNewGroup(createGroupFormValue));
    }
  }

  sortingTrigger(sortingQuery: string): void {
    this.sortingQuery = sortingQuery;
    this.store.dispatch(new GetEntitiesList(this.currentPage, this.entity, sortingQuery));
  }

  private prepareFilter(filterKey: string, resource: any): void {
    this.filtersConfig.find(config => config.key === filterKey).items = resource;
  }

  private createStateSubscriptions(): void {
    this.storeSubscribe(
      select(state => state[USERS_STATE_KEY]),
      state => {
        const { usersList, listOfFacilities, mroGroupsList } = state;
        this.mroGroupsList = mroGroupsList;
        this.listOfFacilities = listOfFacilities?._embedded.facilities;

        if (EntityTypeUtils.isMroOrAgency(this.entity)) {
          this.filtersConfig.find(filterObj => filterObj.name === this.entity).items = this.listOfFacilities;
        }

        if (EntityTypeUtils.isMroFacilities(this.entity)) {
          this.filtersConfig.find(filterObj => filterObj.name === this.entity).items = mroGroupsList;
        }

        if (usersList) {
          this.usersList =
            usersList._embedded.users ||
            usersList._embedded.technicians ||
            usersList._embedded.facilities ||
            usersList._embedded.groups ||
            [];
          this.usersList = this.usersList.map(elem => {
            if (elem?._embedded && elem._embedded['profile']) {
              return { ...elem, ...elem._embedded['profile'] };
            }
            return elem;
          });
          this.currentPage = usersList.page;
          this.nextPage = usersList.page + 1;
          this.pageSize = usersList.pageSize;
          this.totalElements = usersList.totalElements;
        }
      }
    );

    this.removeAgencyOrderingTabIfAdminOrModeratorFromUsa();
  }

  private removeAgencyOrderingTabIfAdminOrModeratorFromUsa() {
    if (!this.accessPolicyService.isBackofficeEuropeOrHasCrossRegionAccess()) {
      const agencyOrderingTabIndex: number = this.currentTabsGroup.findIndex(
        it => it.entity === EntityTypes.AGENCY_ORDERING
      );
      if (agencyOrderingTabIndex != -1) {
        this.currentTabsGroup.splice(agencyOrderingTabIndex, 1);
      }
    }
  }

  private createActionsSubscriptions(): void {
    this.onAction(
      UsersActionTypes.FailedToGetUsersList,
      UsersActionTypes.UsersListReceived,
      () => (this.isLoading = false)
    );
    this.onAction(
      UsersActionTypes.SuccessCreatingNewGroup,
      UsersActionTypes.SuccessEditingGroup,
      UsersActionTypes.SuccessfullyRegistredFacility,
      () => this.getEntitiesList(this.currentPage)
    );
    this.onAction(FacilityProfileUpdated, () => {
      if (EntityTypeUtils.isMroFacilityOrAgencyFacility(this.entity)) {
        this.getEntitiesList(this.currentPage);
      }
    });
  }

  private getEntitiesList(page: number): void {
    this.isLoading = true;
    this.store.dispatch(new GetEntitiesList(page, this.entity, this.sortingQuery));
  }

  private storeSubscribe<T, S>(pipedSelector: OperatorFunction<T, S>, subscribeFn: (a: S) => void): void {
    this.subs.push(this.store.pipe(pipedSelector).subscribe(subscribeFn));
  }

  private onAction(...args: any[]): void {
    const subscribeFn = args.pop();
    this.subs.push(this.actionsSubject.pipe(ofType(...args)).subscribe(subscribeFn));
  }

  private watchQueryParams(): void {
    this.subs.push(
      this.activatedRoute.queryParams.subscribe(params => {
        this.updateFromQueryParams(params.page, params.activeTab, this.usersService.extractFiltersFromParams(params));
        this.initializeMroFilterWithLazyLoadingEvents();
      })
    );
  }

  private updateFromQueryParams(page: number, activeTab: string, filters: UsersFilterValueModel) {
    this.activeTab = this.currentTabsGroup.find(tab => tab.label == activeTab) || this.currentTabsGroup[0];
    this.lastActiveTab = this.activeTab;
    if (this.activeTab?.entity) {
      this.entity = this.activeTab.entity;
    }
    if (!this.isAgencyOrdering) {
      if (!EntityTypeUtils.isTechnician(this.entity)) {
        this.filtersConfig = cloneDeep(filtersConfig[this.entity]);
      }
      this.updateFilters(filters);
      this.setFiltersValues(filters);
      this.getEntitiesList(page ? page : this.currentPage);
      if (!EntityTypeUtils.isAdminOrTechnician(this.entity)) {
        this.store.dispatch(new GetPageOfFacilities({ page: 0, term: '' }, this.entity));
      }
      if (EntityTypeUtils.isMroRelated(this.entity)) {
        this.store.dispatch(new GetMroGroupsList());
      }
    }
  }

  private setFiltersValues(filters: UsersFilterValueModel): void {
    this.filtersConfig.forEach(config => (config.initialValue = filters[config.key] || null));
    if (!isEmpty(filters.aircraft)) {
      const ids = filters.aircraft.map(aircraft => aircraft.id);
      const config = this.filtersConfig.find(config => config.key === 'aircraft');
      config.initialValue = config.items.filter(aircraft => ids.includes(aircraft.id));
    }
    const ameTitleConfig = this.filtersConfig.find(config => config.key === 'ameTypeLicense');
    if (ameTitleConfig) {
      ameTitleConfig.initialValue = filters.ameType
        ? {
            ameType: filters.ameType,
            ameLicense: filters.ameLicense || null
          }
        : {};
    }
  }

  private initializeMroFilterWithLazyLoadingEvents(): void {
    const mroFilterConfig = this.filtersConfig.find(filterConfig => filterConfig.key === 'mroFacility');
    if (mroFilterConfig) {
      mroFilterConfig.onSearch = this.onMroSearch.bind(this);
      mroFilterConfig.loadMore = this.onMroLoadMore.bind(this);
      mroFilterConfig.onClear = this.onMroSearch.bind(this);
    }
  }

  private onMroSearch(searchTerm: string): void {
    this.store.dispatch(new GetPageOfFacilities({ page: 0, term: searchTerm }, this.entity));
  }

  private onMroLoadMore(): void {
    this.store.dispatch(new LoadMoreFacilities(this.entity));
  }
}
