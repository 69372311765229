import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { BehaviorSubject, debounceTime, Observable, Subscription, switchMap } from 'rxjs';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { FormGroupState, RemoveGroupControlAction, SetErrorsAction, SetValueAction } from 'ngrx-forms';

import { FEATURE_STATE_KEY } from '../../state/users.reducer';
import { ResetRegistrationForm, SubmitRegistrationForm, UsersActionTypes } from '../../state/users.actions';
import { REGISTRATION_FORM_ID, RegistrationFormModel } from './registration-form.model';
import { AgencyOrderingListLoaded, AppActionTypes, GetAgencyOrderingList, GetMroList } from 'apps/back-office/src/app/state/app.actions';
import { BO_CREATE_USER_ROLES, MRO_ROLES } from '../../../shared/config/roles';
import { ofType } from '@ngrx/effects';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { UserRoles } from '@libs/shared/models/roles.enum';
import { getFilteredApiRoot, getLoggedInUserRole } from '@libs/shared/bms-common/api-root/api-root.selectors';
import { MroFacilityLoaderService } from '../../../../../../../../libs/offer-management/shared/services/mro-facility-loader.service';
import { getUrl } from '@libs/shared/bms-common/rest/resource.utils';
import { UsersLinkRel } from '@libs/shared/linkrels/users.linkrel';
import { MroFacilitySimple } from '../../../../../../../staffnow-platform/src/app/state/app-state.model';


@Component({
  selector: 'staffnow-backoffice-registration',
  templateUrl: './backoffice-registration.component.html'
})
export class BackofficeRegistrationComponent implements OnInit, OnDestroy {
  public ENTITY_TYPES = EntityTypes;

  @Input() public facilityList?: Array<any>;
  @Input() public facilityName?: string;

  @ViewChild('modalWindow') private modalWindow: any;

  public registrationForm: Observable<FormGroupState<RegistrationFormModel>>;
  public registrationFormValue: FormGroupState<RegistrationFormModel>;
  public isLoading = false;
  public entityGroup: string = null;
  private subs: Subscription[] = [];
  public linkToFacility: boolean;
  public agenciesList: Array<{ uuid: string; name: string }> = [];
  public mroList: Array<{ uuid: string; name: string }> = [];
  public userRoles: Array<{ name: string; serverName: string }> = [];
  public getFacilityUuid = facility => facility.uuid;
  mroSearcherObservable = new BehaviorSubject({term: '', pageSize: 20, page: 0, facilities: []});
  mros$: Observable<MroFacilitySimple[]>;
  loading: boolean = false;

  private authorizedUserRole: string = '';

  constructor(
    private mroService: MroFacilityLoaderService,
    private store: Store<any>,
    private actionsSubject: ActionsSubject
  ) {
    this.store
      .pipe(getLoggedInUserRole, take(1))
      .subscribe(userRole => (this.authorizedUserRole = userRole));

    this.registrationForm = store.pipe(
      select(
        state =>
          <FormGroupState<RegistrationFormModel>>(
            state[FEATURE_STATE_KEY][REGISTRATION_FORM_ID]
          )
      )
    );
    this.registrationForm.subscribe(
      value => (this.registrationFormValue = value)
    );

    this.mros$ = this.mroSearcherObservable
      .pipe(
        debounceTime(250),
        tap(() => this.loading = true),
        withLatestFrom(this.store.pipe(getFilteredApiRoot)),
        switchMap(([{ term, pageSize, page, facilities }, apiRoot]) =>
          this.mroService.getMroFacilities(getUrl(apiRoot, UsersLinkRel.GetMROFacilitiesPaged), term, page, pageSize)
            .pipe(
              map((retrievedFacilities) => [
                ...facilities,
                ...retrievedFacilities._embedded.facilities.map(facility => ({uuid: facility.uuid, name: facility.name }))
              ]),
              tap(() => this.loading = false),
            )
        ),
      );


    this.subs.push(
      this.actionsSubject
        .pipe(
          filter(
            action =>
              action.type === UsersActionTypes.SuccessfulUserRegistration
          )
        )
        .subscribe(() => this.closeRegistrationForm())
    );

    this.subs.push(
      this.actionsSubject
        .pipe(
          filter(
            action => action.type === UsersActionTypes.FailedUserRegistration
          )
        )
        .subscribe(() => (this.isLoading = !this.isLoading))
    );

    this.subs.push(
      this.onActionOfType(AgencyOrderingListLoaded)
        .pipe(
          BackofficeRegistrationComponent.mapFacilities(),
          tap(agenciesList => (this.agenciesList = agenciesList))
        )
        .subscribe()
    );

    this.subs.push(
      this.onActionOfType(AppActionTypes.MroListLoaded)
        .pipe(
          BackofficeRegistrationComponent.mapFacilities(),
          tap(mroList => (this.mroList = mroList))
        )
        .subscribe()
    );

    this.subs.push(
      this.onActionOfType(SetValueAction.TYPE)
        .pipe(
          filter(
            (action: SetValueAction<any>) =>
              action.controlId ===
              this.registrationFormValue.controls.agencyUuid.id
          ),
          map((action: SetValueAction<any>) =>
            action.value ? {} : {required: {actual: ''}}
          ),
          tap(errors =>
            this.store.dispatch(
              new SetErrorsAction(
                this.registrationFormValue.controls.agencyUuid.id,
                errors
              )
            )
          )
        )
        .subscribe()
    );

    this.subs.push(
      this.onActionOfType(SetValueAction.TYPE)
        .pipe(
          filter(
            (action: SetValueAction<any>) =>
              this.registrationFormValue.controls.role &&
              action.controlId === this.registrationFormValue.controls.role.id
          ),
          map((action: SetValueAction<any>) =>
            action.value !== UserRoles.ROLE_AGENCY_TECHNICIAN
              ? {}
              : {required: {actual: ''}}
          ),
          tap(errors => {
            if (
              Object.keys(errors).length === 0 &&
              this.registrationFormValue.controls.agencyUuid
            ) {
              this.store.dispatch(
                new SetValueAction(
                  this.registrationFormValue.controls.agencyUuid.id,
                  null
                )
              );
            }

            this.store.dispatch(
              new SetErrorsAction(
                this.registrationFormValue.controls.agencyUuid.id,
                errors
              )
            );
          })
        )
        .subscribe()
    );
  }

  private onActionOfType(actionType) {
    return this.actionsSubject.pipe(ofType(actionType));
  }

  private static mapFacilities() {
    return map(
      (action: ReturnType<typeof AgencyOrderingListLoaded>) => {
        const facilities = action.payload;
        return facilities.map(facility => ({
          uuid: facility.uuid,
          name: facility.name
        }));
      }
    );
  }

  public handleRoleChange(): void {
    const role: string = this.registrationFormValue.controls.role.value;
    if (role === UserRoles.ROLE_TECHNICIAN && this.mroList.length === 0) {
      this.store.dispatch(GetMroList());
    } else if (
      role === UserRoles.ROLE_AGENCY_TECHNICIAN &&
      this.agenciesList.length === 0
    ) {
      this.store.dispatch(GetAgencyOrderingList());
    }
  }

  public ngOnInit() {
    this.updateLinkToFacility();
    this.updateEntityGroup();
    this.updateUserRoles();
    this.configRegForm();
  }

  public ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  public submitForm(): void {
    let regForm = {...this.registrationFormValue.value};

    if (this.linkToFacility) {
      this.store.dispatch(
        new SubmitRegistrationForm(
          this.registrationFormValue.value,
          this.facilityName
        )
      );
    } else {
      if (regForm.role === UserRoles.ROLE_AGENCY_TECHNICIAN) {
        regForm = {...regForm, role: UserRoles.ROLE_TECHNICIAN};
      }
      this.store.dispatch(new SubmitRegistrationForm(regForm));
    }
    this.isLoading = !this.isLoading;
  }

  public openRegistrationForm(): void {
    this.configRegForm();
    this.modalWindow.open();
  }

  onSearch(term: string) {
    this.mroSearcherObservable.next({
      page: 0,
      term,
      pageSize: 20,
      facilities: []
    });
  }

  onClose() {
      this.onSearch('');
  }

  loadMore(facilities: MroFacilitySimple[]) {
    const currentPageableValues = this.mroSearcherObservable.getValue();
    this.mroSearcherObservable.next({
      ...currentPageableValues,
      facilities,
      page: currentPageableValues.page + 1});
  }


  public closeRegistrationForm(): void {
    this.store.dispatch(new ResetRegistrationForm());
    this.modalWindow.close();
    this.isLoading = false;
  }

  private configRegForm(): void {
    this.store.dispatch(new ResetRegistrationForm());

    if (this.isAgencyRelatedFacility()) {
      this.store.dispatch(
        new RemoveGroupControlAction(this.registrationFormValue.id, 'role')
      );
    }
    if (!this.isAgencyRelatedFacility() && !this.isMroRelatedFacility()) {
      this.store.dispatch(
        new RemoveGroupControlAction(
          this.registrationFormValue.id,
          'facilityUuid'
        )
      );
      this.store.dispatch(
        new RemoveGroupControlAction(this.registrationFormValue.id, 'position')
      );
      this.store.dispatch(
        new RemoveGroupControlAction(
          this.registrationFormValue.id,
          'defaultUser'
        )
      );
    }
  }

  private updateLinkToFacility() {
    this.linkToFacility =
      this.isMroRelatedFacility() || this.isAgencyRelatedFacility();
  }

  private updateEntityGroup() {
    if (this.isMroRelatedFacility()) {
      this.entityGroup = 'Aviation Company';
    } else if (this.isAgencyRelatedFacility()) {
      this.entityGroup = 'Agency';
    } else {
      this.entityGroup = this.facilityName;
    }
  }

  private isMroRelatedFacility() {
    return (
      this.facilityName === this.ENTITY_TYPES.MRO_GROUPS ||
      this.facilityName === this.ENTITY_TYPES.MRO ||
      this.facilityName === this.ENTITY_TYPES.MRO_FACILITIES
    );
  }

  isAgencyRelatedFacility() {
    return (
      this.facilityName === this.ENTITY_TYPES.AGENCY_FACILITIES ||
      this.facilityName === this.ENTITY_TYPES.AGENCY
    );
  }

  private updateUserRoles() {
    this.userRoles = this.isMroRelatedFacility() ? MRO_ROLES : BO_CREATE_USER_ROLES;
  }
}
