import { FullTitleDtoReadOnly } from './full-title-dto.model';
import {
  AmeTitleDocumentsOptions,
  DocumentTypeOption, ExtendedDocumentType
} from './title-documents.model'
import {LocationPO} from "@libs/shared/models/location.model";

export const STATE_FEATURE_KEY = 'manage-content';

export interface ManageContentStateModel {
  titles: FullTitleDtoReadOnly[];
  locations: LocationPO[];
  isLoading: boolean;
  existingOptionsForTitle: DocumentTypeOption[];
  ameTitleDocumentsOptions: AmeTitleDocumentsOptions;
  documentTypes: ExtendedDocumentType[];
}

export const initialState: ManageContentStateModel = {
  titles: [],
  locations: [],
  isLoading: false,
  ameTitleDocumentsOptions: null,
  existingOptionsForTitle: [],
  documentTypes: []
};
