import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContractStatus } from '@libs/shared/models/contract-status.enum';
import { getLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import {
  AcceptAgencyOfferContract,
  DeclineAgencyOfferContract,
  MarkAgencyOfferContractAsDocumentsNeeded
} from '@libs/request-overview-common/state/requests-overview.actions';
import { ModalService } from '@libs/common-ui/services/modal.service';
import { AgencyOfferContractOutDto } from '@libs/shared/models/agency-offer-contract.model';
import { Contract } from '@libs/shared/models/contract.model';
import { EntityTypes } from '@libs/shared/models/entity-types.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'agency-offer-contracts-list-expand',
  templateUrl: './agency-offer-contracts-list-expand.component.html',
  styleUrls: ['./agency-offer-contracts-list-expand.component.scss']
})
export class AgencyOfferContractsListExpandComponent {
  @Input() contractsList: Array<AgencyOfferContractOutDto | Contract> = [];
  @Input() isLoading: boolean;
  @Input() offerVacancies: number;
  @Input() vacancyMatches: number;

  @Output() openPublicProfile: EventEmitter<{ contract: AgencyOfferContractOutDto; entityType: EntityTypes }> =
    new EventEmitter<{ contract: AgencyOfferContractOutDto; entityType: EntityTypes }>();

  constructor(
    private store: Store,
    private modalService: ModalService,
    private translateService: TranslateService
  ) {}

  hasReasons(contract: AgencyOfferContractOutDto): boolean {
    return contract.isRejected || contract.areDocumentsNeeded;
  }

  isOfferEndedOrClosed(contract: AgencyOfferContractOutDto): boolean {
    return contract.isAgencyOfferFinished || contract.isAgencyOfferExpired;
  }

  hasAgencyAcceptedAlready(contract: AgencyOfferContractOutDto): boolean {
    return contract.agencyStatus === ContractStatus.accepted;
  }

  hasAgencyDeclinedAlready(contract: AgencyOfferContractOutDto): boolean {
    return contract.agencyStatus === ContractStatus.rejected;
  }

  acceptContract(contract: AgencyOfferContractOutDto) {
    this.store.dispatch(
      AcceptAgencyOfferContract({
        refNumber: contract.offerRefNumber,
        url: getLink(contract, RequestOverviewLinkRel.AcceptAgencyOfferContract).href,
        isBackoffice: true
      })
    );
  }

  declineContract(contract: AgencyOfferContractOutDto): void {
    this.modalService.openTextAreaDialog(
      'Decline Contract',
      'Please, type the reason for declining this contract.',
      null,
      (text: string) => {
        this.store.dispatch(
          DeclineAgencyOfferContract({
            refNumber: contract.offerRefNumber,
            url: getLink(contract, RequestOverviewLinkRel.DeclineAgencyOfferContract).href,
            reason: text,
            isBackoffice: true
          })
        );
      }
    );
  }

  markContractAsDocumentsNeeded(contract: AgencyOfferContractOutDto) {
    this.modalService.openTextAreaDialog(
      'Request More Documents',
      'Please, inform an explanation for requesting additional documents.',
      null,
      (text: string): void => {
        this.store.dispatch(
          MarkAgencyOfferContractAsDocumentsNeeded({
            refNumber: contract.offerRefNumber,
            url: getLink(contract, RequestOverviewLinkRel.MarkAgencyOfferContractAsDocumentsNeeded).href,
            explanation: text,
            isBackoffice: true
          })
        );
      }
    );
  }

  get declineOfferTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.DECLINE_JOB_OPENING');
  }

  get documentsNeededTooltip(): string {
    return this.translateService.instant('SYSTEM.INFO.DOCUMENTS_NEEDED');
  }

  onOpenPublicProfile(entityType: EntityTypes, contract: AgencyOfferContractOutDto): void {
    this.openPublicProfile.emit({ entityType, contract: { ...contract } });
  }
}
