<div class="reports-bo-container">
    <div class="wrapper">
        <select-with-tabs
            title="Reports list"
            [items]="getAllReportsExceptUserList()"
            [getLabel]="getReportTitle"
            [getValue]="getReportKey"
            [initialValue]="getAllReportsExceptUserList()[0]"
            (onChange)="setSelectedReport($event)"
        >
            @if(selectedBackofficeReport) {
            <staffnow-report-backoffice
                [report]="selectedBackofficeReport"
                [reportsState]="reportsState"
                [entity]="entity"
                [menuItemsEnum]="MENU_ITEMS_ENUM"
                [isLoading]="isLoading"
                (generateReport)="generateReportByEntity($event)"
            ></staffnow-report-backoffice>
            }
        </select-with-tabs>
    </div>
    @if(isBackofficeTab) {
    <div class="export-button-wrapper">
        <general-button
            text="{{ 'Export List of Users' | translate }}"
            (onClick)="generateReportByEntity(getUserListReport())"
            [isLoading]="isLoading"
        ></general-button>
    </div>
    }
</div>
