<div class="bo-container">
    <ng-container *ngIf="menuItems.length > 0; else noReports">
        <entity-nav
            [activeItem]="activeItem"
            [menuItems]="menuItems"
            (selectedItem)="handleSelectItem($event)"
        >
        </entity-nav>

        <div class="reports-container">
            <staffnow-backoffice-reports
                [entity]="activeItem"
            ></staffnow-backoffice-reports>
        </div>
    </ng-container>
    <ng-template #noReports>
        <h1>There are no reports available to display.</h1>
    </ng-template>
</div>
