import { Action } from '@ngrx/store';
import { createFormStateReducerWithUpdate, FormGroupState, updateGroup, validate } from 'ngrx-forms';
import { minLength, pattern, required } from 'ngrx-forms/validation';

import { RegistrationFormModel, FormState, initialFormState } from './registration-form.model';
import { UsersActionTypes } from '../../state/users.actions';

const updateMyFormGroup = updateGroup<RegistrationFormModel>({
  firstName: validate<string>(required, minLength(2)),
  lastName: validate<string>(required, minLength(2)),
  email: validate<string>([required, pattern(/^[\w\-+]+(?:\.[\w\-+]+)*@(?:[\w\-+]+\.)+[a-zA-Z]{2,7}$/)]),
  facilityUuid: validate(required),
  role: validate(required),
  position: validate(required)
});

const formReducer = createFormStateReducerWithUpdate(updateMyFormGroup);

export function registrationFormReducer(
  state: FormState = initialFormState,
  action: Action
): FormGroupState<RegistrationFormModel> {
  const form = formReducer(state, action);

  if (action.type === UsersActionTypes.ResetRegistrationForm) {
    return {
      ...initialFormState
    };
  }

  return {
    ...form
  };
}
