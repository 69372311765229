<staffnow-modal-window
    [content]="mroFacilityRegistration"
    [autoSize]="true"
    [disableBackdrop]="true"
    #modalWindow
></staffnow-modal-window>
<ng-template #mroFacilityRegistration>
    <div class="facility-reg-form-container">
        <h2 class="h2">
            <strong>{{ 'Aviation Company Information' | translate }}</strong>
        </h2>
        <form
            autocomplete="off"
            novalidate
            [ngrxFormState]="mroRegistrationForm | async"
        >
            <label>{{ 'Company Name: ' | translate }}</label>
            <input
                class="form-control"
                type="text"
                name="email"
                [ngrxFormControlState]="
                    (mroRegistrationForm | async).controls.name
                "
            />
            <div class="dropdown">
                <label for="location">
                    {{ 'Company Location: ' | translate }}
                </label>
                <ng-select
                    id="location"
                    bindLabel="name"
                    [placeholder]="'Select a location' | translate"
                    [ngModelOptions]="{ standalone: true }"
                    [closeOnSelect]="true"
                    [clearSearchOnAdd]="true"
                    [searchable]="true"
                    [items]="locationList"
                    [(ngModel)]="selectedLocation"
                    (change)="writeLocationIdToForm()"
                ></ng-select>
            </div>
            <div class="dropdown">
                <label for="group">
                    {{ 'Aviation Company group: ' | translate }}
                </label>
                <ng-select
                    id="group"
                    [items]="mroGroupsList"
                    [searchable]="true"
                    bindLabel="name"
                    [ngrxFormControlState]="
                        (mroRegistrationForm | async).controls.groupId
                    "
                    [ngrxValueConverter]="groupValueConverter"
                    placeholder="Select from the list"
                >
                </ng-select>
            </div>
        </form>
        <div class="action-buttons">
            <general-button
                (onClick)="closeRegistrationForm()"
                backgroundStyle="outline"
                text="CANCEL"
            ></general-button>
            <general-button
                text="REGISTER"
                [isLoading]="isLoading"
                [isDisabled]="
                    !(mroRegistrationForm | async).isValid ||
                    !(mroRegistrationForm | async).isTouched
                "
                [customClass]="'btn-loader'"
                (onClick)="submitForm()"
            ></general-button>
        </div>
    </div>
</ng-template>
