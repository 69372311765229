import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DASHBOARDS_MENU_ITEMS } from '@libs/shared/constants/dashboardMenuItems.constants';

@Component({
  selector: 'staffnow-technician-dashboard-page',
  templateUrl: './technician-dashboard-page.component.html',
  styleUrls: ['./technician-dashboard-page.component.scss']
})
export class TechnicianDashboardPageComponent {
  get dashboardUrl(): string {
    return DASHBOARDS_MENU_ITEMS.TECHNICIANS.url;
  }

  get environment(): any {
    return environment;
  }
}
