<div class="overview-container">
    <div class="overview-filters align-items-center">
        <h4 class="h4">{{ 'Quick Filters: ' | translate }}</h4>
        <div class="filters-buttons">
            <general-button
                (onClick)="toggleFilters('all')"
                [highlight]="filters.all"
                color="primary-lowlight"
                size="small"
                text="All"
            ></general-button>
            <general-button
                (onClick)="toggleFilters('active')"
                [highlight]="filters.active"
                color="primary-lowlight"
                size="small"
                text="Active"
            ></general-button>
            @if (shouldDisplayPending) {
            <general-button
                (onClick)="toggleFilters('pending')"
                [highlight]="filters.pending"
                color="primary-lowlight"
                size="small"
                text="Pending"
            ></general-button>
            } @if (shouldDisplayDocumentsNeeded) {
            <general-button
                (onClick)="toggleFilters('documentsNeeded')"
                [highlight]="filters.documentsNeeded"
                color="primary-lowlight"
                size="small"
                text="Documents needed"
            ></general-button>
            }
            <general-button
                (onClick)="toggleFilters('past')"
                [highlight]="filters.past"
                color="primary-lowlight"
                size="small"
                text="Finished"
            ></general-button>
            @if (shouldDisplayRejected) {
            <general-button
                (onClick)="toggleFilters('rejected')"
                [highlight]="filters.rejected"
                color="primary-lowlight"
                size="small"
                text="Rejected"
            ></general-button>
            }
        </div>
        <div class="search-container">
            <input
                type="text"
                class="form-control"
                [placeholder]="'Search by Ref. No' | translate"
                (keyup)="pressedKeyValidation($event)"
                [(ngModel)]="refNumberSearch"
                [maxlength]="CUSTOM_REFERENCE_NUMBER_MAX_LENGTH"
            />
            <i
                class="material-icons"
                [style.visibility]="refNumberSearch ? 'visible' : 'hidden'"
                (click)="clearSearch()"
                (keydown.enter)="clearSearch()"
            >
                remove_circle
            </i>
            <general-button (onClick)="onSearchByRefNumber()" size="small" text="SEARCH"></general-button>
        </div>
    </div>

    <div class="overview-table-container">
        <table class="table table-sm table-striped" aria-label="List of jobs">
            <thead>
                <tr>
                    <th
                        *ngFor="let col of tableConfig"
                        class="{{ col.key }}"
                        [ngClass]="{
                            clickable: hasProp(col.key) || col.key === 'period'
                        }"
                        (click)="handleSortingChange(col.key)"
                        (keydown.enter)="handleSortingChange(col.key)"
                    >
                        <span>{{ col.name | translate }}</span>

                        @if(col.key === 'period') {
                        <i
                            class="material-icons sorting-arrow"
                            [ngClass]="{
                                asc: sortState['periodFrom'] === sortingStagesEnum.asc,
                                desc: sortState['periodFrom'] === sortingStagesEnum.desc
                            }"
                        >
                            arrow_drop_down
                        </i>
                        } @if(hasProp(col.key)) {
                        <i
                            class="material-icons sorting-arrow"
                            [ngClass]="{
                                asc: sortState[col.key] === sortingStagesEnum.asc,
                                desc: sortState[col.key] === sortingStagesEnum.desc
                            }"
                        >
                            arrow_drop_down
                        </i>
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let offer of resultList; index as index">
                    <tr
                        class="clickable"
                        [ngClass]="{
                            unseen: !offer.isSeen && isMro,
                            expired: offer.isExpired,
                            closed: !offer.isExpired && offer.isClosed
                        }"
                        (click)="handleRowExpand(offer, index)"
                        (keydown.enter)="handleRowExpand(offer, index)"
                    >
                        <td *ngFor="let col of tableConfig" [ngSwitch]="col.key" class="{{ col.key }}">
                            <span *ngSwitchCase="'ameType'">
                                {{ offer?.ameTitle | ameTitleName }}
                            </span>
                            <span *ngSwitchCase="'period'">
                                {{ offer?.periodFrom | absoluteDateFormat }} -
                                {{ offer?.periodTo | absoluteDateFormat }}
                            </span>
                            <span *ngSwitchCase="'startDate'">
                                {{ offer?.startDate | absoluteDateFormat }}
                            </span>
                            <span *ngSwitchCase="'vacancies'"
                                >{{ offer?.vacancies }}
                                <small class="text-muted">({{ offer?.fullyMatchedSize }} matches)</small>
                            </span>
                            <staffnow-offer-aircrafts-field
                                *ngSwitchCase="'aircraft'"
                                [offer]="offer"
                            ></staffnow-offer-aircrafts-field>
                            <span *ngSwitchCase="'agencyOfferAgencyName'">{{ offer?.agencyOfferAgencyName }}</span>
                            <span *ngSwitchCase="'mroName'">{{ offer?._embedded?.facility?.name }}</span>
                            <span *ngSwitchCase="'chargeRate'">
                                @if(!offer.hasHiddenRate) { @if(hasIndicativeRateForOffer(offer)) {
                                {{ offer?.chargeRate | rate }} -
                                {{ offer?.maxChargeRate | rate }}
                                } @else {
                                {{ offer?.chargeRate | rate }}
                                } } @else { - }
                            </span>
                            <span *ngSwitchCase="'minExperience'">
                                {{ offer?.minExperience }}+
                                <small class="text-muted">{{ '(year/s)' }}</small>
                            </span>
                            <span *ngSwitchCase="'payRate'">
                                @if(!offer.hasHiddenRate) { @if(hasIndicativeRateForOffer(offer)) {
                                {{ offer?.minPayRate | rate }} -
                                {{ offer?.maxPayRate | rate }}
                                } @else if (offer.payRate || offer.payRate?.amount === 0){
                                {{ offer?.payRate | rate }}
                                } @else {
                                {{ offer?.minPayRate | rate }} -
                                {{ offer?.maxPayRate | rate }}
                                } } @else { - }
                            </span>
                            <span *ngSwitchCase="'netSalary'">
                                @if(!offer.hasHiddenRate) {
                                {{ offer?.monthlySalary | rate }}
                                } @else { - }
                            </span>
                            <span *ngSwitchCase="'actions'">
                                @if(isMro && canAddTechnicians(offer)) {
                                <borderless-button
                                    [isDisabled]="!offer.isApproved"
                                    (onClick)="onAddTechnician(offer)"
                                    icon="person_add"
                                    title="{{ 'SYSTEM.INFO.ADD_TECHNICIANS_TOOLTIP' | translate }}"
                                ></borderless-button>
                                } @if( isMro && canInviteAgenciesTechnicians(offer)) {
                                <borderless-button
                                    [isDisabled]="!offer.isApproved"
                                    (onClick)="onInviteAgency(offer)"
                                    icon="add_business"
                                    title="{{ 'SYSTEM.INFO.INVITE_AGENCIES_TOOLTIP' | translate }}"
                                ></borderless-button>
                                } @if(canEditOffer(offer)) {
                                <borderless-button
                                    (onClick)="openJobOfferModal(offer)"
                                    icon="description"
                                    title="{{ 'SYSTEM.INFO.VIEW_FULL_JO_PROFILE' | translate }}"
                                ></borderless-button>
                                <borderless-button
                                    (onClick)="
                                        isTemporaryJobOpening(offer)
                                            ? openEditJobOfferForm(offer)
                                            : isAgencyJobOpening(offer)
                                            ? openEditAgencyOfferForm(offer)
                                            : isPermanentJobOpening(offer)
                                            ? openEditPermanentJobOpeningForm(offer)
                                            : openEditPackageOfferForm(offer)
                                    "
                                    icon="edit"
                                    title="{{ 'SYSTEM.INFO.EDIT_JO' | translate }}"
                                ></borderless-button>
                                } @if (canDeleteOffer(offer)) {
                                <borderless-button
                                    (onClick)="handleDeleteOffer(offer)"
                                    actionType="danger"
                                    icon="delete"
                                    title="{{ 'SYSTEM.INFO.DELETE_JO' | translate }}"
                                ></borderless-button>
                                }
                                <div class="visibility-action-buttons">
                                    @if(canClose(offer)) {
                                    <general-button
                                        (onClick)="closeOffer(offer)"
                                        backgroundStyle="outline"
                                        size="small"
                                        color="danger"
                                        text="Close for Applications"
                                    ></general-button>
                                    } @if (canReopen(offer)) {
                                    <general-button
                                        (onClick)="reopenOffer(offer)"
                                        backgroundStyle="outline"
                                        size="small"
                                        text="Reopen"
                                    ></general-button>
                                    } @if (canApproveOffer(offer)) {
                                    <general-button
                                        (onClick)="approveOffer(offer)"
                                        size="small"
                                        text="Approve"
                                    ></general-button>
                                    } @if(isFixedPriceJobOpening(offer) && canManageFixedPriceJobOpeningFiles(offer)) {
                                    <general-button
                                        (onClick)="openManageFixedPriceJobOpeningFilesModal(offer)"
                                        backgroundStyle="outline"
                                        size="small"
                                        text="Manage Files"
                                    ></general-button>
                                    }
                                </div>
                            </span>
                            <span *ngSwitchDefault>{{ offer[col.key] }}</span>
                        </td>
                    </tr>
                    @if(expandedRowIndex === index) {
                    <tr class="append">
                        <td class="append-td" [colSpan]="tableConfig.length">
                            <div
                                class="content-wrapper"
                                [ngClass]="{
                                    expandedRow: expandedRowIndex === index,
                                    collapsedRow: expandedRowIndex !== index
                                }"
                            >
                                <!--Expanded Temporary Job Offers-->
                                @if ( isTemporaryJobOpening(resultList[expandedRowIndex])) {
                                <ng-container>
                                    @if(isTechnician) {
                                    <offer-info-expand
                                        [effectiveRole]="effectiveRole"
                                        [offer]="getJobOfferAt(expandedRowIndex)"
                                        [contract]="expandedRowIndex === index ? offerContractsList[0] : null"
                                        (openPublicProfile)="onOpenPublicProfile($event)"
                                        [isLoading]="expandLoading"
                                        (acceptContract)="onAcceptOfferTechnician($event)"
                                    ></offer-info-expand>
                                    } @if(isMro || isAgency){
                                    <contracts-list-expand
                                        [offer]="getJobOfferAt(expandedRowIndex)"
                                        [effectiveRole]="effectiveRole"
                                        [contractsList]="offerContractsList"
                                        [isLoading]="expandLoading"
                                        [offerVacancies]="offer?.vacancies"
                                        [vacancyMatches]="offer?.fullyMatchedSize"
                                        [reasonsArray]="offer?._embedded?.reasons"
                                        (acceptContract)="onAcceptContract($event)"
                                        (openPublicProfile)="onOpenPublicProfile($event)"
                                        (setAgencyContract)="onSetContractAgency($event)"
                                    ></contracts-list-expand>
                                    } @if(canAddStaffies(offer)) {
                                    <div class="text-center">
                                        <span
                                            class="add-staffies-btn"
                                            (click)="onAddStaffie(offer)"
                                            (keydown.enter)="onAddStaffie(offer)"
                                        >
                                            + {{ 'add own employees' }}
                                        </span>
                                    </div>
                                    }
                                </ng-container>
                                }

                                <!--Expanded Permanent Job Offers-->
                                @if (isPermanentJobOpening(resultList[expandedRowIndex])) {
                                <ng-container>
                                    @if (isTechnician) {
                                    <offer-info-expand
                                        [effectiveRole]="effectiveRole"
                                        [offer]="getPermanentOfferAt(expandedRowIndex)"
                                        [contract]="expandedRowIndex === index ? offerContractsList[0] : null"
                                        (openPublicProfile)="onOpenPublicProfile($event)"
                                        [isLoading]="expandLoading"
                                        (acceptContract)="onAcceptOfferTechnician($event)"
                                    ></offer-info-expand>
                                    } @if(isMro) {
                                    <contracts-list-expand
                                        [offer]="getPermanentOfferAt(expandedRowIndex)"
                                        [effectiveRole]="effectiveRole"
                                        [contractsList]="offerContractsList"
                                        [isLoading]="expandLoading"
                                        [offerVacancies]="offer?.vacancies"
                                        [vacancyMatches]="offer?.fullyMatchedSize"
                                        [reasonsArray]="offer?._embedded?.reasons"
                                        (acceptContract)="onAcceptContract($event)"
                                        (openPublicProfile)="onOpenPublicProfile($event)"
                                        (setAgencyContract)="onSetContractAgency($event)"
                                    ></contracts-list-expand>
                                    }
                                </ng-container>

                                }
                                <!--Expanded Agency Offers-->
                                @if( isAgencyJobOpening(resultList[expandedRowIndex])) {
                                <ng-container>
                                    @if (isTechnician) {
                                    <agency-offer-info-expand
                                        [contract]="expandedRowIndex === index ? offerContractsList[0] : null"
                                        (openPublicProfile)="onOpenPublicProfile($event)"
                                        [isLoading]="false"
                                    >
                                    </agency-offer-info-expand>
                                    } @if(isAgency) {
                                    <agency-offer-contracts-list-expand
                                        [contractsList]="offerContractsList"
                                        [isLoading]="false"
                                        [offerVacancies]="offer?.vacancies"
                                        [vacancyMatches]="offer?.fullyMatchedSize"
                                        (openPublicProfile)="onOpenPublicProfile($event)"
                                    >
                                    </agency-offer-contracts-list-expand>
                                    }
                                </ng-container>

                                }

                                <!--Expanded Package Offers-->
                                @if(isFixedPriceJobOpening(resultList[expandedRowIndex])) {
                                <ng-container>
                                    <staffnow-package-contract-list-expand
                                        [offer]="getFixedPriceJobOpeningAt(expandedRowIndex)"
                                        [effectiveRole]="effectiveRole"
                                        [isLoading]="expandLoading"
                                    ></staffnow-package-contract-list-expand>
                                </ng-container>
                                }
                            </div>
                        </td>
                    </tr>
                    }
                </ng-container>

                @if (resultList.length === 0) {
                <tr>
                    <td class="text-center" [colSpan]="tableConfig.length">
                        <span>{{ 'SYSTEM.INFO.NO_RESULTS_SHOWN' | translate }}</span>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>

    <div class="overview-pagination">
        <pagination
            class="pagination"
            previousText="Prev"
            firstText="&laquo;"
            lastText="&raquo;"
            [maxSize]="5"
            [totalItems]="totalElements"
            [ngModel]="page"
            [itemsPerPage]="pageSize"
            [boundaryLinks]="true"
            (pageChanged)="pageChanged($event)"
        >
        </pagination>
    </div>
</div>
