import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';

import { AppComponent } from './app.component';
import { ManageContentModule } from './modules/manage-content/manage-content.module';
import { AppRoutingModule } from './app-routing.module';
import { UsersModule } from './modules/users/users.module';
import { getReducers, reducersInjectionToken } from './state/app.reducer';
import { AppState, initialState as appInitialState } from './state/app-state.model';
import { AppEffects } from './state/app.effects';
import { SharedModule } from './modules/shared/shared.module';
import { AdminRequestOverviewModule } from './modules/admin-request-overview/admin-request-overview.module';
import { ReportsModule } from './modules/reports/reports.module';
import { AgencyOrderingModule } from './modules/agency-ordering/agency-ordering.module';

//TODO(SN-969) temporary. remove after the backend accepts hal+json and use resourceFactory
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { MailboxModule } from '@libs/mailbox/mailbox.module';
import { NotificationsModule } from '@libs/notifications/notifications.module';
import { UserProfileModule } from '@libs/user-profile/user-profile.module';
import { getEnvironmentInitialState } from '@libs/shared/bms-common/environment/environment.loader';
import { BmsApiRootEffects } from '@libs/shared/bms-common/api-root/api-root.effects';
import { ReportsResolverService } from '@libs/shared/services/reports-resolver.service';
import { CreateOfferPageModule } from '@libs/create-offer-page/create-offer-page.module';
import { PackageOfferModule } from '@libs/package-offer/package-offer.module';
import { AuthenticationModule } from '@libs/auth/authentication.module';
import { CreateAgencyOfferPageModule } from '@libs/create-agency-offer-page/create-agency-offer-page.module';
import { BackofficeLoginModule } from './modules/backoffice-login/backoffice-login.module';
import { BackofficeHomeModule } from './modules/backoffice-home/backoffice-home.module';
import { DashboardsModule } from './modules/dashboards/dashboards.module';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { CreatePermanentOfferPageModule } from '../../../../libs/offer-management/create-permanent-offer/src/lib/create-permanent-offer-page.module';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { EnvironmentEffects } from '@libs/shared/bms-common/environment/environment.effects';

export function getInitialState(): Partial<AppState> {
  return {
    ...appInitialState,
    environment: getEnvironmentInitialState()
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ManageContentModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    CommonUiModule,
    SharedModule,
    AuthenticationModule.forRoot(),
    UserProfileModule,
    MailboxModule,
    UsersModule,
    BackofficeHomeModule,
    BackofficeLoginModule,
    ReportsModule,
    NotificationsModule,
    AdminRequestOverviewModule,
    AgencyOrderingModule,
    CreateOfferPageModule,
    CreatePermanentOfferPageModule,
    CreateAgencyOfferPageModule,
    PackageOfferModule,
    HttpClientModule, //TODO(SN-969) temporary. remove after the backend accepts hal+json and use resourceFactory
    StoreModule.forRoot(reducersInjectionToken, {
      initialState: getInitialState
    }),
    EffectsModule.forRoot([AppEffects, BmsApiRootEffects, EnvironmentEffects]),
    StoreDevtoolsModule.instrument({
      //TODO(SN-970): constant
      maxAge: 50,
      connectInZone: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    DashboardsModule
  ],

  providers: [
    { provide: reducersInjectionToken, useFactory: getReducers },
    ReportsResolverService,
    provideEnvironmentNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
