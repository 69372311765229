import { InjectionToken } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, createReducer, on } from '@ngrx/store';

import {
  AgenciesOrderingState,
  AppState,
  FacilityProfileState,
  initialState
} from './app-state.model';
import {
  AgencyOrderingListLoaded,
  AgencyOrderingResetState
} from './app.actions';
import { ManageContentActionTypes } from '../modules/manage-content/state/manage-content.actions';
import { cloneDeep, sortBy } from 'lodash-es';
import {
  UpdateAuthorizedUserInfo,
  UpdateAuthorizedUserPicture
} from '@libs/user-profile/state/user-profile.actions';
import { environmentReducer } from '@libs/shared/bms-common/environment/environment.reducer';
import { ApiRootState } from '@libs/shared/bms-common/api-root/api-root.model';
import {
  getAuthorizedProfileFromState,
  getEmbeddedResource,
  getLink,
  hasLink
} from '@libs/shared/bms-common/rest/resource.utils';
import { UserProfileLinkRel } from '@libs/shared/linkrels/user-profile.linkrel';
import { bmsApiRootReducer } from '@libs/shared/bms-common/api-root/api-root.reducers';
import { GlobalReset } from '@libs/shared/bms-common/api-root/api-root.actions';
import {
  ApprovedAgenciesLoaded,
  FacilityProfileLoaded,
  FailedToGetApprovedAgencies,
  FailedToGetMroRates,
  FailedToSubmitApprovedAgencies,
  GetApprovedAgencies,
  GetMroRates,
  MroApprovalsLoaded,
  MroRatesLoaded,
  ResetFacilityProfile,
  SubmitApprovedAgencies,
  SubmitMroRates
} from '@libs/common-ui/facility-profile/facility-profile.actions';

export function getReducers(): ActionReducerMap<AppState> {
  return {
    apiRoot: apiRootReducer,
    navigation: state => state,
    router: routerReducer,
    environment: environmentReducer,
    facilityProfile: facilityProfileReducer,
    agenciesOrdering: agenciesOrderingReducer
  };
}

export function agenciesOrderingReducer(
  state: AgenciesOrderingState,
  action
): AgenciesOrderingState {
  switch (action.type) {
    case AgencyOrderingListLoaded.type:
      return {
        ...state,
        notFeaturedAgencies: action.payload.filter(
          agency => agency.orderIndex === null
        ),
        featuredAgencies: action.payload.filter(
          agency => agency.orderIndex !== null
        )
      };
    case GlobalReset.type:
    case AgencyOrderingResetState.type:
      return initialState.agenciesOrdering;
  }

  return state;
}

const facilityProfileReducer = createReducer<FacilityProfileState>(
  initialState.facilityProfile,
  on(ResetFacilityProfile, GlobalReset, () => initialState.facilityProfile),
  on(FacilityProfileLoaded, (state, { payload }) => ({
    ...state,
    ...payload
  })),
  on(SubmitApprovedAgencies, GetApprovedAgencies, state => ({
    ...state,
    approvedAgenciesStatus: 'LOADING'
  })),
  on(SubmitMroRates, GetMroRates, state => ({
    ...state,
    mroRatesStatus: 'LOADING'
  })),
  on(FailedToGetApprovedAgencies, ApprovedAgenciesLoaded, state => ({
    ...state,
    approvedAgenciesStatus: 'IDLE'
  })),
  on(FailedToGetMroRates, MroRatesLoaded, state => ({
    ...state,
    mroRatesStatus: 'IDLE'
  })),
  on(FailedToSubmitApprovedAgencies, state => ({
    ...state,
    approvedAgenciesStatus: 'DIRTY'
  })),
  on(FailedToGetMroRates, state => ({
    ...state,
    mroRatesStatus: 'DIRTY'
  })),
  on(ApprovedAgenciesLoaded, (state, { payload }) => ({
    ...state,
    approvedAgencies: payload
  })),
  on(MroRatesLoaded, (state, { rates }) => ({
    ...state,
    rates
  })),
  on(MroApprovalsLoaded, (state, { payload }) => ({
    ...state,
    mroApprovals: payload
  }))
);

export function apiRootReducer(state: ApiRootState, action: any) {
  const updatedState = cloneDeep(state);
  switch (action.type) {
    case UpdateAuthorizedUserPicture.type:
      const authorizedUser = getAuthorizedProfileFromState(state);
      if (!hasLink(authorizedUser, UserProfileLinkRel.GetProfilePicture)) {
        return state;
      }
      const picLink = cloneDeep(
        getLink(authorizedUser, UserProfileLinkRel.GetProfilePicture)
      );
      if (picLink) {
        picLink.href = action.updatedPicUrl;
        getAuthorizedProfileFromState(updatedState)._links[
          UserProfileLinkRel.GetProfilePicture
        ] = picLink;
        return updatedState;
      }
      break;
    case UpdateAuthorizedUserInfo.type:
      const updatedProfile: any = getEmbeddedResource(
        action.payload,
        'profile'
      );
      updatedState.apiRoot._embedded.authorizedUserProfile['firstName'] =
        updatedProfile.firstName;
      updatedState.apiRoot._embedded.authorizedUserProfile['lastName'] =
        updatedProfile.lastName;

      return updatedState;
    case ManageContentActionTypes.UpdateAircraftList:
      updatedState.apiRoot._embedded.airplanes = [
        ...sortBy(action.aircraftList, ['name'])
      ];
      return updatedState;
    default:
      break;
  }
  return bmsApiRootReducer(state, action);
}

export const reducersInjectionToken = new InjectionToken<
  ActionReducerMap<AppState>
>('Registered Reducers');
