<div class="main">
    <h2 class="h2 text-center">
        <strong>
            {{ 'Manage ' + HUMAN_READABLE_ENTITIES[entity] + ' Accounts' | translate }}
        </strong>
    </h2>
    <div class="filter-controls-container">
        <staff-filters
            [ngClass]="{
                'filters-container': true,
                'two-lines': isDisplayingTechnicians
            }"
            [filterListConfig]="filtersConfig"
            (search)="onSearchUsers($event)"
            (enterPressed)="onSearchUsers($event)"
            (clear)="onClearFilters()"
        >
        </staff-filters>
    </div>
    <staffnow-table-listing
        [page]="currentPage"
        [pageSize]="pageSize"
        [usersList]="usersList"
        [aviationCompanies]="aviationCompanies"
        [configOption]="entity"
        [isLoading]="isLoading"
        (rowClicked)="onRowClicked($event)"
        (deleteUser)="onDeleteUser($event)"
        (deleteFacility)="onDeleteFacility($event)"
        (sortingTrigger)="onSortingTrigger($event)"
    >
    </staffnow-table-listing>
</div>
<pagination
    class="pagination justify-content-center"
    previousText="Prev"
    firstText="&laquo;"
    lastText="&raquo;"
    [maxSize]="15"
    [totalItems]="totalElements"
    [(ngModel)]="nextPage"
    [itemsPerPage]="pageSize"
    [boundaryLinks]="true"
    (pageChanged)="onPageChanged($event)"
>
</pagination>
