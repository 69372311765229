import {Component, Input, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {FormGroupState, SetValueAction} from 'ngrx-forms';
import {ActionsSubject, select, Store} from '@ngrx/store';

import {GetMroGroupsList, ResetFacilityRegistrationForm, SubmitFacilityRegistrationForm, UsersActionTypes} from '../../state/users.actions';
import {FEATURE_STATE_KEY} from '../../state/users.reducer';
import {MRO_REGISTRATION_FORM_ID, MroRegistrationStateModel} from './mro-registration-state.model';
import {filter} from 'rxjs/operators';
import {LocationPO} from "@libs/shared/models/location.model";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {getLocations} from "@libs/shared/bms-common/environment/environment.selector";
import {LoadLocationList} from "@libs/shared/bms-common/api-root/api-root.actions";

@UntilDestroy()
@Component({
  selector: 'mro-facility-registration',
  templateUrl: './mro-registration.component.html',
  styleUrls: ['./mro-registration.component.scss']
})
export class MroRegistrationComponent {
  @ViewChild('modalWindow') private modalWindow: any;

  @Input() mroGroupsList: Array<any> = [];

  public mroRegistrationForm: Observable<
    FormGroupState<MroRegistrationStateModel>
  >;
  public mroRegistrationFormValue = null;
  public isLoading = false;
  public groupValueConverter = {
    convertViewToStateValue: value => {
      return value ? value.id : '';
    },
    convertStateToViewValue: state => {
      return state ? state : '';
    }
  };
  public locationList: LocationPO[] = [];
  public selectedLocation: LocationPO = null;

  constructor(
    private store: Store<any>,
    private actionsSubject: ActionsSubject
  ) {
    this.store.dispatch(LoadLocationList());
    this.mroRegistrationForm = this.store.pipe(
      select(
        state =>
          <FormGroupState<MroRegistrationStateModel>>(
            state[FEATURE_STATE_KEY][MRO_REGISTRATION_FORM_ID]
          )
      ),
      untilDestroyed(this)
    );
    this.mroRegistrationForm.pipe(untilDestroyed(this)).subscribe(value => {
      this.mroRegistrationFormValue = value;
    });
    this.onActionType(UsersActionTypes.SuccessfullyRegistredFacility, () =>
      this.closeRegistrationForm()
    );
    this.onActionType(
      UsersActionTypes.FailedToRegisterNewFacility,
      () => (this.isLoading = false)
    );
    this.store.pipe(getLocations, untilDestroyed(this)).subscribe(locationList => {
      this.locationList = locationList;
    });
  }

  private onActionType(type: string, fn: () => void) {
    return this.actionsSubject
      .pipe(filter(action => action.type === type), untilDestroyed(this))
      .subscribe(fn);
  }

  public submitForm(): void {
    this.store.dispatch(
      new SubmitFacilityRegistrationForm(this.mroRegistrationFormValue.value)
    );
    this.isLoading = true;
  }

  public openRegistrationForm(): void {
    this.store.dispatch(new GetMroGroupsList());
    this.modalWindow.open();
  }

  public closeRegistrationForm(): void {
    this.store.dispatch(new ResetFacilityRegistrationForm());
    this.modalWindow.close();
    this.isLoading = false;
    this.selectedLocation = null;
  }

  public writeLocationIdToForm(): void {
    this.store.dispatch(
      new SetValueAction(
        this.mroRegistrationFormValue.controls.locationId.id,
        this.selectedLocation?.id
      )
    );
  }
}
