import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuardService } from '@libs/auth/services/authentication-guard.service';
import { NgModule } from '@angular/core';
import { DashboardsComponent } from './dashboards.component';
import { AgenciesDashboardPageComponent } from './agencies-dashboard-page/agencies-dashboard-page.component';
import { AviationDashboardPageComponent } from './aviation-dashboard-page/aviation-dashboard-page.component';
import { RejectionsDashboardPageComponent } from './rejections-dashboard-page/rejections-dashboard-page.component';
import { TechnicianDashboardPageComponent } from './technician-dashboard-page/technician-dashboard-page.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardsComponent,
    canActivate: [AuthenticationGuardService],
    children: [
      {
        path: '',
        redirectTo: 'rejections',
        pathMatch: 'full'
      },
      {
        path: 'technician',
        component: TechnicianDashboardPageComponent,
        canActivate: [AuthenticationGuardService]
      },
      {
        path: 'rejections',
        component: RejectionsDashboardPageComponent,
        canActivate: [AuthenticationGuardService]
      },
      {
        path: 'agency',
        component: AgenciesDashboardPageComponent,
        canActivate: [AuthenticationGuardService]
      },
      {
        path: 'aviation',
        component: AviationDashboardPageComponent,
        canActivate: [AuthenticationGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardsRoutingModule {}
