<div class="offer-info-container" *ngIf="!isLoading && contract">
    <div class="basic-info">
        <div>
            <borderless-button
                (onClick)="openJobOfferModal(offer)"
                icon="description"
                title="{{ 'SYSTEM.INFO.VIEW_FULL_JO_PROFILE' | translate }}"
            ></borderless-button>
        </div>

        <div>
            <label for="start-date">{{ 'Start date:' | translate }}</label>
            <span id="start-date">
                {{ contract?.startDate | absoluteDateFormat }}
            </span>
        </div>

        <div>
            <label for="end-date">{{ 'End date:' | translate }}</label>
            <span id="end-date">
                {{
                    contract?.endDate !== null ? contract?.endDate : (contract.provisionalEndDate | absoluteDateFormat)
                }}
            </span>
        </div>
    </div>
    <div *ngIf="isLoading || !contract"></div>
    <div class="contract-status" *ngIf="!isLoading && contract">
        <contract-status [contract]="contract" (openPublicProfile)="onOpenPublicProfile($event, contract)">
        </contract-status>
        <general-button
            id="new-agency-btn"
            *ngIf="shouldDisplayChooseNewAgency"
            (onClick)="onAcceptContract(contract, offer)"
            title="{{ 'SYSTEM.INFO.NEW_AGENCY_BUTTON' | translate }}"
            backgroundStyle="outline"
            text="Choose new agency"
        ></general-button>
    </div>
    <div class="rejected-reasons">
        <staffnow-reasons-list-item
            *ngIf="getPendingReason()"
            [reason]="getPendingReason()"
        ></staffnow-reasons-list-item>
        <staffnow-reasons-list-item
            *ngFor="let reason of contract.rejectedReasons"
            [reason]="reason"
        ></staffnow-reasons-list-item>
    </div>
    <div class="action-buttons">
        <general-button
            *ngIf="shouldDisplayAcceptButton"
            (onClick)="onAcceptContract(contract, offer)"
            size="small"
            text="ACCEPT"
        ></general-button>
        <general-button
            *ngIf="canDecline"
            (onClick)="onDeclineContractAsTechnician(contract)"
            title="{{ 'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate }}"
            size="small"
            backgroundStyle="outline"
            color="danger"
            text="DECLINE"
        ></general-button>
        <general-button
            *ngIf="canAcceptExtendedDate"
            (onClick)="acceptExtendedDate()"
            size="small"
            backgroundStyle="outline"
            text="ACCEPT EXTENSION"
        ></general-button>
        <general-button
            *ngIf="canDeclineExtendedDate"
            (onClick)="declineExtendedDate()"
            size="small"
            backgroundStyle="outline"
            color="danger"
            text="DECLINE EXTENSION"
        ></general-button>
    </div>
</div>
