import {Injectable} from "@angular/core";
import {AuthenticationService} from "@libs/auth/services/authentication.service";
import {Store} from "@ngrx/store";
import {pipe} from "rxjs";
import {getFilteredApiRoot} from "@libs/shared/bms-common/api-root/api-root.selectors";
import {AppRegionEnum} from "@libs/shared/bms-common/environment/environment.model";


@Injectable({providedIn: 'root'})
export class BackofficeFeatureAccessPolicyService {
  private apiRegion: string;
  private hasCrossRegionAccess: boolean = false;

  constructor(
    private store: Store<any>,
    private authenticationService: AuthenticationService
  ) {
    this.store.pipe(pipe(getFilteredApiRoot)).subscribe(apiRoot => {
      this.apiRegion = apiRoot.apiRegion;
      this.authenticationService.getIdentityClaims().then(claims => {
        this.hasCrossRegionAccess = this.canAccessBothRegions(claims.regions);
      });
    });
  }

  private canAccessBothRegions(regions: string[]): boolean {
    return (regions?.length == 2) && regions.includes(AppRegionEnum.EU) && regions.includes(AppRegionEnum.USA);
  }

  public isBackofficeEuropeOrHasCrossRegionAccess(): boolean {
    return (this.apiRegion == AppRegionEnum.EU) || this.hasCrossRegionAccess;
  }

}

