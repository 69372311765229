<div class="group-registration-wrapper">
    <h2 class="title">{{ 'Create new group' | translate }}</h2>
    <form [formGroup]="form">
        <div>
            <label>{{ 'Group Name' | translate }}</label>
            <input class="form-control" type="text" formControlName="name" />
        </div>
        <div class="custom-control custom-checkbox">
            <input
                id="threeMonthsRule"
                type="checkbox"
                class="custom-control-input"
                formControlName="isThreeMonthRuleAccepted"
            />
            <label for="threeMonthsRule" class="custom-control-label">{{
                'Three months rule' | translate
            }}</label>
        </div>
        <div class="text-center">
            <general-button
                (onClick)="close()"
                backgroundStyle="outline"
                class="mr-5"
                text="Close"
            ></general-button>
            <general-button
                (onClick)="createGroup()"
                [isDisabled]="!form.valid || !form.dirty"
                class="mx-auto"
                text="{{ isEdit ? 'Edit' : 'Create' }}"
            ></general-button>
        </div>
    </form>
</div>
