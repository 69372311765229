import {
  BrandName,
  EnvironmentParameters,
  staffNowPrivacyPolicyUrl,
  staffNowTermsAndConditionsUrl
} from '@libs/shared/bms-common/environment/environment.model';

export const environment: EnvironmentParameters = {
  production: false,
  environmentEndpoint: '/api/environment',
  usaApiUrl: 'https://api.demo.app.elaunchnow.com',
  europeApiUrl: 'https://api.demo.app.staffnow.aero',
  auth: {
    initialUrl: 'https://backoffice.demo.staffnow.aero',
    jwtIssuer: 'https://keycloak.test.app.staffnow.aero/realms/staffnow-demo',
    keycloakLoginUrl:
      'https://keycloak.test.app.staffnow.aero/realms/staffnow-demo/protocol/openid-connect/token',
    clientId: 'staffnow-demo-login'
  },
  brandConfig: {
    brandName: BrandName.StaffNow,
    privacyPolicy: staffNowPrivacyPolicyUrl(),
    termsAndConditions: staffNowTermsAndConditionsUrl()
  },
  euDashboardsEndpoint:
    'https://2bwibmse1e.execute-api.eu-west-1.amazonaws.com/default/reports/',
  usaDashboardsEndpoint:
    'https://p0840gxrni.execute-api.us-east-1.amazonaws.com/default/reports/'
};
