import {Component} from '@angular/core';
import {select, Store} from '@ngrx/store';

import {AppState} from './state/app-state.model';
import {cloneDeep} from 'lodash-es';
import {AuthenticationService} from '@libs/auth/services/authentication.service';
import {getFilteredApiRoot} from '@libs/shared/bms-common/api-root/api-root.selectors';
import {getEmbeddedResource, hasLink} from '@libs/shared/bms-common/rest/resource.utils';
import {ApiRootLinkRel} from '@libs/shared/linkrels/api-root.linkrel';
import {AuthorizedUserProfileModel} from '@libs/shared/models/authorized-user-profile.model';
import {ApiRootResource} from '@libs/shared/bms-common/api-root/api-root.model';
import {NotificationsCount, selectNotificationsCount} from '@libs/notifications/state/notifications.selectors';
import {CustomNavigationService} from '@libs/shared/services/custom-navigation.service';
import {getStaticEnvironment} from '@libs/shared/bms-common/environment/environment.selector';
import {Actions, ofType} from '@ngrx/effects';
import {ReloadApiRoot} from '@libs/shared/bms-common/api-root/api-root.actions';
import {selectAuthState} from '@libs/auth/state/authentication.selectors';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {buildInfo} from "../info/import-extra-json";
import {HeaderProviderService} from "@libs/auth/services/header-provider.service";
import {BackofficeNavbarService} from "@back-office/shared/services/backoffice-navbar/backoffice-navbar.service";
import {MenuItem} from "@libs/shared/models/menu-item.model";
import { TranslateService } from '@ngx-translate/core';
import { AppRegionEnum } from '@libs/shared/bms-common/environment/environment.model';

@UntilDestroy()
@Component({
  selector: 'staffnow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isAppLoading: boolean = true;
  public isAsideMenuOpen: boolean = true;
  public authorizedUserProfile: AuthorizedUserProfileModel = null;
  public menuItems: MenuItem[] = [];
  public version: string = null;
  public bellNotificationsCount: number = null;
  public mailboxNotificationsCount: number = null;
  public notificationListOpened: boolean = false;
  private apiRoot: ApiRootResource = null;

  private isAuthenticated: boolean = undefined;
  private googleRecaptchaSiteKey: string = undefined;
  private grecaptchaLoaded: boolean = false;

  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private authenticationService: AuthenticationService,
    private customNavigationService: CustomNavigationService,
    private headerProviderService: HeaderProviderService,
    private navbarService: BackofficeNavbarService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.headerProviderService.appVersion = buildInfo.appVersion;
    this.headerProviderService.appClass = buildInfo.appClass;
    this.version = buildInfo.appVersion;
    this.customNavigationService.init();
    this.store.pipe(getStaticEnvironment).subscribe(envData => {
      this.googleRecaptchaSiteKey = envData.googleRecaptchaSiteKey;
      this.authenticationService.initForBackoffice();
    });

    this.actions.pipe(ofType(ReloadApiRoot)).subscribe(() => {
      this.isAppLoading = true;
      this.apiRoot = null;
      this.menuItems = [];
      this.authorizedUserProfile = null;
    });

    this.store.pipe(getFilteredApiRoot).subscribe(apiRoot => {
      this.isAppLoading = false;
      this.translateService.use(apiRoot.apiRegion == AppRegionEnum.EU ? 'en' : 'en-us');
      this.apiRoot = cloneDeep(apiRoot);
      this.menuItems = this.navbarService.convertRoutesToMenuItems(this.apiRoot._links)
      this.authorizedUserProfile = getEmbeddedResource(
        this.apiRoot,
        ApiRootLinkRel.AuthorizedUserProfile
      );
    });

    this.store
      .pipe(select(selectAuthState), untilDestroyed(this))
      .subscribe(authState => {
        this.isAuthenticated = authState.validTokenReceived;
        this.loadGrecaptchaIfAllVariablesAreInitialized();
        if (authState.validTokenReceived === false) {
          this.isAppLoading = false;
        }
      });

    this.store
      .pipe(selectNotificationsCount)
      .subscribe((notificationsCount: NotificationsCount) => {
        this.bellNotificationsCount = notificationsCount.bellNotificationsCount;
        this.mailboxNotificationsCount =
          notificationsCount.mailboxNotificationsCount;
      });
  }

  get hasMailboxLink(): boolean {
    return hasLink(this.apiRoot, ApiRootLinkRel.Mailbox);
  }

  public logoutUser(): void {
    this.authenticationService.logout();
  }

  public toggleAsideMenu(): void {
    this.isAsideMenuOpen = !this.isAsideMenuOpen;
  }

  public toggleNotificationList() {
    this.notificationListOpened = !this.notificationListOpened;
  }

  private loadGrecaptchaIfAllVariablesAreInitialized() {
    if (
      !this.grecaptchaLoaded &&
      this.isAuthenticated === false &&
      !!this.googleRecaptchaSiteKey
    ) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.googleRecaptchaSiteKey}`;
      document.head.appendChild(script);
      this.grecaptchaLoaded = true;
    }
  }
}
