<div class="bo-container manage-content-container">
    <tab-navigation
        [menuItems]="MANAGE_CONTENT_TABS"
        [(ngModel)]="activeTab"
    ></tab-navigation>

    <staffnow-aircraft-list-bo
        *ngIf="aircraftIsActiveTab"
    ></staffnow-aircraft-list-bo>
    <staffnow-language-list-bo
        *ngIf="languagesIsActiveTab"
    ></staffnow-language-list-bo>
    <staffnow-location-list-bo
        *ngIf="locationsIsActiveTab"
    ></staffnow-location-list-bo>
    <staffnow-title-management
        *ngIf="titlesIsActiveTab"
    ></staffnow-title-management>
    <staffnow-personal-documents
        *ngIf="personalDocumentsIsActiveTab"
    ></staffnow-personal-documents>
    <staffnow-title-documents-management
        *ngIf="titleDocumentsConfigIsActiveTab"
    ></staffnow-title-documents-management>
    <staffnow-slideshow *ngIf="slideshowIsActiveTab"></staffnow-slideshow>
    <staffnow-faq-bo *ngIf="faqIsActiveTab"></staffnow-faq-bo>
</div>
