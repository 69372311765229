import { Component, Input, OnChanges } from '@angular/core';
import { groupBy, sortBy } from 'lodash-es';
import { FullTitleDtoReadOnly } from '../../../state/full-title-dto.model';

@Component({
  selector: 'staffnow-title-list-backoffice',
  templateUrl: './title-list-backoffice.component.html',
  styleUrls: ['./title-list-backoffice.component.scss']
})
export class TitleListBackofficeComponent implements OnChanges {
  @Input() public label: string = null;
  @Input() public titles: FullTitleDtoReadOnly[] = [];

  public amountOfTitles: number = 0;
  public groupedTitles: Array<{
    type: string;
    titles: FullTitleDtoReadOnly[];
  }> = [];

  ngOnChanges(): void {
    if (this.titles.length > 0) {
      this.amountOfTitles = this.titles.length;
      const groupedTitles = groupBy(this.titles, title => title.type);
      this.groupedTitles = sortBy(
        Object.keys(groupedTitles).map(type => ({
          type,
          titles: sortBy(groupedTitles[type], ['isLufthansa', 'europeLicense'])
        })),
        ['type']
      );
    }
  }
}
