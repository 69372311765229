import { Component } from '@angular/core';
import { DASHBOARDS_MENU_ITEMS } from '@libs/shared/constants/dashboardMenuItems.constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'staffnow-agencies-dashboard-page',
  templateUrl: './agencies-dashboard-page.component.html',
  styleUrls: ['./agencies-dashboard-page.component.scss']
})
export class AgenciesDashboardPageComponent {
  get dashboardUrl(): string {
    return DASHBOARDS_MENU_ITEMS.AGENCIES.url;
  }

  get environment(): any {
    return environment;
  }
}
