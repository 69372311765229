import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { cloneDeep, map, pickBy } from 'lodash-es';
import {
  BackendReportConfig,
  BackofficeReportConfig,
  getReportConfig,
  isReportAvailable,
  orderReportList,
  PlatformReport,
  ReportConfig
} from '@libs/shared/models/reports.model';
import { monthlyDateRangeConverter } from '@libs/shared/helpers/date-utils';
import { MENU_ITEMS_ENUM } from '@back-office/shared/config/menuItems.enum';
import { ReportsState } from '../../state/reports-state.model';
import { REPORTS_FEATURE_KEY } from '../../state/reports.reducer';
import { GenerateReportBO, LoadAgenciesList, LoadMroGroupsList, ReportsActionTypes } from '../../state/reports.actions';
import { ReportsHelper } from '@libs/shared/helpers/reports.helper';
import { AppRegionEnum } from '@libs/shared/bms-common/environment/environment.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RegionSelectorService } from '@libs/auth/services/region-selector.service';

@UntilDestroy()
@Component({
  selector: 'staffnow-backoffice-reports',
  templateUrl: './reports-backoffice-list.component.html',
  styleUrls: ['./reports-backoffice-list.component.scss']
})
export class ReportsBackofficeListComponent implements OnChanges, OnDestroy {
  @Input() public entity?: string;

  allReports: { [key: string]: BackendReportConfig } = null;
  reportsBackoffice: Array<BackofficeReportConfig> = [];
  MENU_ITEMS_ENUM = MENU_ITEMS_ENUM;
  isLoading: boolean = false;
  reportsState: ReportsState = null;
  getReportTitle = (report: PlatformReport) => report.type;
  getReportKey = (report: PlatformReport) => report?.key;
  selectedBackofficeReportKey: string = null;
  private subscription: Subscription = new Subscription();
  private selectedRegion: string = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private regionSelectorService: RegionSelectorService
  ) {
    this.subscription.add(
      this.activatedRoute.data.subscribe(data => {
        this.allReports = cloneDeep(data.links);
      })
    );

    this.setupLoadingSubs();

    this.subscription.add(
      this.store
        .pipe(select(state => state[REPORTS_FEATURE_KEY]))
        .subscribe(reportsState => (this.reportsState = reportsState))
    );

    this.regionSelectorService
      .getSubject()
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(region => {
        this.selectedRegion = region;
      });
  }

  ngOnChanges() {
    this.setSelectedReport(null);
    this.generateAvailableReportsByEntity();
    switch (this.entity) {
      case MENU_ITEMS_ENUM.MROS:
        this.store.dispatch(new LoadMroGroupsList());
        break;
      case MENU_ITEMS_ENUM.AGENCIES:
        this.store.dispatch(new LoadAgenciesList());
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get isBackofficeTab(): boolean {
    return this.entity === this.MENU_ITEMS_ENUM.BACKOFFICE.toString();
  }

  get selectedBackofficeReport(): BackofficeReportConfig {
    const report = this.reportsBackoffice.find(r => r.key === this.selectedBackofficeReportKey);
    return report?.isGroupReport ? null : report;
  }

  setSelectedReport(key: string): void {
    this.selectedBackofficeReportKey = key;
  }

  private setupLoadingSubs(): void {
    this.subscription.add(
      this.actionsSubject
        .pipe(filter(action => action.type === ReportsActionTypes.GenerateReportBO.toString()))
        .subscribe(() => (this.isLoading = true))
    );
    this.subscription.add(
      this.actionsSubject
        .pipe(
          filter(action => {
            const actions: string[] = [ReportsActionTypes.ReportGenerated, ReportsActionTypes.FailedToGenerateReportBO];
            return actions.includes(action.type);
          })
        )
        .subscribe(() => (this.isLoading = false))
    );
  }

  // Generate report in 'back-office' functionality ________________________________________________________________
  generateReportByEntity(report: BackofficeReportConfig): void {
    report.range = report.isMonthly
      ? monthlyDateRangeConverter(report.range as string)
      : ReportsHelper.dateRangeConverter(report.range as Array<any>);
    this.store.dispatch(new GenerateReportBO(this.preparePayloadObj(report)));
  }

  generateAvailableReportsByEntity(): void {
    const currentEntity = this.entity;
    const filteredReports = pickBy(this.allReports, (_, key) => isReportAvailable(key, currentEntity));
    this.reportsBackoffice = orderReportList(
      map(filteredReports, (report, key) => {
        const reportConfig: ReportConfig = getReportConfig(key);
        const showAviationGroupInForm = reportConfig.backofficeCanSelectMroGroup && !reportConfig.canSelectMroFacility;
        return {
          ...report,
          ...reportConfig,
          range: [],
          uuid: null,
          loadGroup: showAviationGroupInForm
        };
      })
    );
  }

  private getAvailableReport(key: string): BackofficeReportConfig {
    return this.reportsBackoffice.find(r => r.key === key);
  }

  // Helper functions  ___________________________________________________________________________________________

  getUserListReport(): BackofficeReportConfig {
    return this.reportsBackoffice.find(r => r.key === 'userList');
  }

  getAllReportsExceptUserList(): Array<BackofficeReportConfig> {
    return this.reportsBackoffice.filter(r => r.key !== 'userList');
  }

  private preparePayloadObj(report: any): any {
    const payloadObj: any = {
      href: report.href,
      range: report.range,
      id: {
        uuid: report.uuid
      },
      platform: report.includePlatformInUrl ? this.platform() : null
    };
    if (report.loadGroup) {
      payloadObj.href = this.getAvailableReport(report.key.replace('mro', 'group')).href;
      payloadObj.id = { groupId: report.uuid };
    }
    return payloadObj;
  }

  private platform(): string {
    if (this.selectedRegion === AppRegionEnum.EU.toString()) return 'STAFFNOW';
    return 'ELAUNCHNOW';
  }
}
