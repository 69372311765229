<div class="contracts-list-container">
    <div *ngIf="isLoading" class="no-applicants">
        <staff-loader
            *ngIf="isLoading"
            [customClass]="'splash-screen-loader'"
        ></staff-loader>
    </div>
    <div *ngIf="!isLoading && contractsList.length > 0" class="col-titles">
        <span class="col-name">{{ 'Name' | translate }}</span>
        <span class="col-name">{{ 'Start Date' | translate }}</span>
        <span class="col-name">{{ 'End Date' | translate }}</span>
        <span class="col-name">{{ 'Contract status' | translate }}</span>
        <span class="col-name">
            {{ 'Reject/Pending Reasons or Acceptance Notes' | translate }}
        </span>
        <span class="col-actions">{{ 'Actions' | translate }}</span>
    </div>
    <div *ngIf="!isLoading && contractsList.length > 0" class="applicants-list">
        <div
            *ngFor="let contract of contractsList; let i = index"
            class="position-relative"
        >
            <div
                [ngClass]="{
                    expired: contract.isExpired,
                    finished: contract.isFinished
                }"
                class="single-contract-row"
            >
                <!-- TECHNICIAN COLUMN -->
                <div class="technician-info">
                    <div
                        *ngIf="contract?._links?.profilePicture"
                        [ngStyle]="{
                            'background-image':
                                'url(\'' +
                                contract?._links?.profilePicture.href +
                                '\')'
                        }"
                        class="profile-pic"
                    ></div>
                    <div
                        *ngIf="!contract?._links?.profilePicture"
                        class="profile-pic"
                    >
                        <i class="material-icons"> person </i>
                    </div>
                    <span class="h6">
                        {{ contract?._embedded?.profile?.firstName }}
                        {{ contract?._embedded?.profile?.lastName }}
                    </span>
                </div>
                <!-- START DATE COLUMN -->
                <span class="start-date">
                    <span *ngIf="!isPendingOrUnset(contract.startDatePhase)">
                        {{ contract.startDate | absoluteDateFormat }}
                    </span>
                    <span
                        *ngIf="isPendingOrUnset(contract.startDatePhase)"
                        class="text-muted"
                    >
                        {{ 'Pending proposal' | translate }}
                    </span>
                    <span
                        *ngIf="isProposed(contract.startDatePhase)"
                        class="text-muted"
                        [innerHtml]="
                            getProposedByLabel(contract.startDatePhase)
                                | translate
                        "
                    >
                    </span>
                </span>
                <!-- END DATE COLUMN -->
                <span class="end-date">
                    {{
                        contract.endDate || contract.provisionalEndDate
                            | absoluteDateFormat
                    }}
                </span>
                <!-- CONTRACT STATUS COLUMN -->
                <contract-status
                    (openPublicProfile)="onOpenPublicProfile($event, contract)"
                    [contract]="contract"
                >
                </contract-status>
                <!-- REASONS COLUMN -->
                <div class="rejected-or-pending-or-acceptance-reasons">
                    <span *ngIf="getAcceptanceNotes(contract)">
                        {{ getAcceptanceNotes(contract) }}
                    </span>
                    <staffnow-reasons-list-item
                        *ngIf="getPendingReason(contract)"
                        [reason]="getPendingReason(contract)"
                    ></staffnow-reasons-list-item>
                    <staffnow-reasons-list-item
                        *ngFor="let reason of contract.rejectedReasons"
                        [reason]="reason"
                    ></staffnow-reasons-list-item>
                </div>

                <!-- ACTIONS COLUMN -->
                <div class="action-buttons" *ngIf="!contract.isFinished">
                    <general-button
                        *ngIf="shouldDisplayAcceptButton(contract)"
                        [isDisabled]="isAlreadyMroAccepted(contract)"
                        (onClick)="acceptContractMro(contract)"
                        size="small"
                        text="ACCEPT"
                    ></general-button>
                    <general-button
                        *ngIf="shouldDisplayDeclineButton(contract)"
                        [isDisabled]="isAlreadyMroRejected(contract)"
                        (onClick)="onDeclineContractAsMro(contract)"
                        title="{{'SYSTEM.INFO.DECLINE_JOB_OPENING' | translate}}"
                        size="small"
                        backgroundStyle="outline"
                        color="danger"
                        text="DECLINE"
                    ></general-button>
                    <general-button
                        *ngIf="canMarkAsDocumentsNeeded(contract)"
                        [isDisabled]="isAlreadyMroRejected(contract)"
                        (onClick)="onDocumentsNeededMro(contract)"
                        title="{{'SYSTEM.INFO.DOCUMENTS_NEEDED' | translate}}"
                        size="small"
                        backgroundStyle="outline"
                        color="dark"
                        text="MORE DOCS"
                    ></general-button>
                    <general-button
                        *ngIf="backofficeCanResetContractStatus(contract)"
                        (onClick)="resetContractStatus(contract)"
                        title="Set the selected role status to pending"
                        size="small"
                        backgroundStyle="outline"
                        color="danger"
                        text="RESET STATUS"
                    ></general-button>
                    <general-button
                        *ngIf="backofficeCanSetAgency(contract)"
                        (onClick)="setAgencyForContract(contract, offer)"
                        title="Set the agency for this contract"
                        size="small"
                        backgroundStyle="outline"
                        color="dark"
                        text="SET AGENCY"
                    ></general-button>
                    <div *ngIf="isAgency" class="decline-as-agency-button">
                        <span
                            *ngIf="!isDeclineDateLimitReached(contract) && canDecline(contract)"
                            title="{{'SYSTEM.INFO.AGENCY_DECLINE_DATE' | translate}}"
                        >
                            {{ contract.agencyDeclineDateLimit | localDateWithTimeFormat }}
                        </span>
                        <general-button
                            *ngIf="canDecline(contract) && !isRejected(contract.agencyStatus)"
                            (onClick)="onDeclineContractAsAgency(contract)"
                            [isDisabled]="isDeclineDateLimitReached(contract)"
                            title="{{(isDeclineDateLimitReached(contract)
                                    ? 'SYSTEM.INFO.DISABLED_AGENCY_DECLINE_BUTTON'
                                    : 'SYSTEM.INFO.DECLINE_JOB_OPENING') | translate}}"
                            size="small"
                            backgroundStyle="outline"
                            color="danger"
                            text="DECLINE"
                        ></general-button>
                    </div>
                    <general-button
                        *ngIf="canEnd(contract) && isMro()"
                        (onClick)="handleContractEnd(contract)"
                        [isDisabled]="!canEnd(contract)"
                        title="{{'SYSTEM.INFO.END_JO' | translate}}"
                        size="small"
                        backgroundStyle="outline"
                        color="secondary"
                        text="END"
                    ></general-button>
                    <general-button
                        *ngIf="hasAcceptStartDateLink(contract)"
                        (onClick)="acceptStartDate(contract)"
                        size="small"
                        backgroundStyle="outline"
                        color="primary"
                        text="ACCEPT START DATE"
                    ></general-button>
                    <general-button
                        *ngIf="hasProposeStartDateLink(contract)"
                        (onClick)="handleStartDateProposal(contract)"
                        size="small"
                        backgroundStyle="outline"
                        color="{{datePhaseIsAccepted(contract) ? 'secondary' : 'primary'}}"
                        text="PROPOSE START DATE"
                    ></general-button>
                </div>
            </div>
        </div>
        <div *ngIf="offerVacancies !== null" class="extra-row">
            <i class="material-icons"> people </i>
            <small class="text-muted">
                {{
                    offerVacancies - vacancyMatches >= 0
                        ? offerVacancies - vacancyMatches
                        : 0
                }}
                opened position/s left
            </small>
        </div>
    </div>
    <div *ngIf="!isLoading && contractsList.length === 0" class="no-applicants">
        <h1 class="h1">
            {{ 'There are no applicants for this request yet.' | translate }}
        </h1>
    </div>
</div>
