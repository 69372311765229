export interface ManageContentGuideline {
  action: string;
  description: string;
}

export const ManageDocumentsGuidelines: ManageContentGuideline[] = [
  {
    action: 'Enable/Disable a document type',
    description: 'mark/unmark the checkbox next to the document title, respectively. Disabled documents will NOT be showed to technicians.'
  },
  {
    action: 'Require/Not Require expiration date',
    description: 'mark/unmark "Expiration date is required" checkbox, respectively.'
  },
  {
    action: 'Require/Not Require a document type',
    description: 'mark/unmark "Is Mandatory" checkbox, respectively. This will affect the completeness check for technician profiles, but already verified technicians will NOT become incomplete.'
  }
];
