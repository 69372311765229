import { createFormGroupState, FormGroupState } from 'ngrx-forms';

export const MRO_REGISTRATION_FORM_ID = 'mroRegistrationForm';

export interface MroRegistrationStateModel {
  name: string;
  locationId: number;
  groupId?: string;
}

export interface MroRegistrationFormState
  extends FormGroupState<MroRegistrationStateModel> {}

export const initialMroFormState: MroRegistrationFormState = createFormGroupState<MroRegistrationStateModel>(
  MRO_REGISTRATION_FORM_ID,
  {
    name: '',
    locationId: null,
    groupId: ''
  }
);
