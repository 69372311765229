<div class="title-selector-wrapper">
    <div class="form-group title-selector">
        <label for="title-selector"> Technician Title </label>
        <div id="title-selector" class="title-selector">
            <ng-select
                bindLabel="name"
                [items]="availableTitles"
                [(ngModel)]="selectedTitle"
                (ngModelChange)="handleTitleChange($event)"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [placeholder]="'Select a Technician Title' | translate"
            ></ng-select>
        </div>
    </div>
</div>
<div class="guidelines">
    <p class="guidelines">
        Here you can configure the available document types for technicians
        depending on the title they have. The available actions are:
    </p>
    <ul>
        <li *ngFor="let guideline of documentsGuidelines">
            <strong>{{ guideline.action }}:</strong> {{ guideline.description }}
        </li>
    </ul>
</div>
<div class="documents-selection-form">
    <ng-container *ngIf="!selectedTitle; else documentAreasList">
        <p><strong>No title was selected!</strong></p>
    </ng-container>
    <ng-template #documentAreasList>
        <div *ngFor="let documentArea of optionsForCurrentTitle;last as isLastArea">
            <div class="document-area">
                <staffnow-document-types-list
                    [documentAreaTitle]=documentArea.title
                    [documentTypes]=documentArea.types
                ></staffnow-document-types-list>
            </div>
            <div class="horizontal-divider" *ngIf="!isLastArea"></div>
        </div>
        <general-button
            *ngIf="optionsForCurrentTitle.length > 0"
            text="Save Changes"
            [isLoading]="isLoading"
            [isDisabled]="isLoading"
            (onClick)="submit()"
        ></general-button>
    </ng-template>
</div>
