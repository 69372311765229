import { Component, Input } from '@angular/core';
import { AbstractExpandComponent } from '../abstract-expand.component';
import { addUtils, Contract } from '@libs/shared/models/contract.model';
import { hasLink } from '@libs/shared/bms-common/rest/resource.utils';
import { RequestOverviewLinkRel } from '@libs/shared/linkrels/request-overview.linkrel';
import { ExtendedPendingReason } from '@libs/shared/models/reasons.model';
import {
  AcceptDateExtension,
  DeclineContract,
  DeclineDateExtension
} from '@libs/request-overview-common/state/requests-overview.actions';
import { Store } from '@ngrx/store';
import { AgencyOfferOutDto, isAgencyOffer, OfferOutDto } from '@libs/shared/models/offer.model';
import { OfferModalComponent } from '@libs/common-ui/offer-modal/offer-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'offer-info-expand',
  templateUrl: './offer-info-expand.component.html',
  styleUrls: ['./offer-info-expand.component.scss']
})
export class OfferInfoExpandComponent extends AbstractExpandComponent {
  @Input() public contract: Contract = null;
  @Input() public isLoading: boolean;

  constructor(private store: Store<any>, private bsModalService: BsModalService) {
    super();
  }

  get shouldDisplayChooseNewAgency(): boolean {
    return this.isAcceptOfferPossible() && this.agencyHasRejected();
  }

  get shouldDisplayAcceptButton(): boolean {
    return this.isAcceptOfferPossible() && !this.agencyHasRejected();
  }

  private isAcceptOfferPossible(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptContract);
  }

  private agencyHasRejected(): boolean {
    return this.contract.agencyStatus === 'REJECTED';
  }

  get canDecline(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineContract);
  }

  get canAcceptExtendedDate(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.AcceptDateExtension);
  }

  get canDeclineExtendedDate(): boolean {
    return hasLink(this.contract, RequestOverviewLinkRel.DeclineDateExtension);
  }

  public onDeclineContractAsTechnician(contract: Contract): void {
    this.store.dispatch(DeclineContract({ contract }));
  }

  public getPendingReason(): ExtendedPendingReason {
    const contractWithUtils = addUtils(this.contract);
    return contractWithUtils.hasPendingReasons()
      ? {
          ...contractWithUtils.getPendingReason(),
          name: 'More Documents Needed'
        }
      : null;
  }

  public acceptExtendedDate(): void {
    this.store.dispatch(AcceptDateExtension({ contract: this.contract }));
  }

  public declineExtendedDate(): void {
    this.store.dispatch(DeclineDateExtension({ contract: this.contract }));
  }

  canEditOffer(offer: OfferOutDto | AgencyOfferOutDto): boolean {
    return this.isAgencyJobOpening(offer)
      ? hasLink(offer, RequestOverviewLinkRel.EditAgencyOffer)
      : hasLink(offer, RequestOverviewLinkRel.Edit);
  }

  isAgencyJobOpening(offer): boolean {
    return isAgencyOffer(offer);
  }

  openJobOfferModal(data): void {
    this.bsModalService.show(OfferModalComponent, {
      ignoreBackdropClick: true,
      class: 'job-offer-modal',
      initialState: {
        offer: data
      }
    });
  }
}
