import { Action, createAction, props } from '@ngrx/store';

import { RegistrationFormModel } from '../components/registration/registration-form.model';
import { UsersFilterValueModel, UsersListingModel } from './users-state.model';

export enum UsersActionTypes {
  SubmitRegistrationForm = '[Users] Submit User Registration Form',
  SuccessfulUserRegistration = '[Users] User was successfully registered',
  FailedUserRegistration = '[Users] Failed to register user',
  GetEntitiesList = '[Users] Get list of users',
  FailedToGetUsersList = '[Users] Failed to get list of users',
  UsersListReceived = '[Users] List of users received',
  ResetRegistrationForm = '[Users] Reset registration form',
  UpdateFilters = '[Users] Update filters value',
  ResetFilters = '[Users] Reset filters',
  ResetState = '[Users] Reset state keys',
  ResetFacilityRegistrationForm = '[User] Reset facility registration form',
  SubmitFacilityRegistrationForm = '[Users] Submit Facility Registration Form',
  SuccessfullyRegistredFacility = '[Users] Successfully registered new facility',
  FailedToRegisterNewFacility = '[Users] Failed to register new facility',
  GetPageOfFacilities = '[Users] Get page of facilities',
  FacilitiesListReceived = '[Users] List of facilities received',
  LoadMoreFacilitiesReceived = '[Users] Load more facilities received',
  FailedToGetFacilities = '[User] Failed to get facilities',
  CreateNewGroup = '[User] Create new group',
  FailedToCreateNewGroup = '[User] Failed to create a group',
  SuccessCreatingNewGroup = '[User] Successfully created new group',
  EditGroup = '[User] Edit group',
  LoadMoreFacilities = '[User] Load More Facilities',
  FailedToEditNewGroup = '[User] Failed to edit a group',
  SuccessEditingGroup = '[User] Successfully edited group',
  GetMroGroupsList = '[User] Get all MRO groups',
  MroGroupsListLoaded = '[User] MRO groups loaded',
  FailedToLoadMROGroups = '[User] Failed to load MRO groups',
  SubmitDeleteUser = '[User] Submit user delete',
  SuccessfullyDeletedUser = '[User] Successfully deleted user',
  FailedDeleteUser = '[User] Failed delete user'
}

export class GetPageOfFacilities implements Action {
  public readonly type = UsersActionTypes.GetPageOfFacilities;
  public readonly payload: {page: number, term: string} = null;
  public readonly entity?: string = '';


  constructor(payload: {page: number, term: string}, entity: string) {
    this.entity = entity;
    this.payload = payload;
  }
}

export class LoadMoreFacilities implements Action {
  public readonly type = UsersActionTypes.LoadMoreFacilities;
  public readonly entity?: string = '';

  constructor(entity: string) {
    this.entity = entity;
  }
}

export class FacilitiesListReceived implements Action {
  public readonly type = UsersActionTypes.FacilitiesListReceived;
  public payload = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class LoadedMoreFacilitiesReceived implements Action {
  public readonly type = UsersActionTypes.LoadMoreFacilitiesReceived;
  public payload = null;

  constructor(payload: UsersListingModel) {
    this.payload = payload;
  }
}

export class FailedToGetFacilities implements Action {
  public readonly type = UsersActionTypes.FailedToGetFacilities;
}

export class ResetFilters implements Action {
  public readonly type = UsersActionTypes.ResetFilters;
}

export class ResetState implements Action {
  public readonly type = UsersActionTypes.ResetState;
}

export class GetEntitiesList implements Action {
  public readonly type = UsersActionTypes.GetEntitiesList;
  public readonly payload: any = null;
  public readonly entity?: string = '';
  public readonly sortingQuery?: string = '';

  constructor(payload: any, entity?: string, sortingQuery?: string) {
    this.payload = payload;
    this.entity = entity;
    this.sortingQuery = sortingQuery;
  }
}

export class FailedToGetUsersList implements Action {
  public readonly type = UsersActionTypes.FailedToGetUsersList;
}

export class UsersListReceived implements Action {
  public readonly type = UsersActionTypes.UsersListReceived;
  public payload = null;

  constructor(payload: any) {
    this.payload = payload;
  }
}

export class SubmitRegistrationForm implements Action {
  public readonly type = UsersActionTypes.SubmitRegistrationForm;
  public readonly payload = null;
  public readonly entity?: string = null;

  constructor(payload: RegistrationFormModel, entity?: string) {
    this.payload = payload;
    this.entity = entity;
  }
}

export class ResetRegistrationForm implements Action {
  public readonly type = UsersActionTypes.ResetRegistrationForm;
}

export class SuccessfulUserRegistration implements Action {
  public readonly type = UsersActionTypes.SuccessfulUserRegistration;
}

export class FailedUserRegistration implements Action {
  public readonly type = UsersActionTypes.FailedUserRegistration;
}

export class UpdateFilters implements Action {
  public readonly type = UsersActionTypes.UpdateFilters;
  public payload: UsersFilterValueModel = <UsersFilterValueModel>{};

  constructor(payload: UsersFilterValueModel) {
    this.payload = payload;
  }
}

export class ResetFacilityRegistrationForm implements Action {
  public readonly type = UsersActionTypes.ResetFacilityRegistrationForm;
}

export class SuccessfullyRegistredFacility implements Action {
  public readonly type = UsersActionTypes.SuccessfullyRegistredFacility;
}

export class FailedToRegisterNewFacility implements Action {
  public readonly type = UsersActionTypes.FailedToRegisterNewFacility;
}

export class SubmitFacilityRegistrationForm implements Action {
  public readonly type = UsersActionTypes.SubmitFacilityRegistrationForm;
  public readonly formValue: any = null;

  constructor(formValue: any) {
    this.formValue = formValue;
  }
}

export class CreateNewGroup implements Action {
  public readonly type = UsersActionTypes.CreateNewGroup;
  public readonly formValue: any = null;

  constructor(formValue: any) {
    this.formValue = formValue;
  }
}

export class SuccessCreatingNewGroup implements Action {
  public readonly type = UsersActionTypes.SuccessCreatingNewGroup;
}

export class FailedToCreateNewGroup implements Action {
  public readonly type = UsersActionTypes.FailedToCreateNewGroup;
}

export class EditGroup implements Action {
  public readonly type = UsersActionTypes.EditGroup;

  constructor(public readonly group: any, public readonly newValue: any) {}
}

export class FailedToEditNewGroup implements Action {
  public readonly type = UsersActionTypes.FailedToEditNewGroup;
}

export class SuccessEditingGroup implements Action {
  public readonly type = UsersActionTypes.SuccessEditingGroup;
}

export class GetMroGroupsList implements Action {
  public readonly type = UsersActionTypes.GetMroGroupsList;
  public readonly withFilters?: boolean = false;

  constructor(withFilters?: boolean) {
    this.withFilters = withFilters;
  }
}

export class MroGroupsListLoaded implements Action {
  public readonly type = UsersActionTypes.MroGroupsListLoaded;

  constructor(public readonly mroGroupList: Array<any>) {}
}

export class FailedToLoadMROGroups implements Action {
  public readonly type = UsersActionTypes.FailedToLoadMROGroups;
}

export class SubmitDeleteUser implements Action {
  public readonly type = UsersActionTypes.SubmitDeleteUser;
  public readonly deleteUserLink: string = '';

  constructor(deleteLink: string) {
    this.deleteUserLink = deleteLink;
  }
}

export const SubmitDeleteFacility = createAction(
  '[User] Submit delete facility',
  props<{ url: string }>()
);

export class SuccessfullyDeletedUser implements Action {
  public readonly type = UsersActionTypes.SuccessfullyDeletedUser;
}

export class FailedDeleteUser implements Action {
  public readonly type = UsersActionTypes.FailedDeleteUser;
}

export type UsersAction =
  | SubmitRegistrationForm
  | ResetRegistrationForm
  | SuccessfulUserRegistration
  | FailedUserRegistration
  | GetEntitiesList
  | FailedToGetUsersList
  | UsersListReceived
  | UpdateFilters
  | ResetFilters
  | ResetState
  | ResetFacilityRegistrationForm
  | SubmitFacilityRegistrationForm
  | FacilitiesListReceived
  | FailedToGetFacilities
  | CreateNewGroup
  | SuccessCreatingNewGroup
  | FailedToCreateNewGroup
  | EditGroup
  | LoadedMoreFacilitiesReceived
  | FailedToEditNewGroup
  | SuccessEditingGroup
  | MroGroupsListLoaded
  | FailedToLoadMROGroups
  | SubmitDeleteUser
  | SuccessfullyDeletedUser
  | FailedDeleteUser;

export const fromUsersActions = {
  SubmitRegistrationForm,
  ResetRegistrationForm,
  SuccessfulUserRegistration,
  FailedUserRegistration,
  GetEntitiesList,
  FailedToGetUsersList,
  UsersListReceived,
  UpdateFilters,
  ResetFilters,
  ResetState,
  ResetFacilityRegistrationForm,
  SubmitFacilityRegistrationForm,
  FacilitiesListReceived,
  FailedToGetFacilities,
  CreateNewGroup,
  SuccessCreatingNewGroup,
  FailedToCreateNewGroup,
  EditGroup,
  FailedToEditNewGroup,
  SuccessEditingGroup,
  MroGroupsListLoaded,
  FailedToLoadMROGroups,
  SubmitDeleteUser,
  SuccessfullyDeletedUser,
  FailedDeleteUser
};
