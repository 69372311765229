import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { CommonUiModule } from '@libs/common-ui/common-ui.module';
import { BackofficeLoginComponent } from './backoffice-login.component';
import { BackofficeLoginRoutesModule } from './backoffice-login-routing.module';

@NgModule({
  imports: [
    CommonModule,
    BackofficeLoginRoutesModule,
    SharedModule,
    CommonUiModule
  ],
  providers: [],
  declarations: [
    BackofficeLoginComponent,
  ]
})
export class BackofficeLoginModule {}
